/* Ion.RangeSlider, Flat UI Skin
// css version 2.0.3
// Â© Denis Ineshin, 2014    https://github.com/IonDen
// ===================================================================================================================*/

/* =====================================================================================================================
// Skin details */

.irs {
    height: 40px;
  }
  
  .irs-with-grid {
    height: 60px;
  }
  
  .irs-line {
    height: 6px;
    top: 25px;
  }
  
  .irs-line-left {
    height: 6px;
    background-position: 0 -30px;
  }
  
  .irs-line-mid {
    height: 6px;
    background-position: 0 0;
  }
  
  .irs-line-right {
    height: 6px;
    background-position: 100% -30px;
  }
  
  .irs-bar {
    height: 6px;
    top: 25px;
    background-position: 0 -60px;
  }
  
  .irs-bar-edge {
    top: 25px;
    height: 6px;
    width: 9px;
    background-position: 0 -90px;
  }
  
  .irs-shadow {
    height: 3px;
    top: 34px;
    background: #000;
    opacity: 0.25;
  }
  
  .lt-ie9 .irs-shadow {
    filter: alpha(opacity = 25);
  }
  
  .irs-slider {
    width: 16px;
    height: 18px;
    top: 22px;
    background-position: 0 -120px;
  
    &.state_hover, &:hover {
      background-position: 0 -150px;
    }
  }
  
  .irs-min, .irs-max {
    color: #c0c3d5;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    top: 0;
    padding: 1px 3px;
    background: #e1e4e9;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  
  .irs-from, .irs-to, .irs-single {
    color: #fff;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background: #4d65d9;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  
  .irs-from:after, .irs-to:after, .irs-single:after {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #4d65d9;
  }
  
  .irs-grid-pol {
    background: #e1e4e9;
  }
  
  .irs-grid-text {
    color: #c0c3d5;
  }
  
  .irs-slider:before {
    content: '';
    width: 2px;
    height: 14px;
    position: absolute;
    left: 50%;
    margin-left: -1px;
    content: '';
    width: 2px;
    height: 14px;
    position: absolute;
    left: 50%;
    margin-left: -1px;
  }