/* ###### Backgrounds ###### */

.bg-secondary {
  background-color: $secondary !important;
}

a.bg-secondary {
  &:hover, &:focus {
    background-color: #de287a !important;
  }
}

button.bg-secondary {
  &:hover, &:focus {
    background-color: #de287a !important;
  }
}
 
.bg-primary {
  background-color: $primary !important;
}

a.bg-primary {
  &:hover, &:focus {
    background-color: $primary-hover !important;
  }
}

button.bg-primary {
  &:hover, &:focus {
    background-color: $primary-hover !important;
  }
}

.bg-success {
  background-color: $success !important;
}

a.bg-success {
  &:hover, &:focus {
    background-color: #16a552 !important;
  }
}

button.bg-success {
  &:hover, &:focus {
    background-color: #16a552 !important;
  }
}

.bg-info {
  background-color: $info !important;
}

a.bg-info {
  &:hover, &:focus {
    background-color: #06aff1 !important;
  }
}

button.bg-info {
  &:hover, &:focus {
    background-color: #06aff1 !important;
  }
}

.bg-warning {
  background-color: $warning  !important;
}

a.bg-warning {
  &:hover, &:focus {
    background-color: #f1911d !important;
  }
}

button.bg-warning {
  &:hover, &:focus {
    background-color: #f1911d !important;
  }
}

.bg-danger {
  background-color: $danger !important;
}

a.bg-danger {
  &:hover, &:focus {
    background-color: #ec4157 !important;
  }
}

button.bg-danger {
  &:hover, &:focus {
    background-color: #ec4157 !important;
  }
}

.bg-light {
  background-color: $background  !important;
}

a.bg-light {
  &:hover, &:focus {
    background-color: #dbe0ea !important;
  }
}

button.bg-light {
  &:hover, &:focus {
    background-color: #dbe0ea !important;
  }
}

.bg-dark {
  background-color: $dark !important;
}

a.bg-dark {
  &:hover, &:focus {
    background-color: #131212 !important;
  }
}

button.bg-dark {
  &:hover, &:focus {
    background-color: #131212 !important;
  }
}

.bg-white {
  background-color: $white !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-primary-gradient {
  background: linear-gradient(to right, $primary 0%, $primary-05 100%);
}
.bg-secondary-gradient {
  background: linear-gradient(to right, $secondary 0%, #fb6dad 100%);
}
.bg-danger-gradient {
  background-image: linear-gradient(to right, $danger 0%, #f594a0 100%) !important;
}
.bg-dark-gradient {
  background-image: linear-gradient(to right, $dark 0%, #606f8e 100%) !important;
}
.bg-success-gradient {
  background: linear-gradient(to right, $success 0%, #6cefa3 100%);
}
.bg-warning-gradient {
  background: linear-gradient(to right, $warning 0%, #f9c07b 100%);
}
.bg-info-gradient {
  background: linear-gradient(to right, $info 0%, #7edbff 100%);
}
.bg-purple-gradient {
  background: linear-gradient(to right, #673ab7 0%, #b330ac 100%) !important;
}


.bg-white-1 {
  background-color: $white-1;
}

.bg-white-2 {
  background-color: $white-2;
}

.bg-white-3 {
  background-color: $white-3;
}

.bg-white-4 {
  background-color: $white-4;
}

.bg-white-5 {
  background-color: $white-5;
}

.bg-white-6 {
  background-color: $white-6;
}

.bg-white-7 {
  background-color: $white-7;
}

.bg-white-8 {
  background-color: $white-8;
}

.bg-white-9 {
  background-color: $white-9;
}

.bg-black-1 {
  background-color: $black-1;
}

.bg-black-2 {
  background-color: $black-2;
}

.bg-black-3 {
  background-color: $black-3;
}

.bg-black-4 {
  background-color: $black-4;
}

.bg-black-5 {
  background-color: $black-5;
}

.bg-black-6 {
  background-color: $black-6;
}

.bg-black-7 {
  background-color: $black-7;
}

.bg-black-8 {
  background-color: $black-8;
}

.bg-black-9 {
  background-color: $black-9;
}

.bg-indigo {
  background-color: #4b0082;
  color: $white;
}

.bg-purple {
  background-color: #6f42c1;
  color: $white;
}

.bg-pink {
  background-color: $secondary !important;
  color: $white;
}

.bg-orange {
  background-color: #fd7e14;
  color: $white;
}

.bg-teal {
  background-color: #00cccc;
  color: $white;
}
.bg-azure {
  background-color: #007ea7;
  color: $white;
}

.bg-purple-dark {
  background-color: #59339d;
  color: $white;
}

.bg-success-transparent {
  background-color: rgba(111, 234, 163, 0.2);
}

.bg-secondary-transparent {
  background-color: rgba(240, 112, 187, 0.12);
}

.bg-primary-transparent {
  background-color: $primary-transparent;
}

.bg-info-transparent {
  background-color: rgba(1, 184, 255, 0.12);
}

.bg-warning-transparent {
  background-color: rgba(243, 202, 86, 0.12);
}

.bg-danger-transparent {
  background-color: rgba(255, 71, 61, 0.12);
}

.bg-pink-transparent {
  background-color: rgba(255, 101, 165, 0.12);
}

.bg-purple-transparent {
  background-color: rgba(111, 66, 193, 0.12);
}

.bg-dark-transparent {
  background-color: rgba(28, 39, 60, 0.12)  !important;
}

.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.12) !important;
}

.color-primary, .color-secondary, .color-success, .color-info, .color-danger, .color-warning,
.color-teal, .color-purple, .color-dark, .color-light, .color-indigo, .color-azure {
  box-shadow: 0 0.5rem 1rem rgba(162, 169, 204, 0.15) !important;
  .card-header {
    height: 80px;
    p {
      text-align: center;
      margin-top: 0.5rem;
    }
  }
  .card-body {
    padding: 16px;
  }
}

.color-primary-light, .color-secondary-light, .color-success-light, .color-info-light, .color-danger-light, .color-warning-light {
  box-shadow: 0 0.5rem 1rem rgba(162, 169, 204, 0.15) !important;
  .card-header {
    height: 80px;
    p {
      text-align: center;
      margin-top: 0.5rem;
    }
  }
  .card-body {
    padding: 16px;
  }
}

.color-primary-gradient, .color-secondary-gradient, .color-success-gradient, .color-info-gradient, .color-danger-gradient, .color-warning-gradient {
  box-shadow: 0 0.5rem 1rem rgba(162, 169, 204, 0.15) !important;
  .card-header {
    height: 80px;
    p {
      text-align: center;
      margin-top: 0.5rem;
    }
  }
  .card-body {
    padding: 16px;
  }
}

.img-filter-primary, .img-filter-secondary, .img-filter-success, .img-filter-warning, .img-filter-info, .img-filter-danger, .img-filter-teal, .img-filter-dark, .img-filter-pink, .img-filter-purple, .img-filter-indigo, .img-filter-azure {
    position: relative;
}

.img-filter-primary:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    background: $primary-03;
    bottom: 0px;
}
.img-filter-secondary:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(240, 112, 187, 0.3);
}
.img-filter-success:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(111, 234, 163, 0.3);
}
.img-filter-warning:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(243, 202, 86, 0.3);
}
.img-filter-danger:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(255, 71, 61, 0.3);
}
.img-filter-info:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(1, 184, 255, 0.3);
}
.img-filter-teal:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 204, 204, 0.3);
}
.img-filter-purple:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(111, 66, 193, 0.3);
}
.img-filter-indigo:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(75, 0, 130, 0.3);
}
.img-filter-azure:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 126, 167, 0.3);
}
.img-filter-dark:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(59, 72, 99, 0.3);
}
.img-filter-pink:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(255, 101, 165, 0.3);
}




/* ###### Backgrounds ###### */