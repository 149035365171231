/*!
 * Datepicker for Bootstrap v1.8.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */

 .datepicker {
    padding: 10px;
    border-radius: 0px;
    direction: ltr;
    background-color: #f7f6f9;
    border: 1px solid #e8ebf3;
  }
  
  .datepicker-inline {
    width: 220px;
  }
  
  .datepicker-rtl {
    direction: rtl;
  
    &.dropdown-menu {
      left: auto;
    }
  
    table tr td span {
      float: right;
    }
  }
  
  .datepicker-dropdown {
    top: 0;
    left: 0;
  
    &:before {
      content: '';
      display: inline-block;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #f7f6f9;
      border-top: 0;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      position: absolute;
    }
  
    &:after {
      content: '';
      display: inline-block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #f7f6f9;
      border-top: 0;
      position: absolute;
    }
  
    &.datepicker-orient-left {
      &:before {
        left: 6px;
      }
  
      &:after {
        left: 7px;
      }
    }
  
    &.datepicker-orient-right {
      &:before {
        right: 6px;
      }
  
      &:after {
        right: 7px;
      }
    }
  
    &.datepicker-orient-bottom {
      &:before {
        top: -7px;
      }
  
      &:after {
        top: -6px;
      }
    }
  
    &.datepicker-orient-top {
      &:before {
        bottom: -7px;
        border-bottom: 0;
        border-top: 7px solid rgba(0, 0, 0, 0.2);
      }
  
      &:after {
        bottom: -6px;
        border-bottom: 0;
        border-top: 6px solid #f7f6f9;
      }
    }
  }
  
  .datepicker {
    table {
      margin: 0;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-collapse: inherit;
    }
  
    td {
      text-align: center;
      padding: 6px 10px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
      border: none;
      font-size: 13px;
      color: #473b52;
    }
  
    .datepicker-switch {
      text-align: center;
      color: #4d65d9;
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
  
      &:hover {
        color: #4d65d9;
        background: transparent !important;
      }
    }
  
    .prev:hover, .next:hover, tfoot tr th:hover {
      color: #4d65d9;
      background: transparent !important;
    }
  
    th {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 1px;
      padding: 6px 10px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
      border: none;
      color: #a3a7b7;
    }
  }
  
  .datepicker-days tbody {
    background: #fff;
  }
  
  .table-striped .datepicker table tr {
    td, th {
      background-color: transparent;
    }
  }
  
  .datepicker {
    table tr td {
      &.day {
        &:hover, &.focused {
          background: rgb(255, 255, 255, 0.1);
          cursor: pointer;
        }
      }
  
      &.old, &.new {
        opacity: .5;
      }
  
      &.disabled {
        background: none;
        color: #4d65d9;
        cursor: default;
  
        &:hover {
          background: none;
          color: #4d65d9;
          cursor: default;
        }
      }
  
      &.highlighted {
        background: #d9edf7;
        border-radius: 0;
      }
  
      &.today {
        background-color: #fde19a;
        background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
        background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
        border-color: #fdf59a #fdf59a #fbed50;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #7e7da0;
  
        &:hover {
          background-color: #fde19a;
          background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
          background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
          border-color: #fdf59a #fdf59a #fbed50;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #7e7da0;
        }
  
        &.disabled {
          background-color: #fde19a;
          background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
          background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
          border-color: #fdf59a #fdf59a #fbed50;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #7e7da0;
  
          &:hover {
            background-color: #fde19a;
            background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
            background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
            background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
            background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
            background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
            border-color: #fdf59a #fdf59a #fbed50;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            color: #7e7da0;
          }
        }
  
        &:hover, &.disabled:hover, &:active, &:hover:active {
          background-color: #fdf59a;
        }
  
        &.disabled {
          &:active, &:hover:active {
            background-color: #fdf59a;
          }
        }
  
        &.active, &:hover.active {
          background-color: #fdf59a;
        }
  
        &.disabled {
          &.active, &:hover.active {
            background-color: #fdf59a;
          }
  
          background-color: #fdf59a;
        }
  
        &:hover.disabled {
          background-color: #fdf59a;
        }
  
        &.disabled {
          &.disabled, &:hover.disabled {
            background-color: #fdf59a;
          }
        }
  
        &[disabled], &:hover[disabled] {
          background-color: #fdf59a;
        }
  
        &.disabled {
          &[disabled], &:hover[disabled] {
            background-color: #fdf59a;
          }
        }
  
        &:active, &:hover:active {
          background-color: #fbf069 \9;
        }
  
        &.disabled {
          &:active, &:hover:active {
            background-color: #fbf069 \9;
          }
        }
  
        &.active, &:hover.active {
          background-color: #fbf069 \9;
        }
  
        &.disabled {
          &.active, &:hover.active {
            background-color: #fbf069 \9;
          }
        }
  
        &:hover:hover {
          color: #000;
        }
  
        &.active:hover {
          color: #fff;
        }
      }
  
      &.range {
        background: #f0f2f7;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
  
        &:hover {
          background: #f0f2f7;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
  
        &.disabled {
          background: #f0f2f7;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
  
          &:hover {
            background: #f0f2f7;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
          }
        }
  
        &.today {
          background-color: #f3d17a;
          background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
          background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
          border-color: #f3e97a #f3e97a #edde34;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
  
          &:hover {
            background-color: #f3d17a;
            background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
            background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
            border-color: #f3e97a #f3e97a #edde34;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
          }
  
          &.disabled {
            background-color: #f3d17a;
            background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
            background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
            border-color: #f3e97a #f3e97a #edde34;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
  
            &:hover {
              background-color: #f3d17a;
              background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
              background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
              background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
              background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
              background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
              background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
              background-repeat: repeat-x;
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
              border-color: #f3e97a #f3e97a #edde34;
              border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
              filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              border-radius: 0;
            }
          }
  
          &:hover, &.disabled:hover, &:active, &:hover:active {
            background-color: #f3e97a;
          }
  
          &.disabled {
            &:active, &:hover:active {
              background-color: #f3e97a;
            }
          }
  
          &.active, &:hover.active {
            background-color: #f3e97a;
          }
  
          &.disabled {
            &.active, &:hover.active {
              background-color: #f3e97a;
            }
  
            background-color: #f3e97a;
          }
  
          &:hover.disabled {
            background-color: #f3e97a;
          }
  
          &.disabled {
            &.disabled, &:hover.disabled {
              background-color: #f3e97a;
            }
          }
  
          &[disabled], &:hover[disabled] {
            background-color: #f3e97a;
          }
  
          &.disabled {
            &[disabled], &:hover[disabled] {
              background-color: #f3e97a;
            }
          }
  
          &:active, &:hover:active {
            background-color: #efe24b \9;
          }
  
          &.disabled {
            &:active, &:hover:active {
              background-color: #efe24b \9;
            }
          }
  
          &.active, &:hover.active {
            background-color: #efe24b \9;
          }
  
          &.disabled {
            &.active, &:hover.active {
              background-color: #efe24b \9;
            }
          }
        }
      }
  
      &.selected {
        background-color: #9e9e9e;
        background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
        background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: linear-gradient(to bottom, #b3b3b3, #808080);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
        border-color: #808080 #808080 #595959;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  
        &:hover {
          background-color: #9e9e9e;
          background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
          background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: linear-gradient(to bottom, #b3b3b3, #808080);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
          border-color: #808080 #808080 #595959;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #fff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }
  
        &.disabled {
          background-color: #9e9e9e;
          background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
          background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: linear-gradient(to bottom, #b3b3b3, #808080);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
          border-color: #808080 #808080 #595959;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #fff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  
          &:hover {
            background-color: #9e9e9e;
            background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
            background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
            background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
            background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
            background-image: linear-gradient(to bottom, #b3b3b3, #808080);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
            border-color: #808080 #808080 #595959;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
          }
        }
  
        &:hover, &.disabled:hover, &:active, &:hover:active {
          background-color: #808080;
        }
  
        &.disabled {
          &:active, &:hover:active {
            background-color: #808080;
          }
        }
  
        &.active, &:hover.active {
          background-color: #808080;
        }
  
        &.disabled {
          &.active, &:hover.active {
            background-color: #808080;
          }
  
          background-color: #808080;
        }
  
        &:hover.disabled {
          background-color: #808080;
        }
  
        &.disabled {
          &.disabled, &:hover.disabled {
            background-color: #808080;
          }
        }
  
        &[disabled], &:hover[disabled] {
          background-color: #808080;
        }
  
        &.disabled {
          &[disabled], &:hover[disabled] {
            background-color: #808080;
          }
        }
  
        &:active, &:hover:active {
          background-color: #666666 \9;
        }
  
        &.disabled {
          &:active, &:hover:active {
            background-color: #666666 \9;
          }
        }
  
        &.active, &:hover.active {
          background-color: #666666 \9;
        }
  
        &.disabled {
          &.active, &:hover.active {
            background-color: #666666 \9;
          }
        }
      }
  
      &.active {
        background-color: #006dcc;
        background-image: -moz-linear-gradient(to bottom, #4d65d9, #4d65d9);
        background-image: -ms-linear-gradient(to bottom, #4d65d9, #4d65d9);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4d65d9), to(#4d65d9));
        background-image: -webkit-linear-gradient(to bottom, #4d65d9, #4d65d9);
        background-image: -o-linear-gradient(to bottom, #4d65d9, #4d65d9);
        background-image: linear-gradient(to bottom, #4d65d9, #4d65d9);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d65d9', endColorstr='#4d65d9', GradientType=0);
        border-color: #4d65d9 #4d65d9 #002a80;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  
        &:hover {
          background-color: #006dcc;
          background-image: -moz-linear-gradient(to bottom, #4d65d9, #4d65d9);
          background-image: -ms-linear-gradient(to bottom, #4d65d9, #4d65d9);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4d65d9), to(#4d65d9));
          background-image: -webkit-linear-gradient(to bottom, #4d65d9, #4d65d9);
          background-image: -o-linear-gradient(to bottom, #4d65d9, #4d65d9);
          background-image: linear-gradient(to bottom, #4d65d9, #4d65d9);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d65d9', endColorstr='#4d65d9', GradientType=0);
          border-color: #4d65d9 #4d65d9 #002a80;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #fff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }
  
        &.disabled {
          background-color: #006dcc;
          background-image: -moz-linear-gradient(to bottom, #4d65d9, #4d65d9);
          background-image: -ms-linear-gradient(to bottom, #4d65d9, #4d65d9);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4d65d9), to(#4d65d9));
          background-image: -webkit-linear-gradient(to bottom, #4d65d9, #4d65d9);
          background-image: -o-linear-gradient(to bottom, #4d65d9, #4d65d9);
          background-image: linear-gradient(to bottom, #4d65d9, #4d65d9);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d65d9', endColorstr='#4d65d9', GradientType=0);
          border-color: #4d65d9 #4d65d9 #002a80;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #fff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  
          &:hover {
            background-color: #006dcc;
            background-image: -moz-linear-gradient(to bottom, #4d65d9, #4d65d9);
            background-image: -ms-linear-gradient(to bottom, #4d65d9, #4d65d9);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4d65d9), to(#4d65d9));
            background-image: -webkit-linear-gradient(to bottom, #4d65d9, #4d65d9);
            background-image: -o-linear-gradient(to bottom, #4d65d9, #4d65d9);
            background-image: linear-gradient(to bottom, #4d65d9, #4d65d9);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d65d9', endColorstr='#4d65d9', GradientType=0);
            border-color: #4d65d9 #4d65d9 #002a80;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
          }
        }
  
        &:hover, &.disabled:hover, &:active, &:hover:active {
          background-color: #e8ebf3;
        }
  
        &.disabled {
          &:active, &:hover:active {
            background-color: #e8ebf3;
          }
        }
  
        &.active, &:hover.active {
          background-color: #e8ebf3;
        }
  
        &.disabled {
          &.active, &:hover.active {
            background-color: #e8ebf3;
          }
  
          background-color: #e8ebf3;
        }
  
        &:hover.disabled {
          background-color: #e8ebf3;
        }
  
        &.disabled {
          &.disabled, &:hover.disabled {
            background-color: #e8ebf3;
          }
        }
  
        &[disabled], &:hover[disabled] {
          background-color: #e8ebf3;
        }
  
        &.disabled {
          &[disabled], &:hover[disabled] {
            background-color: #e8ebf3;
          }
        }
  
        &:active, &:hover:active {
          background-color: #003399 \9;
        }
  
        &.disabled {
          &:active, &:hover:active {
            background-color: #003399 \9;
          }
        }
  
        &.active, &:hover.active {
          background-color: #003399 \9;
        }
  
        &.disabled {
          &.active, &:hover.active {
            background-color: #003399 \9;
          }
        }
      }
  
      span {
        display: block;
        width: 24%;
        height: 54px;
        line-height: 54px;
        float: left;
        margin: .5px;
        cursor: pointer;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
        background: #fff;
  
        &:hover, &.focused {
          background: #e8ebf3;
        }
  
        &.disabled {
          background: none;
          color: #4d65d9;
          cursor: default;
  
          &:hover {
            background: none;
            color: #4d65d9;
            cursor: default;
          }
        }
  
        &.active {
          background-color: #006dcc;
          background-image: -moz-linear-gradient(to bottom, #4d65d9, #4d65d9);
          background-image: -ms-linear-gradient(to bottom, #4d65d9, #4d65d9);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4d65d9), to(#4d65d9));
          background-image: -webkit-linear-gradient(to bottom, #4d65d9, #4d65d9);
          background-image: -o-linear-gradient(to bottom, #4d65d9, #4d65d9);
          background-image: linear-gradient(to bottom, #4d65d9, #4d65d9);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d65d9', endColorstr='#4d65d9', GradientType=0);
          border-color: #4d65d9 #4d65d9 #002a80;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #fff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  
          &:hover {
            background-color: #006dcc;
            background-image: -moz-linear-gradient(to bottom, #4d65d9, #4d65d9);
            background-image: -ms-linear-gradient(to bottom, #4d65d9, #4d65d9);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4d65d9), to(#4d65d9));
            background-image: -webkit-linear-gradient(to bottom, #4d65d9, #4d65d9);
            background-image: -o-linear-gradient(to bottom, #4d65d9, #4d65d9);
            background-image: linear-gradient(to bottom, #4d65d9, #4d65d9);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d65d9', endColorstr='#4d65d9', GradientType=0);
            border-color: #4d65d9 #4d65d9 #002a80;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
          }
  
          &.disabled {
            background-color: #006dcc;
            background-image: -moz-linear-gradient(to bottom, #4d65d9, #4d65d9);
            background-image: -ms-linear-gradient(to bottom, #4d65d9, #4d65d9);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4d65d9), to(#4d65d9));
            background-image: -webkit-linear-gradient(to bottom, #4d65d9, #4d65d9);
            background-image: -o-linear-gradient(to bottom, #4d65d9, #4d65d9);
            background-image: linear-gradient(to bottom, #4d65d9, #4d65d9);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d65d9', endColorstr='#4d65d9', GradientType=0);
            border-color: #4d65d9 #4d65d9 #002a80;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  
            &:hover {
              background-color: #006dcc;
              background-image: -moz-linear-gradient(to bottom, #4d65d9, #4d65d9);
              background-image: -ms-linear-gradient(to bottom, #4d65d9, #4d65d9);
              background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4d65d9), to(#4d65d9));
              background-image: -webkit-linear-gradient(to bottom, #4d65d9, #4d65d9);
              background-image: -o-linear-gradient(to bottom, #4d65d9, #4d65d9);
              background-image: linear-gradient(to bottom, #4d65d9, #4d65d9);
              background-repeat: repeat-x;
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d65d9', endColorstr='#4d65d9', GradientType=0);
              border-color: #4d65d9 #4d65d9 #002a80;
              border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
              filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
              color: #fff;
              text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
            }
          }
  
          &:hover, &.disabled:hover, &:active, &:hover:active {
            background-color: #e8ebf3;
          }
  
          &.disabled {
            &:active, &:hover:active {
              background-color: #e8ebf3;
            }
          }
  
          &.active, &:hover.active {
            background-color: #e8ebf3;
          }
  
          &.disabled {
            &.active, &:hover.active {
              background-color: #e8ebf3;
            }
  
            background-color: #e8ebf3;
          }
  
          &:hover.disabled {
            background-color: #e8ebf3;
          }
  
          &.disabled {
            &.disabled, &:hover.disabled {
              background-color: #e8ebf3;
            }
          }
  
          &[disabled], &:hover[disabled] {
            background-color: #e8ebf3;
          }
  
          &.disabled {
            &[disabled], &:hover[disabled] {
              background-color: #e8ebf3;
            }
          }
  
          &:active, &:hover:active {
            background-color: #003399 \9;
          }
  
          &.disabled {
            &:active, &:hover:active {
              background-color: #003399 \9;
            }
          }
  
          &.active, &:hover.active {
            background-color: #003399 \9;
          }
  
          &.disabled {
            &.active, &:hover.active {
              background-color: #003399 \9;
            }
          }
        }
  
        &.old, &.new {
          color: #4d65d9;
        }
      }
    }
  
    .datepicker-switch {
      width: 145px;
      cursor: pointer;
    }
  
    .prev, .next, tfoot tr th {
      cursor: pointer;
    }
  
    .prev, .next {
      font-size: 25px;
    }
  
    .datepicker-switch:hover, .prev:hover, .next:hover, tfoot tr th:hover {
      background: rgb(255, 255, 255, 0.1);
      color: #c5c5f3;
    }
  
    .prev.disabled, .next.disabled {
      visibility: hidden;
    }
  
    .cw {
      font-size: 10px;
      width: 12px;
      padding: 0 2px 0 5px;
      vertical-align: middle;
    }
  }
  
  .input-append.date .add-on, .input-prepend.date .add-on {
    cursor: pointer;
  }
  
  .input-append.date .add-on i, .input-prepend.date .add-on i {
    margin-top: 3px;
  }
  
  .input-daterange {
    input {
      text-align: center;
  
      &:first-child {
        -webkit-border-radius: 3px 0 0 3px;
        -moz-border-radius: 3px 0 0 3px;
        border-radius: 3px 0 0 3px;
      }
  
      &:last-child {
        -webkit-border-radius: 0 3px 3px 0;
        -moz-border-radius: 0 3px 3px 0;
        border-radius: 0 3px 3px 0;
      }
    }
  
    .add-on {
      display: inline-block;
      width: auto;
      min-width: 16px;
      height: 18px;
      padding: 4px 5px;
      font-weight: normal;
      line-height: 18px;
      text-align: center;
      text-shadow: 0 1px 0 #fff;
      vertical-align: middle;
      background-color: #f0f2f7;
      border: 1px solid #ccc;
      margin-left: -5px;
      margin-right: -5px;
    }
  }
  
  .form-control {
    color: #c6bcc9;
  }