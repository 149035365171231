

/* ###### file manager ###### */

.file-rounded-attachments {
    padding: 0px 10px !important;
    border-radius: 50px !important;
    font-size: 14px !important;
    cursor: pointer !important;
}
.file-radius-attachments {
    padding: 0px 10px !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    cursor: pointer !important;
}
.file-rounded-attachments-sm {
    padding: 0px 10px !important;
    border-radius: 50px !important;
    font-size: 13px !important;
    cursor: pointer !important;
}
.file-rounded-attachments-lg {
    padding: 0px 10px !important;
    border-radius: 50px !important;
    font-size: 15px !important;
    cursor: pointer !important;
  
    i{
      font-size: 22px !important;
    }
}
  
.file-radius-attachments-sm {
    padding: 0px 10px !important;
    border-radius: 6px !important;
    font-size: 13px !important;
    cursor: pointer !important;
}
.file-radius-attachments-lg {
    padding: 0px 10px !important;
    border-radius: 6px !important;
    font-size: 15px !important;
    cursor: pointer !important;
  
    i{
      font-size: 22px !important;
    }
}



.file-image .icons {
    padding: 0;
    margin: 0;
    list-style: none;
    opacity: 0;
    right: 22px;
    top: 14px;
    text-align: center;
    position: absolute;
    bottom: -3px;
    z-index: 1;
    transition: all 0.5s ease 0s;
    text-align: right;
}
.file-image .icons li {
  display: block;
  margin: 5px;
}
.file-image .icons li a {
  font-size: 15px;
  line-height: 35px;
  text-align: center;
  height: 35px;
  width: 35px;
  margin: 2px 7px;
  padding: 0;
  border-radius: 50px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  color: $white;
  min-height: 0;
}
.file-image .icons li a:after, .file-image .icons li a:before {
  content: attr(data-tip);
  color: #fff;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 20px;
  padding: 1px 5px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: -30px;
}
.file-image .icons li a:after {
  content: "";
  height: 15px;
  width: 15px;
  border-radius: 0;
  transform: translateX(-50%) rotate(45deg);
  top: -20px;
  z-index: -1;
}
.file-image:hover .icons {
  opacity: 1;
  bottom: 33px;
}
.file-name {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  color: $white;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}
.file-image img {
  border-radius: 6px;
}

.file-image-sm {
  width: 100px;
  height: initial;
  display: inline-table;
  margin: 4px;
  position: relative;
  border: 1px solid $border;
  border-radius: 6px;
  padding: 20px;
}
.file-image-sm .product-image img {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.file-image-sm .icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  right: 7px;
  top: 6px;
  text-align: center;
  position: absolute;
  bottom: 0;
  z-index: 1;
  transition: all 0.5s ease 0s;
  text-align: right;
}
.file-image-sm .icons li a {
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  height: 25px;
  width: 25px;
  margin: 4px 1px;
  padding: 0;
  border-radius: 50px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  color: $white;
  min-height: 0;
}
.file-image-sm:hover .icons {
  opacity: 1;
  bottom: 33px;
}
.file-image-sm .file-name-sm {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -3px;
  color: #8f8fb1;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}

.file-image-md {
  width: inherit;
  height: initial;
  display: inline-table;
  margin: 4px;
  position: relative;
  border: 1px solid $border;
  border-radius: 6px;
}
.file-image-md .product-image img {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.file-image-md .icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  right: 7px;
  top: 6px;
  text-align: center;
  position: absolute;
  bottom: 0;
  z-index: 1;
  transition: all 0.5s ease 0s;
  text-align: right;
}
.file-image-md .icons li a {
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  height: 25px;
  width: 25px;
  margin: 4px 1px;
  padding: 0;
  border-radius: 50px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  color: $white;
  min-height: 0;
}
.file-image-md:hover .icons {
  opacity: 1;
  bottom: 33px;
}
.file-image-md .file-name-md {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  color: $white;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}

.file-image-lg {
  width: inherit;
  height: initial;
  display: inline-table;
  margin: 4px;
  position: relative;
  border: 1px solid $border;
  border-radius: 6px;
}
.file-image-lg .product-image img {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.file-image-lg .icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  right: 7px;
  top: 6px;
  text-align: center;
  position: absolute;
  bottom: 0;
  z-index: 1;
  transition: all 0.5s ease 0s;
  text-align: right;
}
.file-image-lg .icons li a {
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  height: 30px;
  width: 30px;
  margin: 4px 1px;
  padding: 0;
  border-radius: 50px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  color: $white;
  min-height: 0;
}
.file-image-lg:hover .icons {
  opacity: 1;
  bottom: 33px;
}
.file-image-lg .file-name-lg {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  color: $white;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}
  
  /* ###### file manager ###### */



.btn-group.file-attach {
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-primary {
    border-color: $white-2 !important;
  }
  .rounded-pill {
    border-radius: 50px 0 0 50px !important;
  }
  .btn-primary-transparent, .btn-secondary-transparent, .btn-info-transparent, .btn-danger-transparent, .btn-warning-transparent, .btn-success-transparent {
    border: 0;
  }
  .btn-close {
      padding: 0px;
      border-radius: 0px 6px 6px 0px;
      opacity: 1;
      font-weight: 300;
      background-image: none;
      font-size: 2rem;
      border: 1px solid;
      line-height: 1;
    &.rounded-pill {
      border-radius: 0px 50px 50px 0px !important;
    }
    &.btn-outline-primary {
      border: 1px solid $primary;
    }
    &.btn-outline-secondary {
      border: 1px solid $secondary;
    }
    &.btn-outline-info {
      border: 1px solid $info;
    }
    &.btn-outline-danger {
      border: 1px solid $danger;
    }
    &.btn-outline-warning {
      border: 1px solid $warning;
    }
    &.btn-outline-success {
      border: 1px solid $success;
    }
    &.btn-primary-transparent, &.btn-secondary-transparent, &.btn-info-transparent, &.btn-danger-transparent, &.btn-warning-transparent, &.btn-success-transparent {
      border: 0;
    }
    
    &.btn-sm {
      font-size: 1.7rem !important;
      background-image: none;
    }
    &.btn-lg {
      padding: 10px !important;
      background-image: none;
      font-size: 30px !important;
    }
  }
}