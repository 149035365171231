@import "../scss/variables";

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body.dark-theme {
  background: $dark-body;
  color: $dark-white;
}

.dark-theme {
  hr {
    border: 0;
    border-top: 1px solid #30314d;
  }

  abbr {
    &[title],
    &[data-original-title] {
      border-bottom: 0;
    }
  }

  a {
    &:not([href]):not([tabindex]) {
      color: inherit;

      &:hover,
      &:focus {
        color: inherit;
      }
    }
  }

  table {
    border-collapse: collapse;
  }

  caption {
    color: $white-7;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
  }

  fieldset {
    border: 0;
  }

  legend {
    color: inherit;
  }

  mark,
  .mark {
    background-color: #fcf8e3;
  }

  .blockquote-footer {
    color: $white-7;
  }

  .img-thumbnail {
    background-color: transparent;
    border: 1px solid $dark-border;
  }

  .figure-caption {
    color: $white-7;
  }

  code {
    color: #f10075;
  }

  a > code {
    color: inherit;
  }

  kbd {
    color: $dark-white;
    background-color: #8f9cc0;
  }

  pre {
    text-shadow: 0 1px $dark-body;
    background-color: $dark-body;

    code {
      color: inherit;
    }
  }

  .accordion-color .card-header a.collapsed {
    color: rgba(255, 255, 255, 0.8) !important;
    background-color: $primary !important;

    &:hover,
    &:focus {
      color: $dark-white;
    }
  }

  .accordion-faq .accordion .card-header a {
    background-color: $dark-body;
  }

  .accordion {
    .card {
      border-width: 0;
    }

    .card-header {
      background-color: transparent;

      a {
        background-color: $dark-body;
        color: $dark-white;

        &.collapsed {
          color: $dark-white;
          background-color: $dark-body;
          border-bottom-color: transparent;

          &:hover,
          &:focus {
            color: $dark-white;
            background-color: #30314d;
          }
        }
      }
    }

    .card-body {
      background-color: $dark-theme;
      border: 1px solid $dark-border;
    }
  }

  .accordion-indigo .card-header a.collapsed {
    color: rgba(255, 255, 255, 0.8);

    &:hover,
    &:focus {
      color: $dark-white;
    }
  }

  .accordion-blue .card-header a {
    color: rgba(255, 255, 255, 0.8);

    &:hover,
    &:focus {
      color: $dark-white;
    }
  }

  .accordion-dark {
    .card {
      border-color: #8f9cc0;
    }

    .card-header a {
      color: #8f9cc0;

      &:hover,
      &:focus {
        color: #8f9cc0;
      }

      &.collapsed {
        background-color: #8f9cc0;
        color: rgba(255, 255, 255, 0.8);

        &:hover,
        &:focus {
          background-color: #141c2b;
          color: $dark-white;
        }
      }
    }
  }

  .accordion-gray {
    .card {
      border-color: $white-7;
    }

    .card-header a {
      color: $white-7;

      &:hover,
      &:focus {
        color: $white-7;
      }

      &.collapsed {
        background-color: $white-7;
        color: rgba(255, 255, 255, 0.8);

        &:hover,
        &:focus {
          background-color: #6a7a96;
          color: $dark-white;
        }
      }
    }
  }

  .accordion > .card {
    &:not(:first-of-type):not(:last-of-type),
    &:first-of-type {
      border-bottom: 0;
    }
  }

  .main-accordion {
    .accordion-item {
      background-color: #f1f2f9;
      border: 1px solid #dee2ef;

      &:hover,
      &:focus {
        background-color: #f1f2f6;
        border-color: #bcc4de;
      }

      &.active {
        background-color: $dark-white;
        border-color: #bcc4de;
      }
    }

    .accordion-title {
      color: #8f9cc0;
    }
  }

  .alert {
    border-width: 0;
    border: 1px solid transparent;

    .btn-close {
      text-shadow: none;
    }
  }

  .alert-heading,
  .alert-dismissible .btn-close {
    color: inherit;
  }

  .alert-primary {
    color: $primary;
    background-color: $primary-01;
    border-color: $primary-01;

    hr {
      height: 1px !important;
      background-color: $primary-02;
      border: none;
    }

    .alert-link {
      color: #5b77c9;
    }
  }

  .alert-secondary {
    color: $secondary;
    background-color: rgba(241, 56, 139, 0.1);
    border-color: rgba(241, 56, 139, 0.1);

    hr {
      height: 1px !important;
      background-color: rgba(241, 56, 139, 0.2);
      border: none;
    }

    .alert-link {
      color: #c3829f;
    }
  }

  .alert-success {
    color: $success;
    background-color: rgba(25, 177, 89, 0.1);
    border-color: rgba(25, 177, 89, 0.1);

    hr {
      height: 1px !important;
      background-color: rgba(25, 177, 89, 0.2);
      border: none;
      background-image: linear-gradient(to right, #1b2b3d, rgba(25, 177, 89, 0.2), #1b2b3d);
    }

    .alert-link {
      color: #9fcbb2;
    }
  }

  .alert-info {
    color: $info;
    background-color: rgba(1, 184, 255, 0.1);
    border-color: rgba(1, 184, 255, 0.1);

    hr {
      height: 1px !important;
      background-color: rgba(1, 184, 255, 0.2);
      border: none;
      background-image: linear-gradient(to right, #182c4e, rgba(1, 184, 255, 0.2), #182c4e);
    }

    .alert-link {
      color: #95c5d8;
    }
  }

  .alert-warning {
    color: $warning;
    background-color: rgba(255, 155, 33, 0.1);
    border-color: rgba(255, 155, 33, 0.1);

    hr {
      height: 1px !important;
      background-color: rgba(255, 155, 33, 0.2);
      border: none;
      background-image: linear-gradient(to right, #322937, rgba(255, 155, 33, 0.2), #322937);
    }

    .alert-link {
      color: #f0c58f;
    }
  }

  .alert-danger {
    color: $danger;
    background-color: rgba(253, 96, 116, 0.1);
    border-color: rgba(253, 96, 116, 0.1);

    hr {
      height: 1px !important;
      background-color: rgba(253, 96, 116, 0.2);
      border: none;
      background-image: linear-gradient(to right, #322340, rgba(253, 96, 116, 0.2), #322340);
    }

    .alert-link {
      color: #cb878f;
    }
  }

  .alert-light {
    color: #7f7f81;
    background-color: #fdfdfe;
    border-color: #d8d8de;

    hr {
      height: 1px !important;
      background-color: #7f7f81;
      border: none;
    }

    .alert-link {
      color: #666667;
    }
  }

  .alert-dark {
    color: $dark;
    background-color: rgba(59, 72, 99, 0.1);
    border-color: rgba(59, 72, 99, 0.1);

    hr {
      height: 1px !important;
      background-color: rgba(59, 72, 99, 0.2);
      border: none;
    }

    .alert-link {
      color: #0c0e13;
    }
  }
}

.alert-dark .alert-link {
  color: #0c0e13;
}

.dark-theme {
  [class*="alert-outline-"] {
    border: 1px solid transparent;
  }

  .alert-outline-primary {
    border-color: $primary;
    color: $primary;
  }

  .alert-outline-secondary {
    border-color: $secondary;
    color: $secondary;
  }

  .alert-outline-success {
    border-color: #36c54d;
    color: $success;
  }

  .alert-outline-info {
    border-color: #1ab6cf;
    color: $info;
  }

  .alert-outline-warning {
    border-color: #ffdb6d;
    color: #d39e00;
  }

  .alert-outline-danger {
    border-color: #eb8c95;
    color: #f16d75;
  }

  [class*="alert-solid-"] {
    border-width: 0;
    color: $white;
  }

  .alert-solid-primary {
    background-color: $primary;
  }

  .alert-solid-secondary {
    background-color: $secondary;
  }

  .alert-solid-success {
    background-color: $success;
  }

  .alert-solid-info {
    background-color: $info;
  }

  .alert-solid-warning {
    background-color: $warning;
  }

  .alert-solid-danger {
    background-color: #f16d75;
  }

  .alert-message {
    border: 1px solid rgba(232, 232, 247, 0.2);
    background: #665dd2;
  }

  .alert-default {
    border: 1px solid #d8d8de;
  }

  .alert-bdleft-success {
    border-left: 5px solid $success;
  }

  .alert-bdleft-primary {
    border-left: 5px solid $primary;
  }

  .alert-bdleft-warning {
    border-left: 5px solid $warning;
  }

  .alert-bdleft-danger {
    border-left: 5px solid $danger;
  }

  .alert-bdleft-info {
    border-left: 5px solid $info;
  }

  .alert-bdleft-secondary {
    border-left: 5px solid $secondary;
  }

  .bg-secondary {
    color: $white !important;
    background-color: $secondary;
  }

  a.bg-secondary {
    &:hover {
      color: $white !important;
      background-color: #de287a;
    }

    &:focus {
      color: $white !important;
      background-color: #de287a;
    }
  }

  .bg-success {
    color: $white !important;
    background-color: $success;
  }

  a.bg-success {
    &:hover {
      color: $white !important;
      background-color: #16a552;
    }

    &:focus {
      color: $white;
      background-color: #16a552;
    }
  }

  .bg-info {
    color: $white !important;
    background-color: $info;
  }

  a.bg-info {
    &:hover {
      color: $white !important;
      background-color: #06aff1;
    }

    &:focus {
      color: $white;
      background-color: #06aff1;
    }
  }

  .bg-warning {
    color: $white !important;
    background-color: #ecb529;
  }

  a.bg-warning {
    &:hover {
      background-color: #f1911d;
    }

    &:focus {
      background-color: #f1911d;
    }
  }

  .bg-danger {
    color: $white !important;
    background-color: $danger;
  }

  a.bg-danger {
    &:hover {
      color: $white !important;
      background-color: #ec4157;
    }

    &:focus {
      color: $white;
      background-color: #ec4157;
    }
  }

  .bg-pink {
    color: $white !important;
    background-color: #ff75ad;
  }

  a.bg-pink {
    &:hover,
    &:focus {
      color: $white !important;
      background-color: #ff75ad;
    }
  }

  .bg-dark {
    color: $white;
    background-color: #8f9cc0;
  }

  a.bg-dark {
    &:hover {
      color: $white !important;
      background-color: #131212;
    }

    &:focus {
      color: $white !important;
      background-color: #131212;
    }
  }

  .bg-success-light {
    color: $success !important;
    background: rgba(25, 177, 89, 0.2);
  }

  .bg-info-light {
    background: rgba(1, 184, 255, 0.2);
    color: $info;
  }

  .breadcrumb-style1,
  .breadcrumb-style2,
  .breadcrumb-style3 {
    background-color: transparent;
  }

  .breadcrumb-basic .breadcrumb-item a,
  .breadcrumb-style1 .breadcrumb-item a,
  .breadcrumb-style2 .breadcrumb-item a,
  .breadcrumb-style3 .breadcrumb-item a {
    color: $white-7;
  }

  .breadcrumb-basic .breadcrumb-item + .breadcrumb-item::before,
  .breadcrumb-style1 .breadcrumb-item + .breadcrumb-item::before,
  .breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before,
  .breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
    color: $white-2;
  }

  .main-content-breadcrumb {
    color: $white-7;

    span {
      + span::before {
        color: #b4bdce;
      }

      &:last-child {
        color: #3c4858;
      }
    }
  }

  .main-breadcrumbs {
    background-color: transparent;

    .breadcrumb-item {
      a {
        color: #8f9cc0;

        &:hover {
          color: $white-7;
        }
      }

      + .breadcrumb-item::before {
        color: $white-7;
      }
    }
  }

  .breadcrumb-item {
    + .breadcrumb-item::before {
      color: $white-7;
    }
    &.active {
      color: $primary;
    }
  }

  .breadcrumb-5 a {
    color: white;
    background: #666;
    background: linear-gradient(#666, #333);

    &.active,
    &:hover {
      background: $primary;
    }

    &.active:after {
      box-shadow: none;
    }

    &:after {
      box-shadow: 2px -2px 0 2px $dark-border, 3px -3px 0 2px rgba(255, 255, 255, 0.1);
    }
  }

  //   .btn, .sp-container button {
  //     color: #8f9cc0;
  //     background-color: transparent;
  //     border: 1px solid transparent;
  //   }
}

.btn:active {
  box-shadow: none;
  box-shadow: none;
}

.dark-theme {
  .sp-container button:active,
  .btn:focus,
  .sp-container button:focus {
    box-shadow: none;
    box-shadow: none;
  }

  .btn-light {
    color: $dark-white;
    background-color: $dark-theme !important;
    border-color: $dark-border !important;

    &:hover {
      color: #8f9cc0;
      background-color: #dde0e9;
      border-color: #d5d9e4;
    }

    &:focus,
    &.focus {
      color: #8f9cc0;
      background-color: #dde0e9;
      border-color: #d5d9e4;
      box-shadow: none;
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #f1f2f9;
      border-color: #f1f2f9;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #d5d9e4;
        border-color: #cdd2df;
      }
    }
  }

  .show > .btn-light.dropdown-toggle {
    color: #8f9cc0;
    background-color: #d5d9e4;
    border-color: #cdd2df;
  }

  .btn-light:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: none;
    }
  }

  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: none;
  }

  .btn-indigo {
    color: $dark-white;
    background-color: #4b0082;
    border-color: #4b0082;

    &:hover {
      color: $dark-white;
      background-color: #49047b;
      border-color: #49047b;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(75, 0, 130, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $dark-white;
      background-color: #4b0082;
      border-color: #4b0082;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $dark-white;
        background-color: #4b0082;
        border-color: #4b0082;
      }
    }
  }

  .show > .btn-indigo.dropdown-toggle {
    color: $dark-white;
    background-color: #4b0082;
    border-color: #4b0082;
  }

  .btn-indigo:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(75, 0, 130, 0.5);
    }
  }

  .show > .btn-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(75, 0, 130, 0.5);
  }

  .btn-purple {
    color: $dark-white;
    background-color: #6f42c1;
    border-color: #643ab0;

    &:hover {
      color: $dark-white;
      background-color: #5e37a6;
      border-color: #4e2d89;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $dark-white;
      background-color: #6f42c1;
      border-color: #643ab0;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $dark-white;
        background-color: #59339d;
        border-color: #482a80;
      }
    }
  }

  .show > .btn-purple.dropdown-toggle {
    color: $dark-white;
    background-color: #59339d;
    border-color: #482a80;
  }

  .btn-purple:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
    }
  }

  .show > .btn-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
  }

  .btn-pink {
    color: $dark-white;
    background-color: #f10075;
    border-color: #d80069;

    &:hover {
      color: $dark-white;
      background-color: #cb0062;
      border-color: #a50050;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $dark-white;
      background-color: #f10075;
      border-color: #d80069;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $dark-white;
        background-color: #be005c;
        border-color: #98004a;
      }
    }
  }

  .show > .btn-pink.dropdown-toggle {
    color: $dark-white;
    background-color: #be005c;
    border-color: #98004a;
  }

  .btn-pink:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
    }
  }

  .show > .btn-pink.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
  }

  .btn-gray-500 {
    color: #8f9cc0;
    background-color: $white-7;
    border-color: #8896af;

    &:hover {
      color: $dark-white;
      background-color: #808faa;
      border-color: #697b9a;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
    }

    &.disabled,
    &:disabled {
      color: #8f9cc0;
      background-color: $white-7;
      border-color: #8896af;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $dark-white;
        background-color: #7988a4;
        border-color: #637493;
      }
    }
  }

  .show > .btn-gray-500.dropdown-toggle {
    color: $dark-white;
    background-color: #7988a4;
    border-color: #637493;
  }

  .btn-gray-500:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
    }
  }

  .show > .btn-gray-500.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
  }

  .btn-gray-700 {
    color: $dark-white;
    background-color: #3c4858;
    border-color: #4f5c73;

    &:hover {
      color: $dark-white;
      background-color: #49566b;
      border-color: #3a4455;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $dark-white;
      background-color: #3c4858;
      border-color: #4f5c73;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $dark-white;
        background-color: #445064;
        border-color: #353e4d;
      }
    }
  }

  .show > .btn-gray-700.dropdown-toggle {
    color: $dark-white;
    background-color: #445064;
    border-color: #353e4d;
  }

  .btn-gray-700:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
    }
  }

  .show > .btn-gray-700.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
  }

  [class*="btn-outline-"] {
    border-width: 1px;
    background-color: transparent;

    &:hover {
      color: $dark-white;
    }
  }
}

[class*="btn-outline-"]:focus {
  color: $dark-white;
}

.dark-theme {
  .btn-outline-indigo {
    border-color: #4b0082;
    color: #4b0082;

    &:hover,
    &:focus {
      background-color: #49047b;
    }
  }
}

.sp-container button:hover {
  color: #8f9cc0;
}

.dark-theme {
  .btn-secondary {
    color: $white;
    background-color: $secondary;
    border-color: #ea67a2;

    &:hover {
      color: $white;
      background-color: #de287a;
      border-color: #ea67a2;
    }

    &:focus,
    &.focus {
      background-color: #de287a !important;
      border-color: #ea67a2 !important;
      box-shadow: 0 0 0 1px rgba(241, 56, 139, 0.3);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #d14282;
      border-color: #ea67a2;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #d14282;
        border-color: #ea67a2;
      }
    }
  }

  .show > .btn-secondary.dropdown-toggle {
    color: $white;
    background-color: $secondary;
    border-color: #ea67a2;
  }

  .btn-secondary:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 1px rgba(241, 56, 139, 0.3);
    }
  }

  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgba(241, 56, 139, 0.3);
  }

  .btn-success {
    color: $white;
    background-color: $success;
    border-color: #3ec377;

    &:hover {
      color: $white;
      background-color: #16a552;
      border-color: #3ec377;
    }

    &:focus,
    &.focus {
      color: $white;
      background-color: #16a552;
      border-color: #3ec377;
      box-shadow: 0 0 0 1px rgba(21, 165, 82, 0.3);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: $success;
      border-color: #3ec377;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #16a552;
        border-color: #3ec377;
      }
    }
  }

  .show > .btn-success.dropdown-toggle {
    color: $white;
    background-color: $success;
    border-color: #3ec377;
  }

  .btn-success:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 1px rgba(21, 165, 82, 0.3);
    }
  }

  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgba(21, 165, 82, 0.3);
  }

  .btn-info {
    color: $white;
    background-color: $info;
    border-color: #55b0ff;

    &:hover {
      color: $white;
      background-color: #06aff1;
      border-color: #55b0ff;
    }

    &:focus,
    &.focus {
      color: $white;
      background-color: #06aff1;
      border-color: #55b0ff;
      box-shadow: 0 0 0 1px rgb(1, 184, 255);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: $info;
      border-color: #55b0ff;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #06aff1;
        border-color: #55b0ff;
      }
    }
  }

  .show > .btn-info.dropdown-toggle {
    color: $white;
    background-color: #06aff1;
    border-color: #55b0ff;
  }

  .btn-info:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 1px $info;
    }
  }

  .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px $info;
  }

  .btn-warning {
    color: $white;
    background-color: $warning;
    border-color: #ff8c00;

    &:hover {
      color: $white;
      background-color: #f1911d;
      border-color: #ff8c00;
    }

    &:focus,
    &.focus {
      color: $white;
      background-color: #f1911d;
      border-color: #ff8c00;
      box-shadow: 0 0 0 1px rgba(241, 145, 29, 0.3);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: $warning;
      border-color: #ff8c00;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #f1911d;
        border-color: #ff8c00;
      }
    }
  }

  .show > .btn-warning.dropdown-toggle {
    color: $white;
    background-color: $warning;
    border-color: #ff8c00;
  }

  .btn-warning:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 1px rgba(241, 145, 29, 0.3);
  }
}

.btn-warning:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 1px rgba(241, 145, 29, 0.3);
}

.dark-theme {
  .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgba(241, 145, 29, 0.3);
  }

  .btn-danger {
    color: $white;
    background-color: $danger;
    border-color: #ff334d;

    &:hover {
      color: $white;
      background-color: #ec4157;
      border-color: #ff334d;
    }

    &:focus,
    &.focus {
      color: $white;
      background-color: #ec4157;
      border-color: #ff334d;
      box-shadow: 0 0 0 1px rgb(255, 71, 61);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: $danger;
      border-color: #ff334d;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #ec4157;
        border-color: #ff334d;
      }
    }
  }

  .show > .btn-danger.dropdown-toggle {
    color: $white;
    background-color: $danger;
    border-color: #ff334d;
  }

  .btn-danger:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 1px #ff473d;
    }
  }

  .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px #ff473d;
  }

  .btn-white {
    background-color: $dark-theme;
    border-color: transparent;
    color: $dark-white;

    &:hover {
      background-color: $dark-theme;
      border-color: transparent;
      color: $dark-white;
    }

    &:focus,
    &.focus {
      background-color: $dark-theme;
      border-color: transparent;
      color: $dark-white;
      box-shadow: none;
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: $dark-theme;
      border-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $dark-white;
        background-color: $dark-theme;
        border-color: transparent;
      }
    }
  }

  .show > .btn-white.dropdown-toggle {
    color: #495057;
    background-color: #eff0f6;
    border-color: transparent;
  }

  .btn-white:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: none;
    }
  }

  .show > .btn-white.dropdown-toggle:focus {
    box-shadow: none;
  }

  .btn-dark {
    color: $white;
    background-color: #8f9cc0;
    border-color: #8f9cc0;

    &:hover {
      color: $white;
      background-color: #131212;
      border-color: #131212;
    }

    &:focus,
    &.focus {
      color: $white;
      background-color: #131212;
      border-color: #131212;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: $dark;
      border-color: $dark;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #131212;
        border-color: #232b3b;
      }
    }
  }

  .show > .btn-dark.dropdown-toggle {
    color: $dark-white;
    background-color: #131212;
    border-color: #232b3b;
  }

  .btn-dark:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
    }
  }

  .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
  }

  .btn-outline-secondary {
    color: $secondary !important;
    border-color: $secondary;

    &:hover {
      color: $white !important;
      background-color: $secondary;
      border-color: $secondary;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 1px #ec831b;
    }

    &.disabled,
    &:disabled {
      color: $secondary !important;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $dark-white;
        background-color: $secondary;
        border-color: $secondary;
      }
    }
  }

  .show > .btn-outline-secondary.dropdown-toggle {
    color: $dark-white;
    background-color: $secondary;
    border-color: $secondary;
  }

  .btn-outline-secondary:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 1px #ec831b;
    }
  }

  .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px #ec831b;
  }

  .btn-outline-success {
    color: $success !important;
    border-color: $success;

    &:hover {
      color: $white !important;
      background-color: $success;
      border-color: $success;
    }

    &:focus,
    &.focus {
      color: $white !important;
      background-color: $success;
      border-color: $success;
      box-shadow: 0 0 0 1px #38d250;
    }

    &.disabled,
    &:disabled {
      color: $success !important;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $dark-white;
        background-color: $success;
        border-color: $success;
      }
    }
  }

  .show > .btn-outline-success.dropdown-toggle {
    color: $dark-white;
    background-color: $success;
    border-color: $success;
  }

  .btn-outline-success:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 1px #38d250;
    }
  }

  .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px #38d250;
  }

  .btn-outline-info {
    color: $info !important;
    border-color: $info;

    &:hover {
      color: $white !important;
      background-color: $info;
      border-color: $info;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 1px $info;
    }

    &.disabled,
    &:disabled {
      color: $info !important;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $dark-white;
        background-color: $info;
        border-color: $info;
      }
    }
  }

  .show > .btn-outline-info.dropdown-toggle {
    color: $dark-white;
    background-color: $info;
    border-color: $info;
  }

  .btn-outline-info:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 1px $info;
    }
  }

  .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px $info;
  }

  .btn-outline-warning {
    color: $warning !important;
    border-color: $warning;

    &:hover {
      color: $white !important;
      background-color: $warning;
      border-color: $warning;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 1px #ffc107;
    }

    &.disabled,
    &:disabled {
      color: $warning !important;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: #8f9cc0;
        background-color: $warning;
        border-color: $warning;
      }
    }
  }

  .show > .btn-outline-warning.dropdown-toggle {
    color: #8f9cc0;
    background-color: $warning;
    border-color: $warning;
  }

  .btn-outline-warning:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 1px #ffc107;
    }
  }

  .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px #ffc107;
  }

  .btn-outline-danger {
    color: #f16d75 !important;
    border-color: #f16d75;

    &:hover {
      color: $white !important;
      background-color: #f16d75;
      border-color: #f16d75;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 1px #ff473d;
    }

    &.disabled,
    &:disabled {
      color: #f16d75 !important;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $dark-white !important;
        background-color: #f16d75;
        border-color: #f16d75;
      }
    }
  }

  .show > .btn-outline-danger.dropdown-toggle {
    color: $dark-white !important;
    background-color: #f16d75;
    border-color: #f16d75;
  }

  .btn-outline-danger:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 1px #ff473d;
    }
  }

  .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px #ff473d;
  }

  .btn-outline-light {
    color: $dark-white !important;
    border-color: $dark-border !important;

    &:hover {
      color: $dark-white !important;
      background-color: $dark-theme !important;
      border-color: $dark-border;
    }

    &:focus {
      box-shadow: 0 0 0 1px #616477;
    }
  }
}

.btn-outline-light.focus {
  box-shadow: 0 0 0 1px #616477;
}

.dark-theme {
  .btn-outline-light {
    &.disabled,
    &:disabled {
      color: $white !important;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: #8f9cc0;
        background-color: #f0f2f8;
        border-color: #f0f2f8;
      }
    }
  }

  .show > .btn-outline-light.dropdown-toggle {
    color: #8f9cc0;
    background-color: #f0f2f8;
    border-color: #f0f2f8;
  }

  .btn-outline-light:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 1px $border;
    }
  }

  .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px $border;
  }

  .btn-outline-dark {
    color: $dark-white !important;
    border-color: #8f9cc0;

    &:hover {
      color: $dark-white !important;
      background-color: #8f9cc0;
      border-color: #8f9cc0;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
    }

    &.disabled,
    &:disabled {
      color: $white !important;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $dark-white;
        background-color: #8f9cc0;
        border-color: #000000;
      }
    }
  }

  .show > .btn-outline-dark.dropdown-toggle {
    color: $dark-white;
    background-color: #8f9cc0;
    border-color: #000000;
  }

  .btn-outline-dark:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
    }
  }

  .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
  }

  .btn-link {
    &:focus,
    &.focus {
      box-shadow: none;
    }

    &:disabled,
    &.disabled {
      color: $white-7;
    }
  }

  .btn-default {
    color: $dark-white;
  }

  .btn-primary-transparent {
    background-color: $primary-02;
    color: $primary;
    &:hover {
      color: $primary;
    }
  }

  .btn-secondary-transparent {
    background-color: rgba(241, 56, 139, 0.2);
    color: $secondary;
    &:hover {
      color: $secondary;
    }
  }

  .btn-success-transparent {
    background-color: rgba(25, 177, 89, 0.2);
    color: $success;
    &:hover {
      color: $success;
    }
  }

  .btn-danger-transparent {
    background-color: rgba(253, 96, 116, 0.2);
    color: $danger;
    &:hover {
      color: $danger;
    }
  }

  .btn-info-transparent {
    background-color: rgba(1, 184, 255, 0.2);
    color: $info;
    &:hover {
      color: $info;
    }
  }

  .btn-warning-transparent {
    background-color: rgba(255, 155, 33, 0.2);
    color: $warning;
    &:hover {
      color: $warning;
    }
  }

  .btn-dark-transparent {
    background-color: rgba(143, 156, 192, 0.2) !important;
    color: #8f9cc0 !important;
    &:hover {
      color: #8f9cc0;
    }
  }

  .card {
    background-color: $dark-theme;
    border: 1px solid $dark-border;

    &.text-white .main-content-label,
    &.tx-white .main-content-label {
      color: $white;
    }
  }

  .main-content-label {
    color: #3c4858;
  }

  .card .card {
    box-shadow: none;
  }

  .card-header {
    border-bottom: 1px solid $dark-border;
    background: transparent;

    + .list-group .list-group-item:first-child {
      border-top: 0;
    }
  }

  .card-footer {
    background: $dark-white;
    border-top: 1px solid $border;
    background: transparent;
  }

  .card-header-tabs {
    border-bottom: 0;
  }

  .card-header,
  .card-footer {
    border-color: $dark-border;
  }

  .card-header-tab {
    border-bottom: 0;
  }

  .card-item {
    .card-item-icon.card-icon {
      background: $darkprimary-transparent;
      fill: $primary;
    }

    .card-item-title {
      color: #383942;

      small {
        color: #8d95b1;
      }
    }

    .card-item-body {
      .card-item-stat {
        .card-item-figure {
          color: #383942;
        }

        small {
          color: #8d95b1;
        }
      }

      .card-item-redirect .btn-card-item-view {
        background: #f4f7fd;
        color: #a5abba;
        border: none;
      }
    }
  }

  .card-dashboard-calendar {
    box-shadow: -7.829px 11.607px 20px 0px #e4e3f5;

    .main-content-label {
      color: #1c273c;
    }

    .ui-datepicker-inline {
      border-width: 0;
    }

    .ui-datepicker {
      .ui-datepicker-header {
        border-bottom: 0;
      }

      .ui-datepicker-calendar .ui-datepicker-today a {
        color: $dark-white;
      }
    }
  }

  .card-dashboard-twentyfour {
    .card-header {
      background-color: transparent;
    }

    .card-body-top {
      h6 {
        color: #8f9cc0;

        span {
          color: $white-7;
        }
      }

      label {
        color: $white-7;
      }
    }

    .main-content-label,
    .card-table-two .main-content-label {
      color: #8f9cc0;
    }
  }

  .card-table-two .card-dashboard-twentyfour .main-content-label,
  .card-dashboard-twentyfour .card-dashboard-eight .main-content-label,
  .card-dashboard-eight .card-dashboard-twentyfour .main-content-label {
    color: #8f9cc0;
  }

  .card-dashboard-twentyfive {
    .col + .col {
      border-left: 1px solid $border;
    }

    .card-label {
      color: $white-7;
    }

    .card-value {
      color: #8f9cc0;
    }
  }

  .card-dashboard-twentysix {
    .card-header {
      background-color: transparent;
    }

    .card-body {
      h6 {
        color: #8f9cc0;
      }

      label {
        color: $white-7;
      }
    }

    .flot-chart {
      .flot-x-axis > div {
        color: rgba(255, 255, 255, 0.75);
      }

      .flot-y-axis > div {
        color: rgba(28, 39, 60, 0.5);
      }
    }

    &.card-dark-one .main-content-label,
    &.card-dark-two .main-content-label,
    &.card-dark-one .card-body h6,
    &.card-dark-two .card-body h6 {
      color: $dark-white;
    }

    &.card-dark-one .card-body h6 span,
    &.card-dark-two .card-body h6 span {
      color: rgba(255, 255, 255, 0.5);
    }

    &.card-dark-one .card-body label,
    &.card-dark-two .card-body label {
      color: rgba(255, 255, 255, 0.7);
    }

    &.card-dark-one .flot-chart .flot-y-axis > div,
    &.card-dark-two .flot-chart .flot-y-axis > div {
      color: rgba(255, 255, 255, 0.2);
    }
  }

  .custom-card {
    border: 0px solid $dark-border;
    box-shadow: 0 8px 16px 0 $black-1;
  }

  .card-aside-column {
    background: no-repeat center/cover;
  }

  .card-options {
    color: $white-7;

    a:not(.btn) {
      color: $dark-white;

      &:hover {
        color: $white;
      }
    }
  }

  .custom-card-header {
    background: $dark-theme;
  }

  .card-body + .card-body {
    border-top: 1px solid $dark-border;
  }

  .card-blog-overlay:before,
  .card-blog-overlay1:before,
  .card-blog-overlay2:before {
    background: rgba(0, 0, 0, 0.5);
  }

  .card-blog-overlay .custom-card-header,
  .card-blog-overlay1 .custom-card-header,
  .card-blog-overlay2 .custom-card-header {
    background: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .card-blog-overlay .card-footer,
  .card-blog-overlay1 .card-footer,
  .card-blog-overlay2 .card-footer {
    background: none;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  .card-item-desc .card-item-desc-1 dd {
    color: $white-5;
  }

  .carousel-control-prev,
  .carousel-control-next {
    color: $white;
  }

  .carousel-control-prev {
    &:hover,
    &:focus {
      color: $white;
    }
  }

  .carousel-control-next {
    &:hover,
    &:focus {
      color: $white;
    }
  }

  .carousel-indicators li {
    background-color: $white;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  .carousel-caption {
    color: $white;
  }

  .carousel-dark .carousel-caption {
    color: #000;
  }

  .carousel-slider #thumbcarousel .carousel-item .thumb:hover {
    border-color: #cad4e4;
  }

  .carousel-control {
    &:active,
    &:focus,
    &:hover {
      color: #333;
    }
  }

  #thumbcarousel {
    .carousel-control-prev,
    .carousel-control-next {
      background: rgba(0, 0, 0, 0.8);
    }
  }

  .owl-nav button {
    background: $dark-theme !important;
    border: 1px solid $dark-border !important;
    box-shadow: 0 4px 15px rgba(67, 67, 67, 0.15);

    &:before {
      background: #2098d1;
    }
  }

  .owl-carousel:hover .owl-nav button {
    background: $dark-body !important;
  }

  .owl-theme .owl-dots {
    -webkit-tap-highlight-color: transparent;

    .owl-dot span {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .product-carousel .thumb {
    border: 1px solid #cad4e4;
  }

  .dropup .dropdown-toggle::after {
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }

  .dropend .dropdown-toggle::after {
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }

  .dropstart .dropdown-toggle::before {
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
  }

  .dropdown-divider {
    border-top: 1px solid $dark-border;
  }

  .dropdown-item {
    color: $dark-white;
    background-color: transparent;
    border: 0;

    &:hover,
    &:focus {
      color: $dark-white;
      background-color: $dark-body;
    }

    &.disabled,
    &:disabled {
      color: $dark-white;
      background-color: transparent;
      opacity: 0.2;
    }

    &.active {
      background-color: $primary;
      color: $white;
    }
  }

  .dropdown-header {
    color: $white-7;
  }

  .dropdown-item-text {
    color: #8f9cc0;
  }

  .dropdown-menu {
    .main-header-center {
      .select2-container--default .select2-selection--single {
        background: #eaebfa;
      }

      .search-btn {
        color: $white;
      }
    }

    .main-form-search {
      .btn,
      .sp-container button {
        color: $dark-white;
      }
    }

    .sp-container .main-form-search button {
      color: $dark-white;
    }

    .main-header-center .form-control {
      background: #eaebfa;
      border: 1px solid $border;
    }

    border-width: 1px;
    color: #8f9cc0;
    background-color: $dark-theme;
    border: 1px solid $dark-border;
    box-shadow: -8px 12px 18px 0 rgba(21, 21, 62, 0.3);
  }

  .form-control {
    color: $white-7 !important;
    background-color: $dark-theme;
    border: 1px solid $dark-border !important;

    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    &:focus {
      color: #8f9cc0;
      box-shadow: 0 0 0 0.2rem rgba(135, 96, 251, 0.25);
      border-color: #e0e1fb;
      box-shadow: none;
    }

    &::placeholder {
      color: $white-3;
    }

    &[readonly] {
      background-color: $dark-body;
    }
    &:disabled {
      background-color: $black-2;
    }
  }

  select.form-control:focus::-ms-value {
    color: #8f9cc0;
    background-color: $dark-white;
  }

  .form-control-plaintext {
    color: #8f9cc0;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
  }

  .form-check-input:disabled ~ .form-check-label {
    color: $white-7;
  }

  .form-label {
    color: $dark-white;
  }

  .file-browser .btn-default {
    color: $dark-white;
    border: 1px solid #e2e8f5;
  }

  .main-form-group {
    border: 1px solid $border;

    &.focus {
      border-color: #b4bdce;
      box-shadow: 0 0 0 2px rgba(91, 71, 251, 0.16);
    }

    .form-label {
      color: $white-7;
    }

    .form-control {
      border-width: 0;
      color: #8f9cc0;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .valid-feedback {
    color: $success !important;
  }

  .valid-tooltip {
    color: $white;
    background-color: $success;
  }

  .was-validated .form-control:valid,
  .form-control.is-valid,
  input.form-control.parsley-success,
  textarea.form-control.parsley-success {
    border-color: $success !important;
  }

  .was-validated .form-control:valid:focus,
  .form-control.is-valid:focus,
  input.form-control.parsley-success:focus,
  textarea.form-control.parsley-success:focus {
    border-color: $success !important;
    box-shadow: none;
  }

  .was-validated .form-select:valid,
  .form-select.is-valid,
  input.form-select.parsley-success,
  textarea.form-select.parsley-success {
    border-color: $success !important;
  }

  .was-validated .form-select:valid:focus,
  .form-select.is-valid:focus,
  input.form-select.parsley-success:focus,
  textarea.form-select.parsley-success:focus {
    border-color: $success !important;
    box-shadow: none;
  }

  .was-validated .form-check-input:valid ~ .form-check-label,
  .form-check-input.is-valid ~ .form-check-label,
  input.form-check-input.parsley-success ~ .form-check-label,
  textarea.form-check-input.parsley-success ~ .form-check-label,
  .was-validated .custom-control-input:valid ~ .custom-control-label,
  .custom-control-input.is-valid ~ .custom-control-label,
  input.custom-control-input.parsley-success ~ .custom-control-label,
  textarea.custom-control-input.parsley-success ~ .custom-control-label {
    color: $success !important;
  }

  .was-validated .custom-control-input:valid ~ .custom-control-label::before,
  .custom-control-input.is-valid ~ .custom-control-label::before,
  input.custom-control-input.parsley-success ~ .custom-control-label::before,
  textarea.custom-control-input.parsley-success ~ .custom-control-label::before {
    border-color: $success !important;
  }

  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
  .custom-control-input.is-valid:checked ~ .custom-control-label::before,
  input.custom-control-input.parsley-success:checked ~ .custom-control-label::before,
  textarea.custom-control-input.parsley-success:checked ~ .custom-control-label::before {
    border-color: #4ce301;
    background-color: #4ce301;
  }

  .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
  .custom-control-input.is-valid:focus ~ .custom-control-label::before,
  input.custom-control-input.parsley-success:focus ~ .custom-control-label::before,
  textarea.custom-control-input.parsley-success:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.25);
  }

  .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
  .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before,
  input.custom-control-input.parsley-success:focus:not(:checked) ~ .custom-control-label::before,
  textarea.custom-control-input.parsley-success:focus:not(:checked) ~ .custom-control-label::before,
  .was-validated .custom-file-input:valid ~ .custom-file-label,
  .custom-file-input.is-valid ~ .custom-file-label,
  input.custom-file-input.parsley-success ~ .custom-file-label,
  textarea.custom-file-input.parsley-success ~ .custom-file-label {
    border-color: $success !important;
  }

  .was-validated .custom-file-input:valid:focus ~ .custom-file-label,
  .custom-file-input.is-valid:focus ~ .custom-file-label,
  input.custom-file-input.parsley-success:focus ~ .custom-file-label,
  textarea.custom-file-input.parsley-success:focus ~ .custom-file-label {
    border-color: $success !important;
    box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.25);
  }

  .invalid-feedback {
    color: #f16d75 !important;
  }

  .invalid-tooltip {
    color: $white;
    background-color: #f16d75;
  }

  .was-validated .form-control:invalid,
  .form-control.is-invalid,
  input.form-control.parsley-error,
  textarea.form-control.parsley-error {
    border-color: #f16d75 !important;
  }

  .was-validated .form-control:invalid:focus,
  .form-control.is-invalid:focus,
  input.form-control.parsley-error:focus,
  textarea.form-control.parsley-error:focus {
    border-color: #f16d75 !important;
    box-shadow: none;
  }

  .was-validated .form-select:invalid,
  .form-select.is-invalid,
  input.form-select.parsley-error,
  textarea.form-select.parsley-error {
    border-color: #f16d75 !important;
  }

  .was-validated .form-select:invalid:focus,
  .form-select.is-invalid:focus,
  input.form-select.parsley-error:focus,
  textarea.form-select.parsley-error:focus {
    border-color: #f16d75 !important;
    box-shadow: none;
  }

  .was-validated .form-check-input:invalid ~ .form-check-label,
  .form-check-input.is-invalid ~ .form-check-label,
  input.form-check-input.parsley-error ~ .form-check-label,
  textarea.form-check-input.parsley-error ~ .form-check-label,
  .was-validated .custom-control-input:invalid ~ .custom-control-label,
  .custom-control-input.is-invalid ~ .custom-control-label,
  input.custom-control-input.parsley-error ~ .custom-control-label,
  textarea.custom-control-input.parsley-error ~ .custom-control-label {
    color: #f16d75 !important;
  }

  .was-validated .custom-control-input:invalid ~ .custom-control-label::before,
  .custom-control-input.is-invalid ~ .custom-control-label::before,
  input.custom-control-input.parsley-error ~ .custom-control-label::before,
  textarea.custom-control-input.parsley-error ~ .custom-control-label::before {
    border-color: #f16d75 !important;
  }

  .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
  .custom-control-input.is-invalid:checked ~ .custom-control-label::before,
  input.custom-control-input.parsley-error:checked ~ .custom-control-label::before,
  textarea.custom-control-input.parsley-error:checked ~ .custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d;
  }

  .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
  .custom-control-input.is-invalid:focus ~ .custom-control-label::before,
  input.custom-control-input.parsley-error:focus ~ .custom-control-label::before,
  textarea.custom-control-input.parsley-error:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
  .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before,
  input.custom-control-input.parsley-error:focus:not(:checked) ~ .custom-control-label::before,
  textarea.custom-control-input.parsley-error:focus:not(:checked) ~ .custom-control-label::before,
  .was-validated .custom-file-input:invalid ~ .custom-file-label,
  .custom-file-input.is-invalid ~ .custom-file-label,
  input.custom-file-input.parsley-error ~ .custom-file-label,
  textarea.custom-file-input.parsley-error ~ .custom-file-label {
    border-color: #f16d75 !important;
  }

  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
  .custom-file-input.is-invalid:focus ~ .custom-file-label,
  input.custom-file-input.parsley-error:focus ~ .custom-file-label,
  textarea.custom-file-input.parsley-error:focus ~ .custom-file-label {
    border-color: #f16d75 !important;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  #example-input .form-control {
    color: #8f9cc0 !important;

    &::placeholder {
      color: #8f9cc0 !important;
    }
  }

  .input-group-text {
    color: $dark-white;
    background-color: $dark-theme;
    border: 1px solid $dark-border;
    border-right: none;
  }

  .modal.effect-just-me {
    .btn-close {
      text-shadow: none;
      color: $dark-white;
    }

    .modal-header {
      background-color: transparent;
      border-bottom-color: rgba(255, 255, 255, 0.1);

      h6 {
        color: $dark-white;
      }
    }

    .modal-content {
      background: #000000;
    }

    .modal-body {
      color: rgba(255, 255, 255, 0.8);

      h6 {
        color: $dark-white;
      }
    }

    .modal-footer {
      background-color: transparent;
      border-top-color: rgba(255, 255, 255, 0.1);
    }
  }

  .btn-close:hover {
    color: inherit;
  }

  button.btn-close {
    background-color: transparent;
    border: 0;
  }

  .modal-backdrop {
    background-color: #202029;
  }

  .modal-header {
    border-bottom: 1px solid $dark-border;
  }

  .modal-title {
    color: $dark-white;
  }

  .modal-footer {
    border-top: 1px solid $dark-border;
  }

  .modal-content-demo .modal-body h6 {
    color: $dark-white;
  }

  .modal-backdrop {
    background-color: #0a0a0a;
  }

  .modal-content {
    background-color: $dark-theme;
    border: 1px solid $dark-border;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12), 0 8px 16px 0 rgba(0, 0, 0, 0.24);
    border-width: 0;
  }

  div.dtr-modal div.dtr-modal-content {
    box-shadow: none;
    background-color: $dark-theme;
  }
  div.dtr-modal div.dtr-modal-close {
    border: 1px solid $dark-border;
    background-color: $dark-theme;
  }
  table.dataTable > tbody > tr.child ul.dtr-details > li {
    border-bottom: 1px solid $dark-border;
  }

  .nav-pills .nav-link {
    color: $dark-white;

    &:hover,
    &:focus {
      color: $primary;
    }

    &.active {
      color: $white;

      &:hover,
      &:focus {
        color: $white;
      }
    }
  }

  .nav-dark .nav-link {
    color: $white-7;

    &:hover,
    &:focus {
      color: $dark-white;
    }
  }

  .main-nav .nav-link {
    color: $dark-white;

    &:hover,
    &:focus {
      color: $primary;
    }
  }

  .main-nav-column .nav-link {
    color: $dark-white;

    i:not([class*=" tx-"]) {
      color: $white-5;
    }

    span {
      background: #f0f2f8;
    }

    &:hover,
    &:focus,
    &:hover i:not([class*=" tx-"]),
    &:focus i:not([class*=" tx-"]) {
      color: $primary;
    }

    &.active i:not([class*=" tx-"]) {
      color: $primary !important;
    }
  }

  .main-nav-dark .nav-link {
    color: rgba(255, 255, 255, 0.7);

    &:hover,
    &:focus {
      color: $dark-white;
    }

    + .nav-link {
      border-color: #3c4858;
    }
  }

  .main-nav-colored-bg .nav-link {
    + .nav-link {
      border-color: rgba(255, 255, 255, 0.4);
    }

    &.active {
      color: $dark-white;
    }
  }

  .main-nav-line {
    .nav-link {
      color: $dark-white;

      &.active {
        color: $primary;
      }
    }

    &.main-nav-dark .nav-link {
      color: rgba(255, 255, 255, 0.7);

      &:hover,
      &:focus {
        color: $dark-white;
      }

      &.active {
        color: $dark-white;

        &::before {
          background-color: $dark-white;
        }
      }
    }
  }

  .page-link {
    color: $dark-white;
    border-width: 0;
    border: 1px solid $dark-border;
    background-color: $dark-theme;

    &:hover,
    &:focus {
      background-color: $dark-body;
      box-shadow: none;
    }

    &:hover {
      background-color: $dark-body;
      border-color: $dark-border;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .page-item {
    &.active .page-link {
      color: $dark-white;
    }

    &.disabled .page-link {
      color: $white-2;
      background-color: $dark-theme;
      border-color: $dark-border;
    }
  }

  .pagination-dark {
    .page-item.active .page-link {
      color: $dark-white;
    }

    .page-link {
      background-color: rgba(255, 255, 255, 0.05);
      color: rgba(255, 255, 255, 0.8);

      &:hover,
      &:focus {
        color: $dark-white;
        background-color: rgba(255, 255, 255, 0.08);
      }
    }
  }

  .pagination-success .page-item.active .page-link {
    background-color: $success;
    border-color: $success;
  }

  .pagination-circle {
    background: #f0f2f8;

    li {
      a,
      span {
        border: none;
        border: 0;
        background: $dark-white;
      }
    }
  }

  .pagination-radius li {
    a,
    span {
      border: none;
      border: 1px solid #dee4f5;
      background: $dark-white;
    }
  }

  .popover {
    text-shadow: none;
    background-color: $dark-theme;
    border: none;

    &.show {
      box-shadow: -8px 12px 18px 0 rgba(21, 21, 62, 0.3);
      border: 1px solid rgba(255, 255, 255, 0.12);
    }

    .popover-arrow {
      &::before,
      &::after {
        border-color: transparent;
        border-style: solid;
      }
    }
  }

  .popover-header {
    background-color: $primary;
    border-bottom: 1px solid $dark-border;
  }

  .popover-body {
    color: $dark-white;
  }

  .popover-head-primary .popover-header {
    color: $dark-white;
    background: $primary;
  }

  .popover-head-secondary {
    .popover-header {
      color: $dark-white;
      background-color: $secondary;
    }

    &.bs-popover-bottom .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
      border-bottom-color: $secondary;
    }
  }

  .popover-head-success {
    .popover-header {
      color: $dark-white;
      background-color: $success;
    }

    &.bs-popover-bottom .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
      border-bottom-color: $success;
    }
  }

  .popover-head-info {
    .popover-header {
      color: $dark-white;
      background-color: $info;
    }

    &.bs-popover-bottom .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
      border-bottom-color: $info;
    }
  }

  .popover-head-primary,
  .popover-head-secondary,
  .popover-head-success,
  .popover-head-info,
  .popover-head-primary .popover-header,
  .popover-head-secondary .popover-header,
  .popover-head-success .popover-header,
  .popover-head-info .popover-header {
    border: 0;
  }

  .popover-head-primary .popover-body,
  .popover-head-secondary .popover-body,
  .popover-head-success .popover-body,
  .popover-head-info .popover-body {
    border-top-width: 0;
  }

  .popover-primary {
    border-width: 0;
    background: $primary;

    .popover-header {
      background-color: transparent;
      border-bottom-width: 0;
      color: $dark-white;
    }

    .popover-body {
      color: rgba(255, 255, 255, 0.75);
    }

    &.bs-popover-top .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
      border-top-color: $primary;
    }

    &.bs-popover-bottom .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
      border-bottom-color: $primary;
    }

    &.bs-popover-start .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
      border-left-color: $primary;
    }

    &.bs-popover-end .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
      border-right-color: $primary;
    }
  }

  .popover-secondary {
    background-color: $secondary;
    border-width: 0;

    .popover-header {
      background-color: transparent;
      border-bottom-width: 0;
      color: $dark-white;
    }

    .popover-body {
      color: rgba(255, 255, 255, 0.75);
    }

    &.bs-popover-top .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
      border-top-color: $secondary;
    }

    &.bs-popover-bottom .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
      border-bottom-color: $secondary;
    }

    &.bs-popover-start .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
      border-left-color: $secondary;
    }

    &.bs-popover-end .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
      border-right-color: $secondary;
    }
  }

  .popover-success {
    background-color: $success;
    border-width: 0;

    .popover-header {
      background-color: transparent;
      border-bottom-width: 0;
      color: $dark-white;
    }

    .popover-body {
      color: rgba(255, 255, 255, 0.75);
    }

    &.bs-popover-top .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
      border-top-color: $success;
    }

    &.bs-popover-bottom .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
      border-bottom-color: $success;
    }

    &.bs-popover-start .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
      border-left-color: $success;
    }

    &.bs-popover-end .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
      border-right-color: $success;
    }
  }

  .popover-info {
    background-color: $info;
    border-width: 0;

    .popover-header {
      background-color: transparent;
      border-bottom-width: 0;
      color: $dark-white;
    }

    .popover-body {
      color: rgba(255, 255, 255, 0.75);
    }

    &.bs-popover-top .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
      border-top-color: $info;
    }

    &.bs-popover-bottom .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
      border-bottom-color: $info;
    }

    &.bs-popover-start .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
      border-left-color: $info;
    }

    &.bs-popover-end .popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
      border-right-color: $info;
    }
  }

  .popover-static-demo {
    background-color: $dark-body;

    .popover {
      border: 1px solid rgba(255, 255, 255, 0.12);
    }
  }

  .bs-popover-top > .popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(255, 255, 255, 0.12);
  }

  .bs-popover-top > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: $dark-theme;
  }

  .bs-popover-end > .popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(255, 255, 255, 0.12);
  }

  .bs-popover-end > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: $dark-theme;
  }

  .bs-popover-bottom > .popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(255, 255, 255, 0.12);
  }

  .bs-popover-bottom > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: $primary;
  }

  .bs-popover-bottom .popover-header::before,
  .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
    border-bottom: 1px solid #f7f7f7;
  }

  .bs-popover-start > .popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(255, 255, 255, 0.12);
  }

  .bs-popover-start > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: $dark-theme;
  }

  .progress {
    background-color: $dark-body;
  }

  .progress-bar {
    color: $dark-white;
  }

  .table {
    color: $dark-white;

    th,
    td {
      border-top: 0;
    }

    thead th {
      border-bottom: 1px solid $border;
    }

    tbody + tbody {
      border-top: 1px solid $border;
    }

    thead {
      th,
      td {
        color: $dark-white;
        border-bottom-width: 1px;
        border-top-width: 0;
      }
    }

    tbody tr {
      background-color: $dark-theme;
    }
  }

  .table-bordered {
    border: 1px solid $dark-border;

    th,
    td {
      border: 1px solid $dark-border;
    }

    thead {
      th,
      td {
        border-bottom-width: 2px;
        border-top-width: 1px;
        background-color: #efefff;
      }
    }
  }

  .table-borderless {
    th,
    td,
    thead th,
    tbody + tbody {
      border: 0;
    }
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .table-hover tbody tr:hover {
    background-color: $dark-body;
    color: $dark-white;
  }

  .table-primary {
    background-color: #c6d4ff;

    > {
      th,
      td {
        background-color: #c6d4ff;
      }
    }

    th,
    td,
    thead th,
    tbody + tbody {
      border-color: #95afff;
    }
  }

  .table-hover .table-primary:hover {
    background-color: #adc1ff;

    > td {
      background-color: #adc1ff;
    }
  }
}

/* ###### Buttons ###### */

/* ###### Card  ###### */

@media (min-width: 576px) {
  .dark-theme .card-group > .card + .card {
    border-left: 0;
  }
}

/* ###### Card  ###### */

/* ###### carousel ###### */

/* ###### carousel ###### */

/* ########## Dropdown ########## */

/* ########## Dropdown ########## */

/* ###### Form-elements ###### */

/* ###### Form elements ###### */

/* ###### Input-group ###### */

/* ###### Input-group ###### */

/* ###### Modal ###### */

/* ###### Modal ###### */

/* ###### Navigation   ###### */

@media (min-width: 768px) {
  .dark-theme .main-nav .nav-link + .nav-link {
    border-top: 0;
  }
}

@media (min-width: 992px) {
  .dark-theme .main-nav-line-chat {
    border-bottom: 1px solid #e2e8f5;
  }
}

/* ###### Navigation   ###### */

/* ###### Pagination  ###### */

/* ###### Pagination  ###### */

/* ###### Popover  ###### */

/* ###### Popover  ###### */

/* ###### Progress ###### */

/* ###### Progress ###### */

/* ###### Table  ###### */

.table-hover .table-primary:hover > th {
  background-color: #adc1ff;
}

.dark-theme {
  .table-secondary {
    background-color: #d9dde5;

    > {
      th,
      td {
        background-color: #d9dde5;
      }
    }

    th,
    td,
    thead th,
    tbody + tbody {
      border-color: #b9c1ce;
    }
  }

  .table-hover .table-secondary:hover {
    background-color: #cacfdb;

    > {
      td,
      th {
        background-color: #cacfdb;
      }
    }
  }

  .table-success {
    background-color: #c8e9b8;

    > {
      th,
      td {
        background-color: #c8e9b8;
      }
    }

    th,
    td,
    thead th,
    tbody + tbody {
      border-color: #99d67b;
    }
  }

  .table-hover .table-success:hover {
    background-color: #b9e3a5;

    > {
      td,
      th {
        background-color: #b9e3a5;
      }
    }
  }

  .table-info {
    background-color: #bee5eb;

    > {
      th,
      td {
        background-color: #bee5eb;
      }
    }

    th,
    td,
    thead th,
    tbody + tbody {
      border-color: #86cfda;
    }
  }

  .table-hover .table-info:hover {
    background-color: #abdde5;

    > {
      td,
      th {
        background-color: #abdde5;
      }
    }
  }

  .table-warning {
    background-color: #ffeeba;

    > {
      th,
      td {
        background-color: #ffeeba;
      }
    }

    th,
    td,
    thead th,
    tbody + tbody {
      border-color: #ffdf7e;
    }
  }

  .table-hover .table-warning:hover {
    background-color: #ffe8a1;

    > {
      td,
      th {
        background-color: #ffe8a1;
      }
    }
  }

  .table-danger {
    background-color: #f5c6cb;

    > {
      th,
      td {
        background-color: #f5c6cb;
      }
    }

    th,
    td,
    thead th,
    tbody + tbody {
      border-color: #ed969e;
    }
  }

  .table-hover .table-danger:hover {
    background-color: #f1b0b7;

    > {
      td,
      th {
        background-color: #f1b0b7;
      }
    }
  }

  .table-light {
    background-color: #fcfcfd;

    > {
      th,
      td {
        background-color: #fcfcfd;
      }
    }

    th,
    td,
    thead th,
    tbody + tbody {
      border-color: #f9fafb;
    }
  }

  .table-hover .table-light:hover {
    background-color: #ededf3;

    > {
      td,
      th {
        background-color: #ededf3;
      }
    }
  }

  .table-dark {
    color: $dark-white;
    background-color: $dark;

    > {
      th,
      td {
        background-color: #c8ccd3;
      }
    }

    th,
    td,
    thead th,
    tbody + tbody {
      border-color: #49597b;
    }

    &.table-bordered {
      border: 0;
    }

    &.table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(255, 255, 255, 0.05);
    }

    &.table-hover tbody tr:hover {
      color: $dark-white;
      background-color: rgba(255, 255, 255, 0.075);
    }
  }

  .table-hover .table-dark:hover {
    background-color: #babfc8;

    > {
      td,
      th {
        background-color: #babfc8;
      }
    }
  }

  .table-active {
    background-color: rgba(0, 0, 0, 0.075);

    > {
      th,
      td {
        background-color: rgba(0, 0, 0, 0.075);
      }
    }
  }

  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);

    > {
      td,
      th {
        background-color: rgba(0, 0, 0, 0.075);
      }
    }
  }

  .table {
    .thead-dark th {
      color: $dark-white;
      background-color: $dark;
      border-color: #49597b;
    }

    .thead-light th {
      color: #3c4858;
      background-color: #f0f2f8;
      border-color: #f0f2f8;
    }
  }

  .table-responsive > .table-bordered {
    border: 0;
  }

  .table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .table > {
    :not(:last-child) > :last-child > * {
      border-bottom-color: $dark-border !important;
    }

    :not(caption) > * > * {
      box-shadow: none;
    }
  }

  .table-bordered > :not(caption) > * {
    border: 1px solid $dark-border;
  }

  table.dataTable {
    border: 1px solid $dark-border;

    &.no-footer {
      border-bottom-color: $dark-border !important;
    }

    thead {
      th,
      td {
        border-top-width: 0;
        border-bottom-width: 0;
        color: $dark-white;
      }
    }
  }

  .dataTables_wrapper {
    .dataTables_filter input {
      border: 1px solid $dark-border;
    }

    .selected {
      background: $dark-body;
    }

    .dataTables_paginate .paginate_button.current {
      border: 0;
      color: $dark-white !important;

      &:hover,
      &:focus {
        border: 0;
        background-image: none;
        color: $dark-white !important;
      }
    }
  }

  .table.dataTable {
    tr {
      border: 1px solid $dark-border;
    }
  }

  table.table-bordered.dataTable th:last-child,
  table.table-bordered.dataTable th:last-child,
  table.table-bordered.dataTable td:last-child,
  table.table-bordered.dataTable td:last-child {
    border-right-width: 0px !important;
  }

  .toast {
    background-color: $dark-theme;
    border-color: $dark-border;
    box-shadow: 0 2px 3px rgba(28, 39, 60, 0.06);
  }

  .toast-header {
    background-color: $dark-theme;
    border-bottom: 1px solid $dark-border;
    color: $dark-white;

    .btn-close {
      color: $dark-white;
      background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
      border: 0;
      border-radius: 0.25rem;
    }
  }

  .tooltip {
    text-shadow: none;

    .tooltip-arrow::before {
      border-color: transparent;
      border-style: solid;
    }
  }

  .tooltip-inner {
    color: $dark-white;
    background-color: #000000;
  }

  .tooltip-primary {
    .tooltip-inner {
      background-color: $primary;
      color: $dark-white;
    }

    &.bs-tooltip-top .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      border-top-color: $primary;
    }

    &.bs-tooltip-bottom .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      border-bottom-color: $primary;
    }

    &.bs-tooltip-start .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      border-left-color: $primary;
    }

    &.bs-tooltip-end .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      border-right-color: $primary;
    }
  }

  .tooltip-secondary {
    .tooltip-inner {
      background-color: $secondary;
      color: $dark-white;
    }

    &.bs-tooltip-top .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      border-top-color: $secondary;
    }

    &.bs-tooltip-bottom .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      border-bottom-color: $secondary;
    }

    &.bs-tooltip-start .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      border-left-color: $secondary;
    }

    &.bs-tooltip-end .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      border-right-color: $secondary;
    }
  }

  .tooltip-info {
    .tooltip-inner {
      background-color: $info;
      color: $dark-white;
    }

    &.bs-tooltip-top .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      border-top-color: $info;
    }

    &.bs-tooltip-bottom .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      border-bottom-color: $info;
    }

    &.bs-tooltip-start .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      border-left-color: $info;
    }

    &.bs-tooltip-end .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      border-right-color: $info;
    }
  }

  .tooltip-danger {
    .tooltip-inner {
      background-color: #f16d75;
      color: $dark-white;
    }

    &.bs-tooltip-top .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      border-top-color: #f16d75;
    }

    &.bs-tooltip-bottom .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      border-bottom-color: #f16d75;
    }

    &.bs-tooltip-start .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      border-left-color: #f16d75;
    }

    &.bs-tooltip-end .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      border-right-color: #f16d75;
    }
  }

  .tooltip-success {
    .tooltip-inner {
      background-color: $success;
      color: $dark-white;
    }

    &.bs-tooltip-top .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      border-top-color: $success;
    }

    &.bs-tooltip-bottom .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      border-bottom-color: $success;
    }

    &.bs-tooltip-start .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      border-left-color: $success;
    }

    &.bs-tooltip-end .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      border-right-color: $success;
    }
  }

  .tooltip-warning {
    .tooltip-inner {
      background-color: $warning;
      color: #8f9cc0;
    }

    &.bs-tooltip-top .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      border-top-color: $warning;
    }

    &.bs-tooltip-bottom .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      border-bottom-color: $warning;
    }

    &.bs-tooltip-start .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      border-left-color: $warning;
    }

    &.bs-tooltip-end .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      border-right-color: $warning;
    }
  }

  .bs-tooltip-top .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000000;
  }

  .bs-tooltip-end .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000000;
  }

  .bs-tooltip-bottom .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000000;
  }

  .bs-tooltip-start .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000000;
  }

  .tooltip-static-demo {
    background-color: $dark-body;
  }

  .main-content-dashboard-ten {
    background-color: #f0f2f8;

    .main-header {
      background-color: $dark-white;
    }

    .main-content-body-right {
      background-color: #f4f5fb;
    }

    .card {
      border-width: 0;
      box-shadow: 0 0 6px rgba(28, 39, 60, 0.12);
    }
  }

  .main-content-label,
  .card-table-two .card-title,
  .card-dashboard-eight .card-title {
    color: $dark-white;
  }

  .main-content-label-sm {
    color: $white-4 !important;
  }

  .main-content-text {
    color: $white-7;
  }

  .main-content-choose-demo {
    background-color: #0f0373;
    color: rgba(255, 255, 255, 0.5);

    .title {
      color: $dark-white;
    }

    .card {
      border-width: 0;

      figcaption {
        background-color: rgba(25, 4, 190, 0.75);
      }

      &.coming-soon {
        color: #8f9cc0;
        background-color: #f0f2f8;
      }
    }

    .main-content-label,
    .card-table-two .card-title {
      color: $white-7;
    }
  }

  .card-table-two .main-content-choose-demo .card-title,
  .main-content-choose-demo .card-dashboard-eight .card-title,
  .card-dashboard-eight .main-content-choose-demo .card-title {
    color: $white-7;
  }

  .main-content-choose-demo .main-content-title {
    color: $dark-white;
  }

  .main-header {
    background-color: $dark-theme;
    box-shadow: none;
    border-bottom: 1px solid rgba(240, 240, 255, 0.09) !important;
  }

  .main-header-menu-icon span {
    background-color: #2f395f;

    &::before,
    &::after {
      background-color: #2f395f;
    }
  }

  .main-header-menu {
    background-color: $dark-white;

    .nav-item {
      &::before {
        border-top: 2px solid rgba(28, 39, 60, 0.12);
        border-left: 2px solid rgba(28, 39, 60, 0.12);
        background-color: $dark-white;
      }

      > .nav-link {
        color: #8f9cc0;
      }
    }
  }

  .main-header-menu-header {
    border-bottom: 1px solid rgba(28, 39, 60, 0.05);
  }

  .main-header-center {
    .search-panel {
      .dropdown-toggle {
        background: #eaebfa;
        border: 1px solid #e6eaff;
        color: #828aa5;
      }

      .btn:hover .dropdown-toggle {
        color: #828aa5;
      }

      .dropdown-menu.show {
        box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
      }
    }

    .search-btn {
      color: $white;

      &:hover i,
      &:focus i {
        color: $dark-white;
      }
    }

    .form-control {
      &:focus {
        background: $dark-theme !important;
        border: 1px solid #e6eaff;
      }

      &:focus {
        background-color: #eaebfa;
        border-color: #e0e1fb;
        box-shadow: none !important;

        + .btn {
          color: $white-7;
        }
      }
    }

    .sp-container .form-control:focus + button {
      color: $white-7;
    }
  }

  .sp-container .main-header-center .form-control:focus + button {
    color: $white-7;
  }

  .main-header-center {
    .btn:hover,
    .sp-container button:hover {
      color: $dark-white;
    }
  }

  .sp-container .main-header-center button:hover {
    color: $dark-white;
  }

  .main-header-center {
    .btn:focus,
    .sp-container button:focus {
      color: $dark-white;
    }
  }

  .sp-container .main-header-center button {
    &:focus {
      color: $dark-white;
    }

    &:hover i {
      color: #b1bad6;
    }
  }

  .main-header-center {
    .btn:focus i,
    .sp-container button:focus i {
      color: #b1bad6;
    }
  }

  .sp-container .main-header-center button:focus i {
    color: #b1bad6;
  }

  .main-header-right {
    .btn-social {
      color: $white-7;

      &:hover,
      &:focus {
        color: #3c4858;
      }
    }

    .btn-buy {
      color: $dark-white;
    }
  }

  .main-header-search-link {
    color: #3c4858;
  }

  .main-header-arrow {
    color: #8f9cc0;

    &:hover,
    &:focus {
      color: #3c4858;
    }
  }

  .main-header-message > a,
  .main-header-notification > a {
    color: #8f9cc0;
  }

  .main-header-message > a.new::before {
    background-color: #f16d75;
  }

  .main-header-notification {
    > a.new::before {
      background-color: #f16d75;
    }

    .dropdown-menu {
      border: 1px solid $dark-border;
      box-shadow: 0 8px 16px 0 $black-1;
      background: $dark-theme;
    }
  }

  .main-header-message .dropdown-menu {
    border-color: $dark-border;
    border-width: 1px;
    box-shadow: 0 8px 16px 0 $black-1;
    background: $dark-theme;
  }

  .main-header-notification .dropdown-footer {
    border-top: 1px solid $dark-border;
  }

  .main-header-profile h6 {
    color: #8f9cc0;
  }

  .main-menu-sub .nav-link {
    color: #3c4858;

    + .nav-link {
      border-top: 1px dotted #f0f2f8;
    }
  }

  .main-menu-sub-mega {
    background-color: transparent;
    border: 0;

    .nav + .nav {
      border-top: 1px dotted $dark-border;
    }

    .mega-subtitle {
      color: #8f9cc0;
    }
  }

  .main-header-right .nav-link.icon {
    color: #4a4d51;

    &:hover {
      background: #f0f1ff;
    }
  }

  .main-header-notification.show .dropdown-menu::before,
  .main-header-message.show .dropdown-menu::before,
  .main-profile-menu.show .dropdown-menu::before {
    background: $dark-white;
    border-top: 1px solid $dark-border;
    border-left: 1px solid $dark-border;
  }

  .header-search {
    &.show .dropdown-menu::before {
      background: $dark-theme;
      border-top: 1px solid $dark-border;
      border-left: 1px solid $dark-border;
    }

    .dropdown-menu {
      border-color: $dark-border;
      border-width: 1px;
      box-shadow: 0 8px 16px 0 $black-1;
    }
  }

  .main-form-search {
    .form-control {
      border-color: $dark-border;
      border-width: 1px;
      background-color: $dark-theme;

      &:focus {
        background-color: $dark-theme;
        border-color: $dark-border;
        box-shadow: none !important;

        + .btn {
          color: $dark-white;
        }
      }
    }

    .sp-container .form-control:focus + button {
      color: $white-7;
    }
  }

  .sp-container .main-form-search .form-control:focus + button {
    color: $white-7;
  }

  .main-form-search {
    .btn,
    .sp-container button {
      color: #b4bdce;
    }
  }

  .sp-container .main-form-search button {
    background-color: transparent;
    color: #b4bdce;
  }

  .main-form-search {
    .btn:hover,
    .sp-container button:hover {
      color: #8f9cc0;
    }
  }

  .sp-container .main-form-search button:hover {
    color: #8f9cc0;
  }

  .main-form-search {
    .btn:focus,
    .sp-container button:focus {
      color: #8f9cc0;
    }
  }

  .sp-container .main-form-search button:focus {
    color: #8f9cc0;
  }

  .main-header-message .dropdown-footer {
    border-top: 1px solid $dark-border;
  }

  .main-header-flags {
    .dropdown-menu {
      border-color: #f0f2f8;
      border-width: 1px;
      box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
    }

    &.show .dropdown-menu::before {
      background: $dark-white;
      border-top: 1px solid #f0f2f8;
      border-left: 1px solid #f0f2f8;
    }

    .dropdown-footer {
      border-top: 1px solid rgba(123, 65, 216, 0.1);
    }
  }

  .main-footer {
    background-color: $dark-theme;
  }

  .main-footer-app {
    .container,
    .container-fluid {
      border-top-width: 0;
    }
  }

  .main-footer-demo {
    background-color: #0c025b;
    color: rgba(255, 255, 255, 0.4);

    .container {
      border-top-width: 0;
    }

    a {
      color: rgba(255, 255, 255, 0.5);

      &:hover,
      &:focus {
        color: $dark-white;
      }
    }
  }

  .sidebar {
    background-color: $dark-theme;
    box-shadow: 0 8px 16px 0 $black-1;
  }

  .sidebar-body h5 {
    border-bottom: 1px solid $dark-border;
    background: $dark-body;
  }

  .main-profile-menu {
    .dropdown-menu {
      border: 1px solid $dark-border;
      box-shadow: 0 8px 16px 0 $black-1;
      background: $dark-theme;
    }

    .dropdown-item {
      color: $white-7 !important;

      &:hover,
      &:focus {
        background-color: $dark-body;
      }

      + .dropdown-item {
        border-top: 1px solid $dark-border;
      }
    }

    .border-top {
      border-top: 1px solid $dark-border !important;
    }
  }

  .main-iconbar {
    background-color: $dark-white;
    border-right: 1px solid $border;
    border-top: 1px solid $border;

    .nav-link {
      color: #8f9cc0;

      &:hover,
      &:focus {
        color: #3c4858;
      }
    }
  }

  .main-iconbar-logo {
    color: #8f9cc0;

    &:hover,
    &:focus {
      color: #8f9cc0;
    }
  }

  .main-iconbar-help {
    color: $white-7;

    &:hover,
    &:focus {
      color: #8f9cc0;
    }
  }

  .main-iconbar-aside {
    background-color: $dark-white;
    border-right: 1px solid $border;
    border-top: 1px solid $border;
  }

  .main-iconbar-toggle-menu {
    color: #8f9cc0;

    &:hover,
    &:focus {
      color: #8f9cc0;
    }
  }

  .main-iconbar-body {
    .nav-item + .nav-item {
      border-top: 1px dotted #f0f2f8;
    }

    .nav-link {
      color: #8f9cc0;

      i,
      &.with-sub::after {
        color: $white-7;
      }
    }

    .nav-sub {
      border-top: 1px dotted #f0f2f8;

      .nav-sub-item + .nav-sub-item {
        border-top: 1px dotted #f0f2f8;
      }

      .nav-sub-link {
        color: #8f9cc0;
      }
    }
  }

  .main-iconbar-title {
    color: #8f9cc0;
  }

  .main-iconbar-text {
    color: $white-7;
  }

  .main-iconbar-primary {
    .main-img-user::after {
      box-shadow: none;
    }

    .main-iconbar-logo {
      color: $dark-white;
    }

    .nav-link {
      color: rgba(255, 255, 255, 0.5);

      &:hover,
      &:focus {
        color: $dark-white;
        background-color: rgba(255, 255, 255, 0.1);
      }

      &.active {
        color: $dark-white;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    .main-iconbar-help {
      color: rgba(255, 255, 255, 0.5);

      &:hover,
      &:focus {
        color: $dark-white;
      }
    }
  }

  .main-iconbar-aside-primary {
    background-color: #f1f2f9;
    box-shadow: 0 0 16px rgba(28, 39, 60, 0.08);
    border-right: 1px solid $border;

    .main-iconbar-header {
      background-color: $dark-white;
      border-bottom: 1px solid $border;
    }

    .main-iconbar-body {
      .nav {
        border-left-width: 0;
      }

      .nav-item + .nav-item,
      .nav-sub,
      .nav-sub-item + .nav-sub-item {
        border-top-color: #f0f2f8;
      }
    }
  }

  .main-icon-group {
    color: $dark;
  }

  .icons-list-item {
    background: $dark-theme;
    border: 1px solid $dark-border;
    color: $dark-white;
  }

  .ui-datepicker {
    background-color: $dark-theme;
    border: 1px solid $dark-border;
    box-shadow: 0 8px 16px 0 #151829;

    .ui-datepicker-header {
      color: $dark-white;
      border: 0;
      background-color: transparent;

      .ui-datepicker-next,
      .ui-datepicker-prev {
        color: $white-7;
        background: #000000;
      }

      .ui-datepicker-next::before,
      .ui-datepicker-prev::before {
        background: rgba(68, 86, 195, 0.1);
      }

      .ui-datepicker-next {
        &:hover::before,
        &:focus::before {
          color: $dark;
        }
      }

      .ui-datepicker-prev {
        &:hover::before,
        &:focus::before {
          color: $dark;
        }
      }

      .ui-datepicker-next-hover,
      .ui-datepicker-prev-hover {
        color: #3c4858;
        border: 0;
        background-color: transparent;
      }
    }

    .ui-datepicker-title {
      color: $dark-white;
    }

    .ui-datepicker-calendar {
      background-color: transparent;

      th {
        color: $dark-white;
        border: 1px solid $dark-border;
      }

      td {
        border: 1px solid $dark-theme;
        background-color: $dark-theme;

        &.ui-datepicker-other-month .ui-state-default {
          color: $white-7;
        }

        span {
          color: #313b54;
          border: 0;
        }

        a {
          color: $dark-white;
          border: 0;

          &:hover {
            background-color: $dark-body;
            color: #8f9cc0;
          }
        }
      }

      .ui-datepicker-today a {
        color: $dark-white;

        &:hover,
        &:focus {
          color: $dark-white;
        }
      }
    }
  }

  .ui-datepicker-inline {
    border-color: $dark-border;
  }

  .datepicker-panel > ul > li.highlighted {
    color: $dark-white !important;
    background: $primary;

    &:hover {
      color: $dark-white !important;
      background: $primary;
    }
  }

  .datetimepicker {
    box-shadow: none;
    border-width: 1px;
    border-color: $dark-border;

    table {
      th {
        &.prev,
        &.next,
        &.switch {
          background-color: $dark-theme;
          color: $dark-white;
        }

        &.prev span::before,
        &.next span::before {
          color: #8f9cc0;
        }

        &.prev {
          &:hover,
          &:focus {
            background-color: $dark-theme;
          }
        }

        &.next {
          &:hover,
          &:focus {
            background-color: $dark-theme;
          }
        }

        &.switch {
          color: #8f9cc0;
        }
      }

      td {
        background-color: $dark-theme;
        color: $dark-white;

        &.old,
        &.new {
          color: $white-7;
        }
      }
    }

    .datetimepicker-days table thead tr:last-child th {
      color: $dark-white;
      background-color: $dark-theme;
    }
  }

  .main-datetimepicker {
    box-shadow: none;
    border-color: #f0f2f8;

    > .datepicker_inner_container {
      box-shadow: none;
      border-width: 0;
      background-color: $dark-white;

      > {
        .datepicker_calendar {
          border-width: 0;

          th {
            color: #8f9cc0;
          }

          td {
            &.day_another_month {
              color: $white-7;
            }

            &.hover {
              background-color: #f0f2f8;
            }
          }
        }

        .datepicker_timelist {
          border-left: 1px solid #b4bdce;

          > div.timelist_item {
            &:hover,
            &:focus {
              background-color: #f0f2f8;
            }
          }
        }
      }
    }
  }

  .jqvmap-label {
    background-color: rgba(17, 17, 17, 0.9);
  }

  .chart-dropshadow2 {
    -webkit-filter: drop-shadow((-6px) 12px 4px rgba(0, 0, 0, 0.1));
    filter: drop-shadow((-6px) 12px 4px rgba(0, 0, 0, 0.1));
  }

  #chart svg path#apexcharts-radialbarTrack-0 {
    stroke: transparent;
  }

  .mapael {
    .zoomButton {
      background-color: #292929;
      border: 1px solid #292929;
      color: $dark-white;
    }

    .mapTooltip {
      background-color: #474c4b;
      color: $dark-white;
    }
  }

  input.parsley-error:focus,
  textarea.parsley-error:focus,
  input.parsley-success:focus,
  textarea.parsley-success:focus {
    box-shadow: none !important;
  }

  .parsley-checkbox {
    &.parsley-error {
      border: 1px solid #f16d75;
    }

    &.parsley-success {
      border: 1px solid $success;
    }
  }

  .parsley-errors-list {
    color: #f16d75;
  }
}

@media (max-width: 575.98px) {
  .dark-theme .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .dark-theme .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .dark-theme .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .dark-theme .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (min-width: 1200px) {
  .dark-theme .main-content-dashboard-ten .main-content-body-right {
    box-shadow: 0 0 6px rgba(28, 39, 60, 0.1);
  }
}

@media (max-width: 991.98px) {
  .dark-theme .main-content-left-show .main-content-left {
    background-color: $dark-white;
    border-right: 1px solid $border;
    box-shadow: 0 0 3px rgba(28, 39, 60, 0.1);
  }
}

/* ###### Main-content  ###### */

/* ###### Main-header  ###### */
@media (max-width: 991.98px) {
  .dark-theme .main-header-menu-show .main-header-menu {
    border-right: 1px solid rgba(28, 39, 60, 0.08);
    box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
  }
}

@media (min-width: 992px) {
  .dark-theme .main-header-menu {
    background-color: transparent;
    border-right: 0;
  }
}

/* ###### Main-header  ###### */

/* ###### Main-header-nav  ###### */

@media (min-width: 992px) {
  .dark-theme .main-menu-sub {
    background-color: $dark-white;
    border: 2px solid rgba(28, 39, 60, 0.12);
  }
}

@media (min-width: 992px) {
  .dark-theme .main-menu-sub-mega .container {
    background-color: $dark-white;
    border: 2px solid rgba(28, 39, 60, 0.12);
  }
}

@media (min-width: 992px) {
  .dark-theme .main-menu-sub-mega .container > div + div {
    border-left: 1px solid rgba(28, 39, 60, 0.05);
  }
}

@media (min-width: 992px) {
  .dark-theme .dark-theme .main-menu-sub-mega .nav + .nav {
    border-top: 0;
  }
}

@media (min-width: 1200px) {
  .dark-theme .main-iconbar-aside {
    border-right: 0;
  }
}

@media (min-width: 1200px) {
  .dark-theme .main-iconbar-toggle-menu::before {
    border-left: 2px solid #000000;
  }
}

.parsley-style-1 .parsley-input.parsley-error .form-control {
  border-color: #f16d75;
}

.dark-theme {
  .parsley-style-1 {
    .parsley-input.parsley-success .form-control {
      border-color: $success;
    }

    .parsley-checkbox.parsley-error {
      border: 1px solid #f16d75;
    }

    .parsley-errors-list.filled {
      color: #f16d75;
    }
  }

  .ql-wrapper-demo {
    background-color: $dark-theme;
  }

  .ql-scrolling-demo {
    background-color: $dark-body;
    border: 1px solid $dark-border;

    .ql-container {
      border-width: 0;

      .ql-editor {
        color: $dark-white;
      }
    }
  }

  .ql-wrapper-modal .ql-snow {
    &.ql-toolbar {
      border-width: 0;
      border-bottom-width: 1px;
    }

    &.ql-container {
      border-width: 0;
    }
  }

  .ql-bubble {
    &.ql-container:not(.ql-disabled) a::before {
      background-color: #8f9cc0;
    }

    .ql-tooltip {
      background-color: #8f9cc0;

      &.ql-flip .ql-tooltip-arrow {
        border-top-color: #000;
      }

      &:not(.ql-flip) .ql-tooltip-arrow {
        border-bottom-color: #000;
      }
    }

    .ql-toolbar {
      background: #000;

      button {
        color: #8896af;

        .ql-fill {
          fill: #8896af;
        }

        .ql-stroke {
          stroke: #8896af;
        }

        &.ql-active {
          color: $dark-white;
          background-color: rgba(255, 255, 255, 0.08);
        }
      }
    }
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border: 1px solid $dark-border !important;
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border: 1px solid $dark-border !important;
  }

  .ql-snow .ql-tooltip {
    background-color: $dark-body;
    border: 1px solid $dark-border;
    box-shadow: 0 8px 16px 0 $black-1;
    color: $dark-white;
  }

  .ql-snow {
    &.ql-container {
      border-color: $dark-border;
      border-width: 1px;
    }
    .ql-picker-options {
      background-color: $dark-theme;
    }

    &.ql-toolbar {
      border-width: 1px;
      border-color: $dark-border;
      border-bottom-width: 1px;

      .ql-picker-label {
        border-color: $dark-border !important;

        &.ql-active {
          background-color: $dark-theme;
          border-color: transparent;
          color: $dark-white;

          .ql-stroke {
            stroke: $dark-white !important;
          }
        }
      }

      button {
        border: 1px solid transparent;
        color: $dark-white !important;

        i {
          &.la-bold,
          &.la-underline {
            width: 21px;
          }
        }

        .ql-stroke {
          stroke-width: 2px;
        }

        &:hover,
        &:focus {
          border-color: $dark-border;
        }

        &:hover .ql-stroke,
        &:focus .ql-stroke {
          stroke: $dark-white;
        }

        &.ql-active {
          background-color: $dark-theme;
          color: $dark-white;
          border-color: transparent;

          .ql-fill {
            fill: $dark-white;
          }

          .ql-stroke {
            stroke: $dark-white !important;
          }
        }
      }
    }

    .ql-picker {
      border-width: 0;
      color: $dark-white !important;

      &:hover .ql-picker-label,
      &:focus .ql-picker-label {
        color: #8f9cc0;
      }

      &:hover .ql-picker-label .ql-stroke,
      &:focus .ql-picker-label .ql-stroke {
        stroke: $dark-white;
      }
    }

    .ql-picker-label {
      border: 1px solid $dark-border;
      background-color: $dark-body;
    }

    .ql-stroke {
      stroke: $dark-white !important;
    }

    .ql-editor {
      color: $dark-white;
    }
  }

  .irs-line-left,
  .irs-line-mid,
  .irs-line-right {
    background-color: $white-2;
  }

  .irs-slider {
    &:hover:before,
    &:focus:before {
      background-color: #260bfa;
    }
  }

  .irs-min,
  .irs-max {
    background-color: $dark-body;
  }

  .irs-success {
    .irs-bar,
    .irs-bar-edge,
    .irs-slider::before,
    .irs-from,
    .irs-to,
    .irs-single {
      background-color: $success;
    }

    .irs-from::after,
    .irs-to::after,
    .irs-single::after {
      border-top-color: $success;
    }
  }

  .irs-dark {
    .irs-bar,
    .irs-bar-edge,
    .irs-slider::before,
    .irs-from,
    .irs-to,
    .irs-single {
      background-color: $dark;
    }

    .irs-from::after,
    .irs-to::after,
    .irs-single::after {
      border-top-color: $dark;
    }
  }

  .irs-modern {
    .irs-line-left,
    .irs-line-mid,
    .irs-line-right {
      box-shadow: inset -2px 2px 2px 0 rgba(34, 34, 34, 0.1);
    }

    .irs-bar,
    .irs-bar-edge {
      box-shadow: inset 0 1px 1px 0 rgba(34, 34, 34, 0.1);
    }

    .irs-slider {
      background-color: $dark-white;

      &::before {
        background-color: $dark-white;
      }
    }

    &.irs-success .irs-slider {
      border-color: $success;

      &::before {
        border-color: $success;
      }
    }

    &.irs-dark .irs-slider {
      border-color: #8f9cc0;

      &::before {
        border-color: #8f9cc0;
      }
    }
  }

  .irs-outline {
    .irs-line-left,
    .irs-line-mid,
    .irs-line-right {
      background-color: transparent;
    }

    .irs-slider {
      background-color: $dark-white;
    }

    &.irs-success {
      .irs-line,
      .irs-slider {
        border-color: $success;
      }

      .irs-line {
        &:hover::before,
        &:focus::before {
          background-color: #339701;
        }
      }

      .irs-slider {
        &:hover::before,
        &:focus::before {
          background-color: #339701;
        }
      }
    }

    &.irs-dark {
      .irs-line,
      .irs-slider {
        border-color: $dark;
      }

      .irs-line {
        &:hover::before,
        &:focus::before {
          background-color: #313c53;
        }
      }

      .irs-slider {
        &:hover::before,
        &:focus::before {
          background-color: #313c53;
        }
      }
    }
  }

  .select2-container--default {
    .select2-selection--single {
      background-color: $dark-theme;
      border-color: $dark-border;

      .select2-selection__rendered {
        color: $white-7 !important;
      }

      .select2-selection__placeholder {
        color: $white-3;
      }
    }

    .select2-selection--multiple {
      background-color: $dark-theme;
      border-color: $dark-border;

      ::placeholder {
        color: $white-3 !important;
      }

      .select2-selection__choice {
        border-color: transparent;
        color: $dark-white;
        background-color: $dark-body;
      }

      .select2-selection__choice__remove {
        color: $dark-white;
      }
    }

    &.select2-container--focus .select2-selection--multiple {
      border: 1px solid $dark-border;
    }

    .select2-search--dropdown .select2-search__field {
      border-color: $dark-border;
      background-color: $dark-theme;
      color: $dark-white;
    }

    .select2-results__option[aria-selected="true"] {
      background-color: $dark-body !important;
    }

    &.select2-container--disabled .select2-selection__choice {
      background-color: $dark-body;
    }
  }

  .select2-container--open {
    .select2-selection--single,
    .select2-selection--multiple {
      background-color: $dark-theme;
      border-color: $dark-border;
    }
  }

  .select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: $dark-body !important;
  }

  .select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: $dark-body !important;
  }

  .select2-dropdown {
    border-color: $dark-border;
    background: $dark-theme;
    box-shadow: 0 8px 16px 0 $black-1;
  }

  .has-success .select2-container--default .select2-selection--single,
  .parsley-select.parsley-success .select2-container--default .select2-selection--single {
    border-color: $success;
  }

  .has-warning .select2-container--default .select2-selection--single {
    border-color: $warning;
  }

  .has-danger .select2-container--default .select2-selection--single,
  .parsley-select.parsley-error .select2-container--default .select2-selection--single {
    border-color: #f16d75;
  }

  .select2-bd-0 + .select2-container--default .select2-selection--single {
    border-width: 0;
  }

  .bg-gray + .select2-container--default .select2-selection--single {
    background-color: #455473;

    .select2-selection__rendered {
      color: #f0f2f8;
    }
  }

  .select2-dropdown {
    &::-webkit-scrollbar-thumb {
      background: #f0f2f8;
    }

    *:hover::-webkit-scrollbar-thumb {
      background: #adb5bd;
    }
  }

  .sp-container {
    background-color: $dark-theme;
    border-color: $dark-border;

    button {
      border: 0;
      background-color: $dark-body;
      text-shadow: none;

      &:hover,
      &:focus {
        border: 0;
        background-color: $dark-theme !important;
        text-shadow: none;
      }
    }
  }

  .sp-button-container .sp-cancel {
    color: #f16d75 !important;

    &:hover,
    &:focus {
      color: $dark-white !important;
    }
  }

  .sp-picker-container {
    border-left: 0;
  }
}

.dark-theme .sp-replacer {
  background-color: $dark-theme;
  border: 1px solid $dark-border;
}

.dark-theme {
  .sp-replacer {
    &:hover,
    &:focus {
      border-color: $dark-border;
    }

    &.sp-active {
      border-color: $dark-border;
    }
  }

  .sp-dd::before {
    color: $white-7;
  }

  .sp-preview {
    border: 0;
  }

  .sp-dragger {
    background-color: transparent;
  }

  .sp-choose {
    background-color: #f0f2f8;
  }

  .sp-palette .sp-thumb-el {
    &:hover,
    &.sp-thumb-active {
      border-color: $dark;
    }
  }

  .spinner-border {
    border: 0.25em solid currentColor;
    border-right-color: transparent;
  }

  .spinner-border-sm {
    border-width: 0.2em;
  }

  .spinner-grow {
    background-color: currentColor;
  }

  .sr-only {
    border: 0;
  }

  .lds-spinner {
    color: official;
  }

  .pulse {
    background: #f3ca56;
    box-shadow: 0 0 0 rgba(243, 202, 86, 0.9);
  }

  .spinner-border {
    border: 0.25em solid currentColor;
    border-right-color: transparent;
  }

  .spinner-border-sm {
    border-width: 0.2em;
  }

  .spinner-grow {
    background-color: currentColor;
  }

  .main-datepicker .ui-datepicker .ui-datepicker-calendar {
    th {
      color: #8f9cc0;

      &.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month) a {
        color: #f16d75;
      }
    }

    td {
      &.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month) a {
        color: #f16d75;
      }

      span,
      a {
        color: #3c4858;
      }
    }

    .ui-datepicker-today a {
      color: $dark-white;
    }
  }

  .main-nav-calendar-event .nav-link.exclude {
    color: $white-7;

    i {
      color: $white-7;
    }

    &::before {
      border-top: 1px solid $white-7;
    }
  }

  .main-calendar {
    border-color: #f0f2f8;

    .fc-content,
    .fc-divider,
    .fc-list-heading td,
    .fc-list-view,
    .fc-popover,
    .fc-row,
    tbody,
    td,
    th,
    thead {
      border-color: $dark-border;
    }

    .fc-header-toolbar {
      h2 {
        color: $dark-white;
      }

      button {
        background-color: $dark-body;
        border: 1px solid $dark-border;
        box-shadow: none;
        color: $dark-white;
        text-shadow: none;

        &:hover,
        &:focus,
        &.fc-state-active {
          background-color: $dark-theme;
        }

        &.fc-today-button {
          color: $dark-white;

          &.fc-state-disabled {
            border-color: $dark-border;
            background-color: $dark-body;
            color: $dark-white;
          }
        }
      }
    }

    .fc-head-container .fc-day-header {
      color: #8f9cc0;
    }

    .fc-view {
      > table {
        background-color: $dark-theme;
        border: 1px solid $dark-border;
        > {
          thead {
            th,
            td {
              border-color: $dark-border;
            }
          }

          tbody > tr > td {
            border-color: $dark-border;
          }
        }
      }

      .fc-other-month {
        background-color: $dark-body;
      }

      .fc-day-number {
        color: $dark-white;

        &:hover,
        &:focus {
          color: $dark-white;
          background-color: $dark-body;
        }
      }

      &.fc-agenda-view .fc-day-header > a > span:first-child {
        color: $white-7;
      }

      &.fc-listMonth-view,
      &.fc-listWeek-view {
        border-width: 0;
      }

      &.fc-listMonth-view .fc-list-table,
      &.fc-listWeek-view .fc-list-table {
        border-collapse: separate;
      }

      &.fc-listMonth-view .fc-list-heading td,
      &.fc-listWeek-view .fc-list-heading td {
        background-color: transparent;
        border-width: 0;
      }

      &.fc-listMonth-view .fc-list-heading-main,
      &.fc-listWeek-view .fc-list-heading-main {
        color: $white-7;
      }

      &.fc-listMonth-view .fc-list-heading-main span:last-child,
      &.fc-listWeek-view .fc-list-heading-main span:last-child {
        color: $dark-white;
      }
    }
  }

  .dark-theme .main-calendar .fc-view.fc-listMonth-view .fc-list-item {
    border-left: 4px solid transparent;
    background-color: $dark-white;
  }

  .main-calendar {
    .fc-view {
      &.fc-listWeek-view .fc-list-item {
        border-left: 4px solid transparent;
        background-color: $dark-body;
      }

      &.fc-listMonth-view .fc-list-item {
        &:hover td,
        &:focus td {
          background-color: transparent;
        }
      }

      &.fc-listWeek-view .fc-list-item {
        &:hover td,
        &:focus td {
          background-color: transparent;
        }
      }

      &.fc-listMonth-view .fc-list-item > td,
      &.fc-listWeek-view .fc-list-item > td {
        border-width: 0;
      }

      &.fc-listMonth-view .fc-list-item > td.fc-list-item-time,
      &.fc-listWeek-view .fc-list-item > td.fc-list-item-time {
        border-top: 1px solid $dark-border;
        border-right: 1px solid $dark-border;
      }

      &.fc-listMonth-view .fc-list-item > td.fc-list-item-title,
      &.fc-listWeek-view .fc-list-item > td.fc-list-item-title {
        border-bottom: 1px solid $dark-border;
        border-right: 1px solid $dark-border;
      }

      &.fc-listMonth-view .fc-list-item-title a,
      &.fc-listWeek-view .fc-list-item-title a {
        color: $dark-white;
      }
    }

    td.fc-today {
      background-color: $dark-body;
    }
  }

  .main-calendar td.fc-today .fc-day-number {
    color: $dark-white;

    &:hover,
    &:focus {
      background-color: $primary !important;
    }
  }

  .main-calendar {
    .fc-event {
      border-width: 0;
      border-top-width: 5px;
    }

    .fc-time-grid-event {
      border-left-width: 0;
      border-top-width: 2px;

      .fc-title {
        color: $white;
      }

      .fc-desc {
        color: $dark-white;
      }
    }

    .fc-list-empty {
      background-color: $dark-white;
      border: 1px solid $border;
    }
  }

  .main-modal-calendar-schedule {
    .modal-content {
      border-width: 0;

      > .btn-close {
        color: $white-7;
        text-shadow: none;
      }
    }

    .form-group-date {
      border: 1px solid $border;
      background-color: #f1f2f9;
      color: #8f9cc0;
    }
  }

  .main-modal-calendar-event {
    .modal-content {
      border-width: 0;
      background-color: transparent;

      > .btn-close {
        text-shadow: none;
      }
    }

    .modal-header {
      border-bottom-width: 0;
    }

    .nav-modal-event .nav-link {
      color: rgba(255, 255, 255, 0.75);

      &:hover,
      &:focus {
        color: $dark-white;
      }
    }

    .event-title {
      color: $dark-white;
    }

    .modal-body {
      background-color: $dark-theme;
    }

    .event-start-date,
    .event-end-date {
      color: #8f9cc0;
    }
  }

  .main-nav-line-chat {
    border-bottom: 1px solid $border;

    .nav-link {
      color: $dark;
    }
  }

  .main-chat-contacts-wrapper {
    border-bottom: 1px solid $border;
  }

  .main-chat-contacts-more {
    color: $dark-white;
  }

  .main-chat-list {
    .media {
      border: 1px solid transparent;

      + .media {
        border-top: 1px solid $dark-border;
      }

      &.new {
        background-color: $dark-theme;

        .media-contact-name span:first-child {
          color: $dark-white;
        }

        .media-body p {
          color: $dark-white;
        }
      }

      &:hover,
      &:focus {
        background-color: $dark-body;
        border-top-color: $dark-body;
        border-bottom-color: $dark-body;
      }

      &:hover:first-child,
      &:focus:first-child {
        border-top-color: transparent;
      }

      &.selected {
        background-color: $dark-body;
        border-top-color: $dark-body;
        border-bottom-color: $dark-body;

        &:first-child {
          border-top-color: transparent;
        }

        .media-contact-name span:first-child {
          color: $dark-white;
        }

        .media-body p {
          color: $dark-white;
        }
      }
    }

    .main-img-user span {
      color: $dark-white;
      background-color: #f16d75;
      box-shadow: 0 0 0 2px $dark-theme;
    }

    .media-body p {
      color: $white-7;
    }

    .media-contact-name span {
      &:first-child {
        color: $dark-white;
      }

      &:last-child {
        color: $white-7;
      }
    }
  }

  .main-chat-header {
    border-bottom: 1px solid $dark-border;

    .nav-link {
      color: $white-7;
    }
  }

  .main-chat-msg-name {
    h6 {
      color: $dark-white;
    }

    small {
      color: $white-7;
    }
  }

  .main-chat-body .media.flex-row-reverse .main-msg-wrapper {
    background-color: $primary;
    color: $dark-white;

    &:before {
      border-right-color: transparent;
      border-left-color: #e9e9f9;
    }

    &:after {
      border-right-color: transparent;
      border-left-color: #e9e9f9;
      border-width: 8px;
    }
  }

  .main-msg-wrapper {
    background-color: #f4f6ff;
  }

  .main-msg-wrapper-1 {
    background-color: $primary;
    color: white;
  }

  .main-msg-wrapper-2,
  .main-msg-wrapper-3 {
    background-color: $dark-body;
  }

  .main-chat-body .media-body > div:last-child {
    color: $white-7;
  }

  .main-chat-time span {
    background: $primary-03;
  }

  .main-chat-footer {
    border-top: 1px solid $dark-border;
    background-color: $dark-theme;

    .nav-link {
      color: $white-7;
    }

    .form-control {
      border: 1px solid $dark-border !important;
      background-color: $dark-body;

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }
  }

  .main-content-title {
    color: #170c6b;
  }

  .main-contact-label {
    color: $white-7;

    &::after {
      border-bottom: 1px solid $dark-border;
    }
  }

  .main-contact-item {
    border: 1px solid transparent;
    border-right-width: 0;

    + .main-contact-item {
      border-top-color: transparent;
      border-top: 1px solid $dark-border;
    }

    &:hover,
    &:focus {
      background-color: $dark-border;
      border-top-color: $dark-border;
      background-color: $dark-body;
    }

    &.selected {
      border-top-color: $dark-border;
      background-color: $dark-body;
    }
  }

  .main-contact-body {
    h6 {
      color: $dark-white;
    }

    span {
      color: $white-7;
    }
  }

  .main-contact-star {
    color: $white-7;

    &:hover,
    &:focus {
      color: $white-7;
    }

    &.active {
      color: $warning;
    }
  }

  .main-contact-info-header {
    border-bottom: 1px solid $dark-border;

    .main-img-user a {
      background-color: $dark-theme;
      color: $dark-white;
      box-shadow: 0 0 0 1px $dark-border;

      &:hover,
      &:focus {
        color: $dark-white;
        background: $primary;
      }
    }

    .media-body {
      h4 {
        color: $dark-white;
      }

      p {
        color: $white-7;
      }

      .nav-link {
        color: $white-7;
      }
    }
  }

  .main-contact-action a {
    color: $white-7;
  }

  .main-contact-info-body {
    .media-icon {
      color: $white-7;
      border: 1px solid $dark-border;
    }

    .media-body {
      label {
        color: $white-8;
      }

      span {
        color: $dark-white;
      }
    }
  }

  .main-card-signin {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .main-signin-footer {
    p {
      color: $white-7;
    }

    a {
      color: #8f9cc0;
    }
  }

  .main-signup-wrapper {
    background-color: rgba(244, 245, 248, 0.2);
  }

  .main-column-signup-left {
    h5 {
      color: #8f9cc0;
    }

    .btn,
    .sp-container button {
      border-width: 2px;
    }
  }

  .sp-container .main-column-signup-left button {
    border-width: 2px;
  }

  .main-column-signup {
    background-color: $dark-white;
  }

  .main-signup-header {
    h4 {
      color: #8f9cc0;
    }

    label {
      color: $white-7;
    }

    .form-control {
      color: #8f9cc0;
      border-width: 2px;
      border-color: #f0f2f8;

      &:focus {
        border-color: #b4bdce;
        box-shadow: none;
      }

      &::placeholder {
        color: $white-05;
      }
    }

    .row > div:first-child {
      .btn,
      .sp-container button {
        background-color: #4267b2;
        color: $dark-white;
      }
    }
  }

  .sp-container .main-signup-header .row > div:first-child button {
    background-color: #4267b2;
    color: $dark-white;
  }

  .main-signup-header .row > div:first-child {
    .btn:hover,
    .sp-container button:hover {
      background-color: #375694;
      color: $dark-white;
    }
  }

  .sp-container .main-signup-header .row > div:first-child button:hover {
    background-color: #375694;
    color: $dark-white;
  }

  .main-signup-header .row > div:first-child {
    .btn:focus,
    .sp-container button:focus {
      background-color: #375694;
      color: $dark-white;
    }
  }

  .sp-container .main-signup-header .row > div:first-child button:focus {
    background-color: #375694;
    color: $dark-white;
  }

  .main-signup-footer {
    p {
      color: $white-7;
    }

    a {
      color: #8f9cc0;
    }
  }

  .main-error-wrapper {
    h1 {
      color: #8f9cc0;
    }

    h2 {
      color: $dark;
    }

    .btn,
    .sp-container button {
      border-width: 2px;
    }
  }

  .sp-container .main-error-wrapper button {
    border-width: 2px;
  }

  .construction:before {
    background: $primary-08;
  }

  .signpages {
    .details:before {
      background: $primary-08;
    }

    .row > h2 {
      color: #008080;
    }

    .login_form {
      background-color: $dark-theme;
    }

    .form__input {
      border: 0px solid transparent;
      border-bottom: 1px solid #aaa;

      &:focus {
        border-bottom-color: #008080;
        box-shadow: 0 0 5px rgba(0, 80, 80, 0.4);
      }
    }
  }

  .main-invoice-list {
    border-top: 1px solid $border;

    .media {
      border: 1px solid transparent;
      border-right-width: 0;

      &:hover,
      &:focus {
        background-color: #f1f2f9;
      }

      + .media::before {
        border-top: 1px dotted #f0f2f8;
      }
    }

    .media-icon {
      color: $white-7;
    }

    .media-body {
      h6 {
        color: #8f9cc0;
      }

      p {
        color: $dark;

        span {
          color: $white-7;
        }
      }
    }

    .selected {
      background-color: #f1f2f9;
      border-top-color: #f0f2f8;
      border-bottom-color: #f0f2f8;

      &:first-child {
        border-top-color: transparent;
      }
    }
  }

  .card-invoice {
    background-color: transparent;
    border-width: 0;
  }

  .invoice-title {
    color: #f0f2f8;
  }

  .billed-from h6,
  .billed-to h6 {
    color: #8f9cc0;
  }

  .invoice-info-row {
    + .invoice-info-row {
      border-top: 1px dotted #f0f2f8;
    }

    span:first-child {
      color: #3c4858;
    }
  }

  .table-invoice tbody > tr > {
    th:first-child,
    td:first-child {
      color: $white-8;
    }

    th:first-child .invoice-notes p,
    td:first-child .invoice-notes p {
      color: $white-6;
    }
  }

  .main-mail-header .btn-group {
    .btn,
    .sp-container button {
      border-color: #e2e8f5;
      background-color: $dark-white;
    }
  }

  .sp-container .main-mail-header .btn-group button {
    border-color: #e2e8f5;
    background-color: $dark-white;
  }

  .main-mail-header .btn-group {
    .btn:hover,
    .sp-container button:hover {
      color: #1c273c;
      background-color: #f6f9fd;
    }
  }

  .sp-container .main-mail-header .btn-group button:hover {
    color: #1c273c;
    background-color: #f6f9fd;
  }

  .main-mail-header .btn-group {
    .btn:focus,
    .sp-container button:focus {
      color: #1c273c;
      background-color: #f6f9fd;
    }
  }

  .sp-container .main-mail-header .btn-group button:focus {
    color: #1c273c;
    background-color: #f6f9fd;
  }

  .main-mail-header .btn-group {
    .btn.disabled,
    .sp-container button.disabled {
      background-color: $dark-white;
      color: #e2e8f5;
      border-color: #e2e8f5;
    }
  }

  .sp-container .main-mail-header .btn-group button.disabled {
    background-color: $dark-white;
    color: #e2e8f5;
    border-color: #e2e8f5;
  }

  .main-mail-header .btn-group {
    .btn.disabled:focus,
    .sp-container button.disabled:focus {
      box-shadow: none;
    }
  }

  .sp-container .main-mail-header .btn-group button.disabled:focus {
    box-shadow: none;
  }

  .main-mail-header .btn-group {
    .btn.disabled:active,
    .sp-container button.disabled:active {
      box-shadow: none;
    }
  }

  .sp-container .main-mail-header .btn-group button.disabled:active {
    box-shadow: none;
  }

  .main-mail-options {
    border: 1px solid #e2e8f5;
    border-left-width: 0;
    border-right-width: 0;

    .btn,
    .sp-container button {
      background-color: transparent;
    }
  }

  .sp-container .main-mail-options button {
    background-color: transparent;
  }

  .main-mail-options {
    .btn:hover,
    .sp-container button:hover {
      background-color: #f6f9fd;
    }
  }

  .sp-container .main-mail-options button:hover {
    background-color: #f6f9fd;
  }

  .main-mail-options {
    .btn:focus,
    .sp-container button:focus {
      background-color: #f6f9fd;
    }
  }

  .sp-container .main-mail-options button:focus {
    background-color: #f6f9fd;
  }

  .main-mail-options {
    .btn.disabled,
    .sp-container button.disabled {
      background-color: transparent;
      color: #7987a1;
    }
  }

  .sp-container .main-mail-options button.disabled {
    background-color: transparent;
    color: #7987a1;
  }

  .main-mail-item {
    border-top: 1px solid #e4ebf7;
    border-bottom: 1px solid #e4ebf7;
    background-color: rgba(189, 205, 228, 0.1);

    &:first-child {
      border-top-width: 0;
    }

    &:hover,
    &:focus {
      background-color: #f6f9fd;
    }

    &.unread {
      background-color: $dark-white;
    }

    &.selected {
      background-color: white;
    }
  }

  .main-mail-star {
    color: #e2e8f5;

    &.active {
      color: #f0ad4e;
    }
  }

  .main-mail-subject {
    strong {
      color: #1c273c;
    }

    span {
      color: #5c6980;
    }
  }

  .main-mail-date {
    color: #97a3b9;
  }

  .main-mail-compose {
    background-color: rgba(28, 39, 60, 0.5);
  }

  .main-mail-compose-box {
    box-shadow: 0 0 30px rgba(28, 39, 60, 0.2);
  }

  .main-mail-compose-header {
    background-color: $dark-theme;
    color: $dark-white;

    .nav-link {
      color: rgba(255, 255, 255, 0.3);

      &:hover,
      &:focus {
        color: $dark-white;
      }
    }
  }

  .main-mail-compose-body {
    background-color: $dark-theme;

    .form-group {
      .form-label {
        color: $dark-white;
      }

      .form-control {
        border-width: 0;
        border: none !important;

        &:focus {
          box-shadow: none !important;
        }
      }

      + .form-group {
        border-top: 1px solid $dark-border;
      }

      .nav-link {
        color: $dark-white;

        &:hover,
        &:focus {
          color: $dark-white;
        }
      }
    }
  }

  .main-mail-compose-compress,
  .main-mail-compose-minimize {
    background-color: transparent;
  }

  .main-mail-two .main-header {
    border-bottom: 1px solid $border;
  }

  .main-mail-left {
    background-color: #f9f9f9;
    border-right: 1px solid #b4bdce;
  }

  .main-mail-content {
    background-color: #f4f5fb;
  }

  .main-content-body-show .main-header-mail-arrow {
    background: #f1f5ff;
  }

  .table-inbox tr td .fa-bookmark {
    color: $white-4;
  }

  .inbox-pagination a.np-btn {
    border: 1px solid $dark-border;
  }

  .mail-option {
    .chk-all,
    .btn-group a.btn {
      border: 1px solid $dark-border;
      color: $dark-white;
    }
  }

  .table-inbox {
    border: 1px solid $dark-border;

    tr td {
      border-top: 1px solid $dark-border;
      color: $dark-white;

      .fa-star {
        color:$white-4;

        &.inbox-started,
        &:hover {
          color: $white-4;
        }
      }
    }
  }

  .mail-option .dropdown-menu > li > a {
    color: $dark-white;

    &:hover {
      background: $dark-body;
      color: $primary;
    }
  }

  .profile-cover__img > {
    img {
      border: 0px solid $dark-border;
    }

    .h3 {
      color: $dark-white;
    }
    a {
      background-color: $dark-body;
      color: $dark-white;
    }
  }

  .profile-cover__info .nav li {
    color: $dark-white;

    &:not(:first-child) {
      border-left: 1px solid $dark-border;
    }
  }

  .main-content-left-profile {
    border-bottom: 1px solid $dark-border;
  }

  .main-profile-name {
    color: #8f9cc0;
  }

  .main-profile-name-text {
    color: $white-7;
  }

  .main-content-body-profile {
    .nav {
      border-bottom: 1px solid $dark-border;
    }

    .main-nav-line .nav-link {
      color: $dark-white;
    }
  }

  .main-profile-view-info {
    h6 {
      color: #8f9cc0;
    }

    span {
      color: #f10075;
    }
  }

  .main-traffic-detail-item > div:first-child > span {
    &:first-child {
      color: $white-7;
    }

    &:last-child {
      color: $white-7;

      span {
        color: $white-7;
      }
    }
  }

  .main-profile-work-list .media-body {
    h6 {
      color: #8f9cc0;
    }

    p {
      color: $white-7;
    }
  }

  .main-profile-contact-list .media-body {
    span {
      color: $white-7;
    }

    div {
      color: #8f9cc0;
    }
  }

  .crypto-transcation.list-unstyled li {
    border: 1px solid $background;
  }

  .toolbar .btn {
    background: $dark-white;
    color: #222;
    border: 1px solid #e7e7e7;
    border-bottom: 2px solid #ddd;
  }

  .crypto-card {
    background-color: $dark-theme;
    // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23262649' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  .btn-default.active {
    background: $primary;
    color: $dark-white;
    border-bottom: 2px solid #433c9a;
  }

  .card-bitcoin {
    label {
      color: $dark-white;
    }

    p {
      color: $dark-white;
    }
  }

  .crypto-wallet .chart-circle-value.circle-style {
    border: 2px dashed #9a93e8;
    background: #fcfcff;
  }

  .cryp-icon {
    color: $dark-white;
  }

  .buy-sell .form {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.2);
  }

  .bg-background2:before {
    background: $primary-08;
  }

  .exchange-icon {
    background: $success;
  }

  .crypto-btc-usd .nav-link.active {
    background: $primary;
    color: $dark-white;
  }

  .payment-list .payment-item {
    color: #c2c6d4;

    i {
      border: 1px solid #e6e9f3;
    }
  }

  .payment-item.active {
    i {
      border-color: $success;
      color: $success;
    }

    span {
      color: $success;
    }
  }

  .payment-cards {
    color: #707894;
    background-color: transparent;
    border: 1px solid $dark-border;
  }

  input:checked + label.payment-cards {
    border-color: $primary !important;
    border-width: 1px;
    color: $primary;
  }

  .form input:checked + label:after,
  form input:checked + label:after {
    border-style: solid;
    border-width: 0 35px 35px 0px;
    border-color: $primary $primary transparent;
  }

  .form input:checked + label {
    &:after {
      border-style: solid;
      border-width: 0 35px 35px 0px;
      border-color: $primary $primary transparent;
    }

    &:before {
      color: $dark-white;
    }
  }

  form input:checked + label:before,
  .form input:checked + label:before {
    color: $dark-white;
  }

  .crypto-buysell-card .nav.panel-tabs li {
    background: $dark-white;

    .active:before {
      border: solid transparent;
      border-width: 10px;
      border-top-color: $primary;
    }

    a.active {
      background: $primary;
      color: $dark-white;
    }
  }

  .wallet {
    .input-group-addon-right,
    .input-group-addon {
      color: #b7bec5;
      border: 1px solid $dark-border;
    }
  }

  .crypto-wallet .clipboard-icon {
    background-color: $primary !important;
    border-color: $primary !important;
    background: $primary;
    border: 1px solid $primary;
    color: $dark-white;

    &:hover,
    &:focus {
      background-color: $primary !important;
      border-color: $primary !important;
      background: $primary;
      border: 1px solid $primary;
      color: $dark-white;
    }
  }

  .wallet {
    .clipboard-icon {
      background: #2a2e4a;
    }

    .input-group-addon-left {
      background: $dark-body;
      border: 1px solid $dark-border;
      border-right: 0 !important;
    }
  }

  .transcation-crypto .table.dataTable {
    th,
    td {
      border-left: 0;
    }
  }

  .ratio {
    background-color: #222222;
    color: $dark-white;
  }

  .product-timeline ul.timeline-1:before {
    border-left: 2px dotted #c0ccda;
  }

  .countrywise-sale .list-group-item {
    border-left-width: 0;
    border-right-width: 0;
    border-style: dotted;
    border-color: #e2e8f5;
    background-color: transparent;

    span {
      color: #1c273c;
    }
  }

  .top-selling-products {
    .product-img {
      background-color: $dark-body;
      border: 1px solid $dark-border;
    }

    .price,
    .count {
      color: $white-5;
    }

    .title,
    .amount {
      color: $white-5;
    }

    .items-sold {
      color: $white-5;
    }
  }

  .ecommerce.wizard {
    background: $dark-white;

    .nav-tabs {
      border-bottom-color: #e0e0e0;
    }
  }

  .connecting-line {
    background: #e0e0e0;
  }

  #checkoutsteps {
    > {
      .actions a {
        color: $dark-white;
        background: $primary;
      }

      .steps {
        a {
          background-color: transparent;
          color: $dark-white;
        }

        .current a .number {
          border: 2px solid $primary;
          color: $primary;
        }
      }
    }

    .item .thumb {
      border: 1px solid $dark-border;
    }

    .total {
      color: $primary;
    }
  }

  .card-pay .tabs-menu {
    &.nav {
      background: $black-2;

      li a {
        border-right: 0;
      }
    }

    li a.active {
      background: $primary;
      color: $dark-white;
    }
  }

  .item1-links {
    li {
      border-top: 1px solid $dark-border;
    }

    a {
      color: #070510;
    }

    .nav-item a.active {
      background-color: $dark-body;
      color: $dark-white;
    }

    .nav-link {
      &:hover,
      &:focus {
        background-color: $dark-body;
        color: $dark-white;
      }
    }
  }

  .track {
    background-color: $dark-body;

    .step.active .text {
      color: $dark-white;
    }

    .icon {
      background: $dark-body;
    }

    .step.active {
      &:before {
        background: $primary;
      }

      .icon {
        background: $primary;
        color: $dark-white;
      }
    }
  }

  .product-grid {
    .product-discount-label {
      color: $dark-white;
      background-color: #16b36e;
    }

    .product-like-icon {
      color: #dc3545;
      background: $dark-white;
      border: 1px solid rgba(0, 0, 0, 0.1);

      &:hover {
        color: $dark-white;
        background: #dc3545;
      }
    }

    .product-link a {
      color: $white;
      background: $primary;
      border-right: 0px solid rgba(215, 215, 215, 0.15);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);

      &:nth-child(2n) {
        border-right: none;
      }

      &:hover {
        color: $dark-theme;
        background-color: $dark-theme;
      }

      span {
        color: $dark-white;
      }
    }
  }

  .product-content .rating {
    color: $warning;
  }

  .product-grid {
    .title a {
      color: $dark-white;
    }

    .price {
      color: $dark-white;

      .old-price {
        color: $white-5;
      }
    }
  }

  .product_price .old_price {
    color: $white-7;
  }

  .productdesc .list-unstyled li {
    border-bottom: 0 !important;
  }

  .bg-secondary {
    background-color: $secondary !important;
  }

  a.bg-secondary {
    &:hover,
    &:focus {
      background-color: #de287a !important;
    }
  }

  button.bg-secondary {
    &:hover,
    &:focus {
      background-color: #de287a !important;
    }
  }

  .bg-primary {
    background-color: $primary !important;
  }

  a.bg-primary {
    &:hover,
    &:focus {
      background-color: $primary-hover !important;
    }
  }

  button.bg-primary {
    &:hover,
    &:focus {
      background-color: $primary-hover !important;
    }
  }

  .bg-success {
    background-color: $success !important;
  }

  a.bg-success {
    &:hover,
    &:focus {
      background-color: #16a552 !important;
    }
  }

  button.bg-success {
    &:hover,
    &:focus {
      background-color: #16a552 !important;
    }
  }

  .dark-bg-info {
    background-color: $info !important;
  }

  a.bg-info {
    &:hover,
    &:focus {
      background-color: #06aff1 !important;
    }
  }

  button.bg-info {
    &:hover,
    &:focus {
      background-color: #06aff1 !important;
    }
  }

  .bg-warning {
    background-color: $warning !important;
  }

  a.bg-warning {
    &:hover,
    &:focus {
      background-color: #f1911d !important;
    }
  }

  button.bg-warning {
    &:hover,
    &:focus {
      background-color: #f1911d !important;
    }
  }

  .bg-danger {
    background-color: #f16d75 !important;
  }

  a.bg-danger {
    &:hover,
    &:focus {
      background-color: #ec4157 !important;
    }
  }

  button.bg-danger {
    &:hover,
    &:focus {
      background-color: #ec4157 !important;
    }
  }

  .bg-light {
    background-color: $white-1 !important;
    color: $dark-white;
  }

  a.bg-light {
    &:hover,
    &:focus {
      background-color: #dbe0ea !important;
    }
  }

  button.bg-light {
    &:hover,
    &:focus {
      background-color: #dbe0ea !important;
    }
  }

  .bg-dark {
    background-color: $dark !important;
  }

  a.bg-dark {
    &:hover,
    &:focus {
      background-color: #131212 !important;
    }
  }

  button.bg-dark {
    &:hover,
    &:focus {
      background-color: #131212 !important;
    }
  }

  .bg-white {
    background-color: $dark-white !important;
  }

  .bg-transparent {
    background-color: transparent !important;
  }

  .bg-primary-gradient {
    background: linear-gradient(to right, $primary 0%, $primary-05 100%);
  }
  .bg-secondary-gradient {
    background: linear-gradient(to right, $secondary 0%, #fb6dad 100%);
  }
  .bg-danger-gradient {
    background-image: linear-gradient(to right, $danger 0%, #f594a0 100%) !important;
  }
  .bg-dark-gradient {
    background-image: linear-gradient(to right, $dark 0%, #606f8e 100%) !important;
  }
  .bg-success-gradient {
    background: linear-gradient(to right, $success 0%, #6cefa3 100%);
  }
  .bg-warning-gradient {
    background: linear-gradient(to right, $warning 0%, #f9c07b 100%);
  }

  .bg-info-gradient {
    background: linear-gradient(to right, $info 0%, #7edbff 100%);
  }

  .bg-white-1 {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .bg-white-2 {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .bg-white-3 {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .bg-white-4 {
    background-color: rgba(255, 255, 255, 0.4);
  }

  .bg-white-5 {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .bg-white-6 {
    background-color: rgba(255, 255, 255, 0.6);
  }

  .bg-white-7 {
    background-color: rgba(255, 255, 255, 0.7);
  }

  .bg-white-8 {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .bg-white-9 {
    background-color: rgba(255, 255, 255, 0.9);
  }

  .bg-black-1 {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .bg-black-2 {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .bg-black-3 {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .bg-black-4 {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .bg-black-5 {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .bg-black-6 {
    background-color: rgba(0, 0, 0, 0.6);
  }

  .bg-black-7 {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .bg-black-8 {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .bg-black-9 {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .bg-indigo {
    background-color: #4b0082;
  }

  .bg-azure {
    background-color: #007ea7;
  }

  .bg-purple {
    background-color: #6f42c1;
  }

  .bg-pink {
    background-color: $secondary !important;
  }

  .bg-orange {
    background-color: #fd7e14;
  }

  .bg-teal {
    background-color: #00cccc;
  }

  .bg-purple-dark {
    background-color: #59339d;
  }

  .bg-success-transparent {
    background-color: rgba(111, 234, 163, 0.2);
  }

  .bg-secondary-transparent {
    background-color: rgba(240, 112, 187, 0.15);
  }

  .bg-primary-transparent {
    background-color: $primary-01;
  }

  .bg-info-transparent {
    background-color: rgba(1, 184, 255, 0.12);
  }

  .bg-warning-transparent {
    background-color: rgba(243, 202, 86, 0.15);
  }

  .bg-danger-transparent {
    background-color: rgba(255, 71, 61, 0.12);
  }

  .bg-pink-transparent {
    background-color: rgba(255, 101, 165, 0.12);
  }

  .bg-purple-transparent {
    background-color: rgba(111, 66, 193, 0.12);
  }

  .bg-dark-transparent {
    background-color: rgba(28, 39, 60, 0.12) !important;
  }

  .bg-white-transparent {
    background-color: rgba(255, 255, 255, 0.12) !important;
  }

  .border {
    border: 1px solid $dark-border !important;
  }

  .border-top {
    border-top: 1px solid $dark-border !important;
  }

  .border-end {
    border-right: 1px solid $dark-border !important;
  }

  .border-bottom {
    border-bottom: 1px solid $dark-border !important;
  }

  .border-start {
    border-left: 1px solid $dark-border !important;
  }

  .border-0 {
    border: 0 !important;
  }

  .border-top-0 {
    border-top: 0 !important;
  }

  .border-end-0 {
    border-right: 0 !important;
  }

  .border-bottom-0 {
    border-bottom: 0 !important;
  }

  .border-start-0 {
    border-left: 0 !important;
  }

  .border-secondary {
    border-color: $secondary !important;
  }

  .border-success {
    border-color: $success !important;
  }

  .border-info {
    border-color: $info !important;
  }

  .border-warning {
    border-color: $warning !important;
  }

  .border-danger {
    border-color: #f16d75 !important;
  }

  .border-light {
    border-color: #f1f2f9 !important;
  }

  .border-dark {
    border-color: $dark !important;
  }

  .border-white {
    border-color: $dark-white !important;
  }

  .bd-transparent {
    border-color: transparent;
  }

  .bd {
    border: 1px solid $dark-border;
  }

  .bd-t {
    border-top: 1px solid $dark-border;
  }

  .bd-r {
    border-right: 1px solid $dark-border;
  }

  .bd-b {
    border-bottom: 1px solid $dark-border;
  }

  .bd-l {
    border-left: 1px solid $dark-border;
  }

  .bd-y {
    border-top: 1px solid $dark-border;
    border-bottom: 1px solid $dark-border;
  }

  .bd-x {
    border-left: 1px solid $dark-border;
    border-right: 1px solid $dark-border;
  }

  .bd-0 {
    border-width: 0;
  }

  .bd-1 {
    border-width: 1px;
  }

  .bd-2 {
    border-width: 2px;
  }

  .bd-3 {
    border-width: 3px;
  }

  .bd-4 {
    border-width: 4px;
  }

  .bd-5 {
    border-width: 5px;
  }

  .bd-t-0 {
    border-top: 0 !important;
  }

  .bd-r-0 {
    border-right: 0 !important;
  }

  .bd-b-0 {
    border-bottom: 0 !important;
  }

  .bd-l-0 {
    border-left: 0 !important;
  }

  .bd-t-0-f {
    border-top: 0 !important;
  }

  .bd-r-0-f {
    border-right: 0 !important;
  }

  .bd-b-0-f {
    border-bottom: 0 !important;
  }

  .bd-l-0-f {
    border-left: 0 !important;
  }

  .bd-y-0 {
    border-top-width: 0;
    border-bottom-width: 0;
  }

  .bd-y-1 {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  .bd-x-0 {
    border-left-width: 0;
    border-right-width: 0;
  }

  .bd-x-1 {
    border-left-width: 1px;
    border-right-width: 1px;
  }

  .bd-primary {
    border-color: $primary;
  }

  .bd-secondary {
    border-color: $secondary;
  }

  .bd-success {
    border-color: $success;
  }

  .bd-warning {
    border-color: $warning;
  }

  .bd-danger {
    border-color: #f16d75;
  }

  .bd-info {
    border-color: $info;
  }

  .bd-white {
    border-color: $dark-white;
  }

  .bd-gray-100 {
    border-color: #f1f2f9;
  }

  .bd-gray-200,
  .bd-gray-300 {
    border-color: #f0f2f8;
  }

  .bd-gray-400 {
    border-color: #b4bdce;
  }

  .bd-gray-500,
  .bd-gray-600 {
    border-color: $white-7;
  }

  .bd-gray-700 {
    border-color: #3c4858;
  }

  .bd-gray-800 {
    border-color: $dark;
  }

  .bd-gray-900 {
    border-color: #8f9cc0;
  }

  .bd-white-1 {
    border-color: rgba(255, 255, 255, 0.1);
  }

  .bd-white-2 {
    border-color: rgba(255, 255, 255, 0.2);
  }

  .bd-white-3 {
    border-color: rgba(255, 255, 255, 0.3);
  }

  .bd-white-4 {
    border-color: rgba(255, 255, 255, 0.4);
  }

  .bd-white-5 {
    border-color: rgba(255, 255, 255, 0.5);
  }

  .bd-white-6 {
    border-color: rgba(255, 255, 255, 0.6);
  }

  .bd-white-7 {
    border-color: rgba(255, 255, 255, 0.7);
  }

  .bd-white-8 {
    border-color: rgba(255, 255, 255, 0.8);
  }

  .bd-dashed {
    border-style: dashed;
  }

  .bd-dotted {
    border-style: dotted;
  }

  .shadow-base {
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
  }

  .shadow-1 {
    box-shadow: 0 0 7px 7px rgba(28, 39, 60, 0.1);
  }

  .shadow-2 {
    box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
  }

  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(162, 169, 204, 0.075) !important;
  }

  .shadow {
    box-shadow: -8px 12px 18px 0 rgba(21, 21, 62, 0.3) !important;
  }

  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(162, 169, 204, 0.175) !important;
  }

  .shadow-none {
    box-shadow: none !important;
  }

  .tx-white {
    color: $dark-white;
  }

  .tx-success {
    color: $success;
  }

  .tx-warning {
    color: $warning;
  }

  .tx-danger {
    color: #f16d75;
  }

  .tx-info {
    color: $info;
  }

  .tx-inverse {
    color: $dark-white;
  }

  .tx-teal {
    color: #00cccc;
  }

  .tx-dark {
    color: $dark-white;
  }

  .tx-indigo {
    color: #3f00ff;
  }

  .tx-purple {
    color: #6f42c1;
  }

  .tx-orange {
    color: #fd7e14;
  }

  .tx-pink {
    color: #f10075;
  }

  .tx-gray-100 {
    color: $background;
  }

  .tx-gray-200,
  .tx-gray-300 {
    color: #f0f2f8;
  }

  .tx-gray-400 {
    color: #b4bdce;
  }

  .tx-gray-500,
  .tx-gray-600 {
    color: $white-7;
  }

  .tx-gray-700 {
    color: #3c4858;
  }

  .tx-gray-800 {
    color: $dark;
  }

  .tx-gray-900 {
    color: #8f9cc0;
  }

  .tx-white-2 {
    color: rgba(255, 255, 255, 0.2);
  }

  .tx-white-3 {
    color: rgba(255, 255, 255, 0.3);
  }

  .tx-white-4 {
    color: rgba(255, 255, 255, 0.4);
  }

  .tx-white-5 {
    color: rgba(255, 255, 255, 0.5);
  }

  .tx-white-6 {
    color: rgba(255, 255, 255, 0.6);
  }

  .tx-white-7 {
    color: rgba(255, 255, 255, 0.7);
  }

  .tx-white-8 {
    color: rgba(255, 255, 255, 0.8);
  }

  .text-white {
    color: $white !important;
  }

  .text-secondary {
    color: $secondary !important;
  }

  a.text-secondary {
    &:hover,
    &:focus {
      color: $secondary !important;
    }
  }

  .text-pink {
    color: $secondary !important;
  }

  .text-success {
    color: $success !important;
  }

  a.text-success {
    &:hover,
    &:focus {
      color: $success !important;
    }
  }

  .text-info {
    color: $info !important;
  }

  a.text-info {
    &:hover,
    &:focus {
      color: $info !important;
    }
  }

  .text-warning {
    color: $warning !important;
  }

  a.text-warning {
    &:hover,
    &:focus {
      color: $warning !important;
    }
  }

  .text-danger {
    color: #f16d75 !important;
  }

  a.text-danger {
    &:hover,
    &:focus {
      color: #f16d75 !important;
    }
  }

  .text-light {
    color: $background !important;
  }

  a.text-light {
    &:hover,
    &:focus {
      color: #c5cbda !important;
    }
  }

  .text-dark {
    color: #465988 !important;
  }

  a.text-dark {
    &:hover,
    &:focus {
      color: $dark-white !important;
    }
  }

  .text-body {
    color: #465988 !important;
  }

  .text-muted {
    color: $white-5 !important;
  }

  .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
  }

  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .text-hide {
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }

  .text-reset {
    color: inherit !important;
  }

  .tag {
    color: $white;
    background-color: #e9edfb;
  }

  a.tag:hover {
    background-color: rgba(110, 118, 135, 0.2);
    color: inherit;
  }

  .tag-addon {
    color: inherit;
    background: rgba(255, 255, 255, 0.04);
  }

  a.tag-addon:hover {
    background: rgba(0, 0, 0, 0.16);
    color: inherit;
  }

  .tag-blue {
    background-color: #467fcf;
    color: $white;
  }

  .tag-indigo {
    background-color: $primary;
    color: $white;
  }

  .tag-purple {
    background-color: #8500ff;
    color: $white;
  }

  .tag-pink {
    background-color: #ec82ef;
    color: $white;
  }

  .tag-red {
    background-color: #ec2d38;
    color: $white;
  }

  .tag-yellow {
    background-color: #fdb901;
    color: $white;
  }

  .tag-green {
    background-color: #0fa751;
    color: $white;
  }

  .tag-cyan {
    background-color: #00b9ff;
    color: $white;
  }

  .tag-orange {
    background-color: #fc7303;
    color: $white;
  }

  .tag-teal {
    background-color: #2bcbba;
    color: $white;
  }

  .tag-white {
    background-color: $dark-white;
    color: $white;
  }

  .tag-gray {
    background-color: #868e96;
    color: $white;
  }

  .tag-gray-dark {
    background-color: #343a40;
    color: $white;
  }

  .tag-azure {
    background-color: #17c1f4;
    color: $white;
  }

  .tag-lime {
    background-color: #7bd235;
    color: $white;
  }

  .tag-primary {
    background-color: $primary;
    color: $white;
  }

  .tag-secondary {
    background-color: $secondary;
    color: $white;
  }

  .tag-success {
    background-color: $success;
    color: $white;
  }

  .tag-info {
    background-color: $info;
    color: $white;
  }

  .tag-default {
    background-color: $dark-body;
    color: $white;
  }

  .tag-warning {
    background-color: $warning;
    color: $white;
  }

  .tag-danger {
    background-color: #f16d75;
    color: $white;
  }

  .tag-light {
    background-color: #f8f9fa;
    color: $white;
  }

  .tag-dark {
    background-color: #8f9cc0;
    color: $white;
  }

  .tag-primary-transparent {
    background-color: $primary-02;
    color: $primary;
  }

  .tag-secondary-transparent {
    background-color: rgba(241, 56, 139, 0.2);
    color: $secondary;
  }

  .tag-success-transparent {
    background-color: rgba(25, 177, 89, 0.2);
    color: $success;
  }
  .tag-danger-transparent {
    background-color: rgba(253, 96, 116, 0.2);
    color: $danger;
  }
  .tag-info-transparent {
    background-color: rgba(1, 184, 255, 0.2);
    color: $info;
  }
  .tag-warning-transparent {
    background-color: rgba(255, 155, 33, 0.2);
    color: $warning;
  }
  .tag-dark-transparent {
    background-color: rgba(143, 156, 192, 0.2);
    color: #8f9cc0;
  }

  .nav-tabs {
    border-bottom: 1px solid $border;
    border-bottom-width: 0;

    .nav-link {
      border: 1px solid transparent;
      background-color: $dark-theme;
      border-width: 0;
      color: $dark-white;

      &:hover,
      &:focus {
        border-color: $dark-border;
      }

      &.disabled {
        color: $white-7;
        background-color: transparent;
        border-color: transparent;
      }

      &.active {
        color: #3c4858;
        background-color: $primary;
        color: $dark-white;
      }
    }

    .nav-item.show .nav-link {
      color: #3c4858;
      background-color: $dark-white;
      border-color: #f0f2f8 #f0f2f8 $dark-white;
    }
  }

  .main-nav-tabs {
    background-color: $dark-body;

    .lSAction > a {
      background-color: $dark-theme;
      color: $dark-white;

      &:hover,
      &:focus {
        background-color: $dark-theme;
        color: $dark-white;
        border: 1px solid;
        border-color: $dark-border;
      }

      &.disabled {
        background-color: $dark-theme;
        color: $dark-white;
      }
    }

    .tab-link {
      color: $dark-white;
      background-color: $dark-body;

      &:hover,
      &:focus {
        background-color: $primary;
      }

      &.active {
        background-color: $primary;
        color: $dark-white;
      }
    }
  }

  .main-navbar-backdrop {
    background-color: rgba(0, 0, 0, 0.45);
  }

  .main-navbar {
    background-color: $dark-theme;

    .nav-sub-link:before {
      border: 0;
      background: transparent;
    }

    .nav-label {
      color: $white-4;
    }

    .nav-item:hover .nav-link,
    &.hor-menu .nav-item.active .nav-link.with-sub::after {
      color: $white;
    }

    .nav-item.active:hover .nav-link.with-sub::after {
      color: $white !important;
    }
    .nav-item:hover .nav-link.with-sub::after {
      color: $primary !important;
    }

    &.hor-menu {
      box-shadow: -8px 12px 18px 0 rgba(36, 36, 51, 0.1);

      .nav-item {
        &.active .nav-link {
          color: $white !important;
        }

        &:hover .nav-link {
          color: $primary;
        }
      }
    }

    .nav-link {
      color: rgba(255, 255, 255, 0.6);

      &.with-sub {
        &::after {
          color: $dark-white;
        }

        &::before {
          border: 1px solid transparent;
          border-top-color: $dark-border;
          border-left-color: $dark-border;
          background-color: $dark-theme;
        }
      }
    }

    .nav-sub-link {
      color: $dark-white;

      &.with-sub::after {
        color: $white-7;
      }
    }

    .nav-sub-mega {
      background-color: transparent;
      border: 0;
      border-top: 0;
    }

    .nav-item .nav-sub li:not(.nav-sub-item),
    .nav-sub-mega .nav li:not(.nav-sub-item) {
      color: #525277;
    }
  }

  .main-navbar-header {
    border-bottom: 1px solid $border;
  }

  .main-navbar-search {
    border-bottom: 1px solid $border;

    .form-control {
      border-width: 0;

      &:focus {
        box-shadow: none !important;
        border-color: #f0f0ff;
      }
    }

    .btn,
    .sp-container button {
      background-color: transparent;
    }
  }

  .sp-container .main-navbar-search button {
    background-color: transparent;
  }

  .main-navbar-two {
    .nav-sub {
      border-width: 1px;
      border-top-width: 0;
    }

    .nav-sub-item > .nav-sub {
      border-top-width: 1px;
    }
  }

  .main-navbar-three {
    .nav-item + .nav-item {
      border-left-width: 0;
    }

    .nav-link {
      color: $dark;

      &:hover,
      &:focus {
        color: #8f9cc0;
      }
    }

    .nav-sub {
      border-width: 0;
    }

    .nav-sub-item + .nav-sub-item {
      border-top: 0;
    }
  }

  .navbar-toggler {
    background-color: transparent;
    border: 1px solid transparent;

    .header-icons:hover {
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 10px;
    }
  }

  .option-dots:hover {
    background-color: $dark-body;
    border-radius: 50%;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .wizard {
    border: 1px solid $dark-border;
    background-color: $dark-theme;
    border-radius: 11px;

    > {
      .steps {
        a {
          color: #8f9cc0;

          &:hover,
          &:active {
            color: #8f9cc0;
          }

          .number,
          &:hover .number,
          &:active .number {
            color: $dark-white;
            background-color: $white-1;
          }
        }

        .disabled a {
          color: $white-7;

          &:hover,
          &:active {
            color: $white-7;
          }
        }

        .current a {
          .number,
          &:hover .number,
          &:active .number {
            color: $dark-white;
          }
        }

        .done a {
          color: $white-7;

          &:hover,
          &:active {
            color: $white-7;
          }

          .number,
          &:hover .number,
          &:active .number {
            background-color: $dark-body;
            border: 1px solid $dark-border;
          }
        }
      }

      .content {
        border-top: 1px solid $dark-border;
        border-bottom: 1px solid $dark-border;

        > {
          .title {
            color: #8f9cc0;
          }

          .body input.parsley-error {
            border-color: #f16d75;
          }
        }
      }

      .actions {
        > ul > li:last-child a {
          background-color: #f16d75;
        }

        a {
          color: $white;
          background: $primary;

          &:hover,
          &:active {
            color: $white;
          }
        }

        .disabled a {
          background-color: $secondary;

          &:hover,
          &:active {
            background-color: $secondary;
          }
        }
      }
    }
  }

  .wizard-style-1 > .steps > ul {
    a {
      .number,
      &:hover .number,
      &:active .number {
        border: 0;
        color: $white-7;
        background-color: #f0f2f8;
      }
    }

    .done a {
      .number,
      &:hover .number,
      &:active .number {
        background-color: #643ab0;
        color: $dark-white;
      }
    }
  }

  .wizard-style-2 > .steps > ul {
    a {
      .number,
      &:hover .number,
      &:active .number {
        border: 2px solid #f0f2f8;
        color: $white-7;
        background-color: $dark-white;
      }
    }

    .done a {
      .number,
      &:hover .number,
      &:active .number {
        border-color: #6f42c1;
        color: #6f42c1;
      }
    }
  }

  .ps > .ps__rail-y {
    background-color: rgba(28, 39, 60, 0.04);

    > .ps__thumb-y {
      background-color: tranparent;
    }
  }

  .rdiobox {
    span {
      &:before {
        background-color: $dark-body;
        border: 1px solid $dark-border;
      }

      &:after {
        background-color: $dark-white;
      }
    }

    input[type="radio"]:checked + span:before {
      border-color: transparent;
    }
  }

  .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: $primary;
    opacity: 0.5;
  }

  .form-select {
    color: $dark-white;
    background-color: $dark-theme !important;
    border: 1px solid $dark-border;

    &:focus {
      border-color: none !important;
      box-shadow: none;

      &::-ms-value {
        color: #3c4858;
        background-color: $dark-white;
      }
    }

    &:disabled {
      color: $white-7;
      background-color: #f0f2f8;
    }
  }

  .custom-file-input {
    &:focus ~ .custom-file-label {
      box-shadow: none;
    }

    &:disabled ~ .custom-file-label {
      background-color: #f0f2f8;
    }
  }

  .custom-file-label {
    color: #3c4858;
    background-color: $dark-white;
    border: 1px solid $border;

    &::after {
      color: #3c4858;
      background-color: #e5e9f3;
      border-left: inherit;
    }
  }

  .custom-range {
    background-color: transparent;

    &:focus {
      &::-webkit-slider-thumb,
      &::-moz-range-thumb,
      &::-ms-thumb {
        box-shadow: 0 0 0 1px $dark-white, 0 0 0 0.2rem rgba(135, 96, 251, 0.25);
      }
    }

    &::-moz-focus-outer {
      border: 0;
    }

    &::-webkit-slider-thumb {
      border: 0;

      &:active {
        background-color: #e6ecff;
      }
    }

    &::-webkit-slider-runnable-track {
      color: transparent;
      background-color: #f0f2f8;
      border-color: transparent;
    }

    &::-moz-range-thumb {
      border: 0;

      &:active {
        background-color: #e6ecff;
      }
    }

    &::-moz-range-track {
      color: transparent;
      background-color: #f0f2f8;
      border-color: transparent;
    }

    &::-ms-thumb {
      border: 0;

      &:active {
        background-color: #e6ecff;
      }
    }

    &::-ms-track {
      color: transparent;
      background-color: transparent;
      border-color: transparent;
      border-width: 0.5rem;
    }

    &::-ms-fill-lower,
    &::-ms-fill-upper {
      background-color: #f0f2f8;
    }

    &:disabled {
      &::-webkit-slider-thumb,
      &::-moz-range-thumb,
      &::-ms-thumb {
        background-color: $white-7;
      }
    }
  }

  .custom-switch-indicator {
    background: $dark-body;
    border: 1px solid $dark-border;

    &:before {
      background: $black-2;
      box-shadow: none;
    }
  }

  .custom-switch-input:focus ~ .custom-switch-indicator {
    box-shadow: none;
  }

  .custom-switch-description {
    color: $white-7;
  }

  .custom-switch-input:checked ~ .custom-switch-description {
    color: $white-7;
  }

  .custom-switch {
    .custom-control-label::after {
      background-color: $white-7;
    }

    .custom-control-input {
      &:checked ~ .custom-control-label::after {
        background-color: $dark-white;
      }

      &:disabled:checked ~ .custom-control-label::before {
        background-color: rgba(135, 96, 251, 0.5);
      }
    }
  }

  .main-rating-value {
    color: #8f9cc0;
  }

  .rating-stars .rating-stars-container .rating-star {
    color: #f0f2f8;

    &.is--active .fa-heart,
    &.is--hover .fa-heart {
      color: #fb0d00;
    }

    &.sm {
      color: #eaedf1;
    }

    &.is--active,
    &.is--hover {
      color: #f1c40f;
    }

    &.is--no-hover,
    .fa-heart .is--no-hover {
      color: #f1f1f9;
    }
  }

  .br-theme-bars-horizontal .br-widget a {
    background-color: $dark-body;
  }

  .br-theme-bars-pill .br-widget a {
    background-color: $dark-body;

    &.br-active,
    &.br-selected {
      color: white;
    }
  }

  .br-theme-bars-square .br-widget a {
    border: 2px solid $dark-border;
    background-color: $dark-body;
    color: $dark-white;

    &.br-active,
    &.br-selected {
      border: 2px solid $primary;
    }
  }

  .dark-theme .br-theme-bars-movie .br-widget a,
  .br-theme-bars-1to10 .br-widget a,
  .br-theme-bars-movie .br-widget a {
    background-color: $dark-body;
  }

  .dark-theme .rating-stars input {
    color: #495057;
    background-color: $dark-white;
    border: 1px solid #eaedf1;
  }

  .latest-timeline1-icon {
    color: $dark-white;
  }

  .latest-timeline ul.timeline:before {
    background: #eff0f6;
  }

  .latest-timeline1 a {
    color: #181727;
  }

  .vtimeline {
    &:before {
      background-color: $black-2;
    }

    .timeline-wrapper {
      .timeline-panel {
        background: $dark-body;
        box-shadow: 0 4px 8px 0 rgba(53, 52, 82, 0.5);

        &:after {
          border-top: 14px solid transparent;
          border-left: 14px solid $black-2;
          border-right: 0 solid $black-2;
          border-bottom: 14px solid transparent;
        }
      }

      .timeline-badge {
        border: 2px solid $dark-border;

        i {
          color: $dark-white;
        }
      }

      &.timeline-inverted .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
      }
    }
  }

  .timeline-wrapper-primary {
    .timeline-panel:before {
      background: $primary;
    }
    .timeline-badge {
      background: $dark-theme;
      border: 4px solid $primary !important;
    }
  }

  .timeline-wrapper-secondary {
    .timeline-panel:before {
      background: $secondary;
    }
    .timeline-badge {
      background: $dark-theme;
      border: 4px solid $secondary !important;
    }
  }

  .timeline-wrapper-success {
    .timeline-panel:before {
      background: $success;
    }
    .timeline-badge {
      background: $dark-theme;
      border: 4px solid $success !important;
    }
  }

  .timeline-wrapper-info {
    .timeline-panel:before {
      background: $info;
    }
    .timeline-badge {
      background: $dark-theme;
      border: 4px solid $info !important;
    }
  }

  .timeline-wrapper-warning {
    .timeline-panel:before {
      background: $warning;
    }
    .timeline-badge {
      background: $dark-theme;
      border: 4px solid $warning !important;
    }
  }

  .timeline-wrapper-danger {
    .timeline-panel:before {
      background: $danger;
    }
    .timeline-badge {
      background: $dark-theme;
      border: 4px solid $danger !important;
    }
  }

  .timeline-wrapper-light {
    .timeline-panel:before {
      background: #f1f2f9;
    }
    .timeline-badge {
      background: $dark-theme;
      border: 4px solid #f1f2f9 !important;
    }
  }

  .timeline-wrapper-dark {
    .timeline-panel:before {
      background: #828db1;
    }
    .timeline-badge {
      background: $dark-theme;
      border: 4px solid #828db1 !important;
    }
  }

  .img-sm {
    border: 1px solid $dark-border;
    background: $dark-body;
  }

  .avatar {
    color: $dark-white;
  }

  .main-avatar {
    color: $dark-white;

    &::after {
      background-color: $white-7;
      box-shadow: 0 0 0 2px $dark-body;
    }

    &.online::after {
      background-color: $success;
    }

    &.bg-danger::after {
      background-color: $danger;
    }

    &.bg-success::after {
      background-color: $success;
    }

    &.bg-warning::after {
      background-color: $warning;
    }
  }

  .avatar-xl::after {
    box-shadow: 0 0 0 2.5px $dark-white;
  }

  .avatar-xxl::after {
    box-shadow: 0 0 0 3px $dark-white;
  }

  .main-list-item {
    + .main-list-item {
      border-top: 1px solid $border;
    }

    > div {
      &:first-child h6 {
        color: $color;
      }

      span {
        color: $white-7;
      }
    }
  }

  .ckbox span:before {
    background-color: $dark-body;
    border: 1px solid $dark-border !important;
  }

  .custom-checkbox .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before,
    &:indeterminate ~ .custom-control-label::before {
      background-color: $primary;
    }
  }

  .main-toggle {
    background-color: $white-1;

    span {
      background-color: $dark-theme;

      &::before,
      &::after {
        color: $dark-white;
        top: 2px;
      }
    }
  }

  .main-toggle-primary.on {
    background-color: $primary;
  }

  .main-toggle-secondary.on {
    background-color: $secondary;
  }

  .main-toggle-success.on {
    background-color: $success;
  }

  .main-toggle-dark.on {
    background-color: #8f9cc0;
  }

  .list-group {
    color: $dark-white;
  }

  .list-group-item-action {
    color: #3c4858;

    &:hover,
    &:focus {
      color: $dark-white;
      background-color: $dark-body;
    }

    &:active {
      color: #8f9cc0;
      background-color: $dark-body;
    }
  }

  .list-group-item {
    background-color: $dark-theme;
    border: 1px solid $dark-border !important;
    color: $dark-white;

    &.disabled,
    &:disabled {
      color: $white-7;
      background-color: $dark-body;
    }
  }

  .listgroup-example .list-group.checked .list-group-item,
  .listorder {
    border: 1px solid $dark-border;
  }

  .list-group-flush {
    .list-group-item {
      border-right: 0;
      border-left: 0;
    }

    &:first-child .list-group-item:first-child {
      border-top: 0;
    }

    &:last-child .list-group-item:last-child {
      border-bottom: 0;
    }
  }

  .list-group-item-primary {
    color: $primary;
    background-color: $dark-body;
    border: none;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: #1b3585;
        background-color: #adc1ff;
      }

      &.active {
        color: $dark-white;
        background-color: #1b3585;
        border-color: #1b3585;
      }
    }
  }

  .list-group-item-secondary {
    color: $secondary;
    background-color: #ffdfef;
    border: none;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: #3f4654;
        background-color: #cacfdb;
      }

      &.active {
        color: $dark-white;
        background-color: #3f4654;
        border-color: #3f4654;
      }
    }
  }

  .list-group-item-success {
    color: #1f5c01;
    background-color: #c8e9b8;
    border: none;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: #1f5c01;
        background-color: #b9e3a5;
      }

      &.active {
        color: $dark-white;
        background-color: #1f5c01;
        border-color: #1f5c01;
      }
    }
  }

  .list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb;
    border: none;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: #0c5460;
        background-color: #abdde5;
      }

      &.active {
        color: $dark-white;
        background-color: #0c5460;
        border-color: #0c5460;
      }
    }
  }

  .list-group-item-warning {
    color: #856404;
    background-color: #ffeeba;
    border: none;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: #856404;
        background-color: #ffe8a1;
      }

      &.active {
        color: $dark-white;
        background-color: #856404;
        border-color: #856404;
      }
    }
  }

  .list-group-item-danger {
    color: #721c24;
    background-color: #f0c6ca;
    border: none;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: #721c24;
        background-color: #f1b0b7;
      }

      &.active {
        color: $dark-white;
        background-color: #721c24;
        border-color: #721c24;
      }
    }
  }

  .list-group-item-light {
    color: #8f9cc0;
    background-color: #fdfdfe;
    border: none;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: #8f9cc0;
        background-color: #f0f2f8;
      }

      &.active {
        color: $dark-white;
        background-color: #7f7f81;
        border-color: #7f7f81;
      }
    }
  }

  .list-group-item-dark {
    color: #1f2533;
    background-color: #c8ccd3;
    border: none;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: #1f2533;
        background-color: #babfc8;
      }

      &.active {
        color: $dark-white;
        background-color: #1f2533;
        border-color: #1f2533;
      }
    }
  }

  .list-group-item.active {
    color: $dark-white;
    background-color: $primary;
    border-color: $primary;
  }

  .jumbotron {
    background-color: #f0f2f8;
  }

  .custom-control-input {
    &:checked ~ .custom-control-label::before {
      color: $dark-white;
    }

    &:focus ~ .custom-control-label::before {
      box-shadow: none;
    }

    &:not(:disabled):active ~ .custom-control-label::before {
      color: $dark-white;
      background-color: #e6ecff;
      border-color: #e6ecff;
    }

    &:disabled ~ .custom-control-label {
      color: $white-7;

      &::before {
        background-color: $dark-theme;
      }
    }
  }

  .custom-control-label::before {
    background-color: $dark-body;
    border: $dark-border solid 1px;
  }

  .embed-responsive {
    .embed-responsive-item,
    iframe,
    embed,
    object,
    video {
      border: 0;
    }
  }

  .stretched-link::after {
    background-color: rgba(0, 0, 0, 0);
  }

  .main-table-reference {
    background-color: $dark-border;

    > {
      thead > tr > {
        th,
        td {
          border: 1px solid $dark-border;
          background-color: $dark-body;
          color: $dark-white;
          border-bottom: 0;
        }
      }

      tbody > tr > {
        th,
        td {
          border: 1px solid $dark-border;
        }
      }
    }
  }

  .main-notification-title {
    color: $dark-white;
  }

  .main-notification-text,
  .main-message-text {
    color: #3c4858;
  }

  .main-notification-list,
  .main-message-list,
  .main-profile-menu .dropdown-menu .dropdown-item:first-child {
    border-top: 1px solid $dark-border;
  }

  .main-notification-list .media + .media,
  .main-message-list .media + .media {
    border-top: 1px solid $dark-border;
  }

  .main-notification-list .media.new,
  .main-message-list .media.new {
    color: #8f9cc0;
  }

  .main-notification-list .media {
    &:hover::before,
    &:focus::before {
      background-color: $dark-body;
      border-top: 1px solid $dark-border;
      border-bottom: 1px solid $dark-border;
    }
  }

  .main-message-list .media {
    &:hover::before,
    &:focus::before {
      background-color: $dark-body;
      border-top: 1px solid $dark-border;
      border-bottom: 1px solid $dark-border;
    }
  }

  .main-notification-list .media-body p,
  .main-message-list .media-body p {
    color: $dark-white;
  }

  .main-notification-list .media-body span,
  .main-message-list .media-body span {
    color: $white-5;
  }

  .main-home-slider {
    background-color: #1904be;

    .card-columns .card {
      border-width: 0;
      background-color: transparent;
    }
  }

  .main-home-content {
    color: rgba(255, 255, 255, 0.7);

    .main-logo,
    h1 {
      color: $dark-white;
    }

    nav:last-child a {
      color: rgba(255, 255, 255, 0.5);

      &:hover,
      &:focus {
        color: $dark-white;
      }
    }
  }

  .main-dropdown-form-demo {
    .static-dropdown {
      background-color: #f0f2f8;
    }

    .dropdown-menu {
      box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
    }

    .dropdown-title {
      color: #565673;
    }
  }

  .main-content-left-components {
    border-right: 1px solid $border;

    .component-item .nav-link {
      color: #3c4858;
    }
  }

  .main-page-header {
    border-bottom: 1px solid #dee2ef;
  }

  .main-page-content-list li i {
    color: $white-7;
  }

  .main-syntax-wrapper pre {
    border-color: #f0f2f8;
    background-color: $dark-white;
  }

  .main-media-list-reviews .media-body small,
  .main-star-group span:last-child {
    color: $white-7;
  }

  .main-media-list-activity {
    .media-icon {
      color: $dark-white;
    }

    .media-body {
      h6 {
        color: #8f9cc0;
      }

      span {
        color: $white-7;
      }
    }

    .media-right {
      color: $white-7;
    }
  }

  .flag-dropdown .dropdown-item {
    color: $dark-white;
  }

  .main-header-right .dropdown-menu .select2-container--default .select2-selection--single {
    background-color: $dark-white;
    border: 1px solid $border;
    border-right: 0;
  }

  .responsive-navbar.navbar .navbar-collapse {
    background: $dark-theme;
  }

  .media-logo,
  .media-icon {
    color: $dark-white;
  }

  .flat a {
    background: $dark-theme;
    color: white;

    &:after {
      background: $dark-theme;
      color: black;
    }

    &.active:before,
    &:hover:before {
      color: #000000;
    }

    &:before {
      background: white;
      box-shadow: 0 0 0 1px #ccc;
    }
  }

  .user-lock .option-dots {
    background: transparent;
  }

  .box-shadow {
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
  }

  .box-shadow-0 {
    box-shadow: none !important;
  }

  .page-header {
    .breadcrumb {
      background: none;
    }

    .breadcrumb-item {
      a {
        color: $dark-white;
      }

      &.active {
        color: #7787bc;
      }
    }
  }

  #back-to-top {
    color: $dark-white;
    border: 2px solid transparent;

    &:hover {
      background: $dark-theme !important;
      color: $primary;
      border: 1px solid $primary;
    }
  }

  .custom-leftnav .main-content-left-components {
    border-right: 0;
  }

  .component-item a:before {
    color: #68798b;
  }

  .main-icon-list {
    border: 1px solid $dark-border;
  }

  .skill-tags a {
    color: $white-7;
    border: 1px solid #f0f2f8;
  }

  .reviewnavs li a {
    color: $white-7;
  }

  .dash-icon {
    color: #d3d9e4;
  }

  .card-pricing .list-unstyled li,
  .card-pricing2 .list-unstyled li,
  .card-pricing3 .list-unstyled li,
  .card-pricing4 .list-unstyled li {
    border-bottom: 1px solid $dark-border;
  }

  .pricing-tabs .nav-price li {
    a {
      color: $dark-white;
      background: $dark-theme;
    }

    .active {
      background: $dark-body;
      border: 1px solid $dark-border;
    }
  }

  .pricingTable2 {
    background: $dark-theme;
    border: 1px solid $dark-border;
  }

  .pricingTable2-header {
    background-color: $dark-theme;
    border-radius: 11px !important;
  }

  .pricingTable2 .pricingTable2-header h3 {
    color: $dark-white;
  }

  .price-value1 {
    color: $dark-white;

    span {
      color: $dark-white;
    }
  }

  .pricing-plans .month {
    color: $dark-white;
  }

  .pricingTable2 {
    .pricingTable2-sign-up {
      border-top: 1px solid $dark-border;
    }

    .btn-block {
      color: $dark-white;
    }

    &.green .pricing-plans {
      background-color: #10d403;
    }

    &.info .pricing-plans {
      background-color: #0ab2e6;
    }
  }

  .example {
    border: 1px solid $dark-border;

    + .highlight {
      border-top: none;
    }
  }

  .highlight {
    border: 1px solid #f0f2f8;
    background: #edeff7;
  }

  .clipboard-icon {
    background: #2a2e4a;
    border: 1px solid $dark-border;
  }

  .our-team:hover .picture img {
    box-shadow: 0 0 0 3px #f0f2f8;
  }

  .token {
    &.operator,
    &.entity,
    &.url {
      background: none;
    }
  }

  .language-css .token.string,
  .style .token.string,
  .token.variable {
    background: none;
  }

  .task-list {
    color: #3c4858;

    &:after {
      background: $dark-body;
    }
  }

  .dt-button-collection .dropdown-item {
    &.active,
    &:active {
      color: #8f9cc0;
      background-color: $dark-theme;
    }
  }

  .advanced-search {
    background: $dark-white;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
  }

  .activity-block .task-list li .task-icon {
    color: $dark-white;
  }

  #global-loader {
    background: $dark-theme;
  }

  .page-header .page-title i {
    color: #577ff5;
  }

  .feature {
    .feature-icon {
      color: $dark-white;
    }

    &.bg-transparent {
      background: #fafbfe !important;
    }
  }

  .search-imgs li img {
    border: 1px solid $dark-border !important;
  }

  .handle-counter {
    input {
      background: $dark-theme;
      color: $dark-white;
      border: none;
    }

    .btn:disabled {
      background-color: #7a7aaf;
      color: $dark-white;

      &:hover {
        background-color: #7a869c;
      }
    }
  }

  .counter-minus.btn {
    border: 1px solid $dark-border;
  }
  .handle-counter .btn {
    border: 1px solid $dark-border;
    color: $white-8;

  }

  .trash {
    fill: $danger !important;
  }

  .itemside .title {
    color: $dark-white;
  }

  .project-status {
    &.success:before {
      border-left: 3px solid $success;
    }

    &.danger:before {
      border-left: 3px solid #f16d75;
    }

    &.warning:before {
      border-left: 3px solid #ecb529;
    }
  }

  .list-card {
    &.danger {
      background: #fff3f4;
    }

    &.success {
      background: #e8f7ee;
    }
  }

  .transcations.table.table-hover tbody tr:hover {
    background-color: transparent;
  }

  .upgrade.custom-card {
    background: #f3f2fd;
  }

  .user-lock .option-dots:hover {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: $border;
  }

  .selectgroup-button {
    border: 1px solid $dark-border;
    color: $dark-white;
  }

  .selectgroup-input {
    &:checked + .selectgroup-button {
      background: $dark-theme;
    }

    &:focus + .selectgroup-button {
      box-shadow: none;
    }
  }

  .colorinput-color {
    border: 1px solid transparent;
    color: $dark-white;
    box-shadow: none;
  }

  .header-icons {
    fill: $dark-white;
    color: $dark-white;
  }
  .header-icons:hover {
    background: $white-05;
  }

  .navbar-toggler .header-icons:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .country-Flag svg {
    background: rgba(255, 255, 255, 0.05);
  }

  .nav-link.icon:hover {
    background: none !important;
  }

  .main-header-menu-icon .header-menu-icon {
    fill: $dark-white;
  }
  .main-header-menu-icon .header-menu-icon:hover {
    background: $white-05;
  }

  .btn-icon-text i {
    color: $primary;
    background: $dark-body;
  }

  .dark-theme .card-item .card-item-icon {
    border: 1px solid $primary;
  }

  .bg-primary-light-1 {
    background: #7886d3;
  }

  .bg-primary-light-2 {
    background: #9fa8e0;
  }

  .bg-primary-light-3 {
    background: #d8dcf3;
  }

  .transaction-blog:before {
    border-left: 2px dotted $white-2;
  }

  .transaction-img {
    border: 2px solid;
  }

  .product-timeline ul.timeline-1:before {
    border-left: none !important;
  }

  .main-content-title {
    color: $dark-white;
  }

  .page-header .breadcrumb-item.active {
    color: $primary;
  }

  .table.dataTable td:first-child {
    border-left: none !important;
  }

  .header-navheading {
    background: $dark-theme;
  }

  .main-notification-text {
    color: $dark-white;
  }

  .main-header-notification.show .dropdown-menu::before,
  .main-profile-menu.show .dropdown-menu::before {
    background: $dark-theme;
  }

  .text-dark {
    color: $dark-white !important;
  }

  .main-message-text {
    color: $dark-white;
  }

  .main-header-message.show .dropdown-menu::before {
    background: $dark-theme;
  }

  .sidebar-icon {
    background: rgba(255, 255, 255, 0.13);
  }

  .table-bordered thead th {
    background: none !important;
    border-bottom-width: 0px !important;
  }

  .buy-sell {
    .form-control,
    .select2-container--default .select2-selection--single {
      background: $dark-theme;
    }
  }

  .bg-background2:before {
    background: $primary-08;
  }

  .tabs-style-1 {
    .panel-tabs {
      border-bottom: 1px solid $dark-border;
    }

    .main-nav-line .nav-link {
      &.active {
        color: $dark-white;
        border: 1px solid;
        border-color: $dark-border $dark-border $dark-theme;
      }

      background: transparent;
      border-color: $dark-border;
    }
  }

  .tabs-style-2 .main-nav-line .nav-link.active,
  .tabs-style-3 .nav.panel-tabs li a.active,
  .tabs-style-4 .nav.panel-tabs li a.active {
    background: $primary;
    color: $dark-white;
  }

  .tabs-style-2 .main-nav-line .nav-link {
    background: $dark-body;
  }

  .tabs-style-3 {
    border: 1px solid $dark-border;

    .nav.panel-tabs li a {
      &.active {
        background: $primary;
        color: $dark-white;
      }

      background: $dark-body;
      color: $dark-white;
    }
  }

  .tabs-style-4 .nav.panel-tabs li a {
    &.active {
      background: $primary;
      color: $dark-white;
    }

    background: $dark-body;
    color: $dark-white;
  }

  .btn-close:focus {
    box-shadow: none !important;
  }

  .hor-menu {
    li.active .hor-icon {
      color: $dark-white;
      fill: $white;
    }

    .nav-link .hor-icon {
      color: $primary;
      fill: $primary;
    }

    li.nav-item.active .nav-link {
      background-color: $primary;
      border: 0px solid transparent;
      border-right: 0px;
    }

    .nav-link {
      color: $dark-white;
    }
  }

  .main-navbar.hor-menu .nav-item.active .nav-link:hover {
    color: $dark-white;
  }
}

@media (max-width: 575px) {
  .dark-theme .main-calendar .fc-header-toolbar button {
    &.fc-month-button,
    &.fc-agendaWeek-button,
    &.fc-agendaDay-button,
    &.fc-listWeek-button,
    &.fc-listMonth-button {
      color: transparent;
    }

    &.fc-month-button::before,
    &.fc-agendaWeek-button::before,
    &.fc-agendaDay-button::before,
    &.fc-listWeek-button::before,
    &.fc-listMonth-button::before {
      color: #8f9cc0;
    }
  }
}

@media (max-width: 991.98px) {
  .dark-theme .main-calendar .fc-view > table > thead {
    th:first-child,
    td:first-child {
      border-left-width: 0;
    }

    th:last-child,
    td:last-child {
      border-right-width: 0;
    }
  }
}

@media (max-width: 991.98px) {
  .dark-theme .main-calendar .fc-view > table > tbody > tr > td {
    &:first-child {
      border-left-width: 0;
    }

    &:last-child {
      border-right-width: 0;
    }
  }
}

@media (min-width: 576px) {
  .dark-theme .main-calendar .fc-event {
    border-top-width: 0;
    border-left-width: 2px;
  }
}

@media (min-width: 480px) {
  .dark-theme .main-card-signin {
    border: 1px solid $border;
  }
}

@media (min-width: 992px) {
  .dark-theme .main-column-signup {
    border-left: 1px solid $border;
  }
}

@media (min-width: 992px) {
  .dark-theme .main-content-left-invoice {
    border-right: 1px solid $border;
  }
}

@media (min-width: 992px) {
  .dark-theme .main-mail-list {
    border-top-width: 0;
  }
}

@media (max-width: 991px) {
  .dark-theme {
    .mail-container .tab-list-items {
      &.task-menu-show,
      &.mail-menu-show {
        background: $dark-white;
        box-shadow: 12px 7px 13px -3px #b4bfd8;
      }
    }

    .task-actions li .task-action-items i {
      color: #031b4e;
      border: 1px solid #e0e7f3;
    }
  }
}

@media (min-width: 601px) {
  .dark-theme .profile-cover__info .nav {
    color: #999;
  }
}

@media (max-width: 600px) {
  .dark-theme .profile-cover__info .nav {
    color: #999;

    li:not(:first-child) {
      border-top: 1px solid #eee;
      border-top: 0;
      border-left-width: 0;
    }
  }
}

@media (min-width: 992px) {
  .dark-theme .main-content-left-profile {
    border-right: 1px solid $border;
    border-bottom: 0;
  }
}

@media (max-width: 767px) {
  .dark-theme .profile-cover__info .nav {
    color: #999;

    li:not(:first-child) {
      border-top: 0;
      border-left-width: 0;
    }
  }
}

@media (min-width: 768px) {
  .dark-theme #checkoutsteps {
    > .steps .number {
      background-color: $black-2;
      color: #9197ab;
    }

    .checkoutline {
      background-color: $dark-border;
    }
  }
}

@media (max-width: 767px) {
  .dark-theme #checkoutsteps {
    > .steps .number {
      background-color: $dark-body;
      color: #9197ab;
    }

    .checkoutline {
      background-color: $dark-border;
    }
  }
}

@media (max-width: 567px) {
  .dark-theme .card-pay .tabs-menu li {
    border-bottom: 1px solid $dark-border;
  }
}

@media (min-width: 480px) {
  .dark-theme {
    .bd-xs {
      border: 1px solid $dark-border;
    }

    .bd-xs-t {
      border-top: 1px solid $dark-border;
    }

    .bd-xs-r {
      border-right: 1px solid $dark-border;
    }

    .bd-xs-b {
      border-bottom: 1px solid $dark-border;
    }

    .bd-xs-l {
      border-left: 1px solid $dark-border;
    }

    .bd-xs-y {
      border-top: 1px solid $dark-border;
      border-bottom: 1px solid $dark-border;
    }

    .bd-xs-x {
      border-left: 1px solid $dark-border;
      border-right: 1px solid $dark-border;
    }
  }
}

@media (min-width: 576px) {
  .dark-theme {
    .bd-sm {
      border: 1px solid $dark-border;
    }

    .bd-sm-t {
      border-top: 1px solid $dark-border;
    }

    .bd-sm-r {
      border-right: 1px solid $dark-border;
    }

    .bd-sm-b {
      border-bottom: 1px solid $dark-border;
    }

    .bd-sm-l {
      border-left: 1px solid $dark-border;
    }

    .bd-sm-y {
      border-top: 1px solid $dark-border;
      border-bottom: 1px solid $dark-border;
    }

    .bd-sm-x {
      border-left: 1px solid $dark-border;
      border-right: 1px solid $dark-border;
    }
  }
}

@media (min-width: 768px) {
  .dark-theme {
    .bd-md {
      border: 1px solid $dark-border;
    }

    .bd-md-t {
      border-top: 1px solid $dark-border;
    }

    .bd-md-r {
      border-right: 1px solid $dark-border;
    }

    .bd-md-b {
      border-bottom: 1px solid $dark-border;
    }

    .bd-md-l {
      border-left: 1px solid $dark-border;
    }

    .bd-md-y {
      border-top: 1px solid $dark-border;
      border-bottom: 1px solid $dark-border;
    }

    .bd-md-x {
      border-left: 1px solid $dark-border;
      border-right: 1px solid $dark-border;
    }
  }
}

@media (min-width: 992px) {
  .dark-theme {
    .bd-lg {
      border: 1px solid $dark-border;
    }

    .bd-lg-t {
      border-top: 1px solid $dark-border;
    }

    .bd-lg-r {
      border-right: 1px solid $dark-border;
    }

    .bd-lg-b {
      border-bottom: 1px solid $dark-border;
    }

    .bd-lg-l {
      border-left: 1px solid $dark-border;
    }

    .bd-lg-y {
      border-top: 1px solid $dark-border;
      border-bottom: 1px solid $dark-border;
    }

    .bd-lg-x {
      border-left: 1px solid $dark-border;
      border-right: 1px solid $dark-border;
    }
  }
}

@media (min-width: 1200px) {
  .dark-theme {
    .bd-xl {
      border: 1px solid $dark-border;
    }

    .bd-xl-t {
      border-top: 1px solid $dark-border;
    }

    .bd-xl-r {
      border-right: 1px solid $dark-border;
    }

    .bd-xl-b {
      border-bottom: 1px solid $dark-border;
    }

    .bd-xl-l {
      border-left: 1px solid $dark-border;
    }

    .bd-xl-y {
      border-top: 1px solid $dark-border;
      border-bottom: 1px solid $dark-border;
    }

    .bd-xl-x {
      border-left: 1px solid $dark-border;
      border-right: 1px solid $dark-border;
    }
  }
}

@media (min-width: 992px) {
  .dark-theme {
    .main-navbar .nav-sub {
      background-color: $dark-theme;
      border: 1px solid $dark-border;
      box-shadow: 0 8px 16px 0 $black-1;
    }
    .main-navbar .nav-sub.nav-sub-mega {
      background: none;
      border: none;
    }
    .nav-item-mega .nav-sub {
      box-shadow: none;
    }

    .main-navbar .nav-sub .container {
      box-shadow: 0 8px 16px 0 $black-1;
    }
    &.hover-submenu.main-sidebar-hide .nav-sub{
      box-shadow: 0 8px 16px 0 $black-1;
    }
    &.hover-submenu1.main-sidebar-hide .nav-sub{
      box-shadow: 0 8px 16px 0 $black-1;
    }
  }
}

@media (min-width: 992px) {
  .dark-theme .main-navbar {
    border-right: 0;
    border-bottom: 0;
    box-shadow: 0 2px 17px 1px rgba(162, 169, 204, 0.24);
    border-bottom: 1px solid transparent;

    .nav-sub-mega {
      .container,
      .container-fluid {
        background-color: $dark-theme;
        border: 1px solid $dark-border;
      }
    }
  }
}

@media (min-width: 992px) {
  .dark-theme .main-navbar .nav-item + .nav-item {
    border-top: 0;
  }
}

@media (max-width: 992px) {
  .dark-theme .main-navbar .nav-sub-link {
    &:hover,
    &:focus {
      color: $dark-white;
    }
  }
}

@media (min-width: 992px) {
  .dark-theme .main-navbar .nav-sub-mega {
    border-top: 0;
  }
}

@media (min-width: 992px) {
  .dark-theme .main-navbar .nav-sub-mega {
    .container > div + div,
    .container-fluid > div + div {
      border-left: 1px solid $dark-border;
    }
  }
}

@media (min-width: 992px) {
  .dark-theme .main-navbar .nav-sub-mega .nav + .nav {
    border-top: 0;
  }
}

@media (min-width: 992px) {
  .dark-theme .main-navbar-three .nav-sub {
    box-shadow: 0 0 0 2px rgba(180, 189, 206, 0.5);
  }
}

/* ###### Navbar  ###### */

/* ###### Wizard ###### */

@media (min-width: 576px) {
  .dark-theme .wizard.vertical > .content {
    border-top-width: 0;
    border-bottom-width: 0;
    border-left: 1px solid $dark-border;
  }
}

@media (min-width: 576px) {
  .dark-theme .wizard.vertical > .actions {
    border-left: 1px solid $dark-border;
  }
}

@media (min-width: 576px) {
  .dark-theme .demo-avatar-group {
    .main-img-user + {
      .main-img-user,
      .main-avatar {
        border: 2px solid $dark-border;
      }
    }

    .main-avatar + {
      .main-img-user,
      .main-avatar {
        border: 2px solid $dark-border;
      }
    }
  }
}

@media (min-width: 576px) {
  .dark-theme #shapes .demo-avatar-group .main-img-user + {
    .main-img-user,
    .main-avatar {
      border: 0px solid $dark-border;
    }
  }
}

@media print {
  .dark-theme {
    pre,
    blockquote {
      border: 1px solid $dark-border;
    }

    .badge {
      border: 1px solid $dark-border;
    }

    .table {
      border-collapse: collapse !important;

      td,
      th {
        background-color: $dark-theme !important;
      }
    }

    .table-bordered {
      th,
      td {
        border: 1px solid $dark-border !important;
      }
    }

    .table-dark {
      color: inherit;

      th,
      td,
      thead th,
      tbody + tbody {
        border-color: $dark-border;
      }
    }

    .table .thead-dark th {
      color: inherit;
      border-color: $dark-border;
    }
  }
}

@media (max-width: 991px) {
  .dark-theme {
    .main-header.side-header {
      border-bottom: 1px solid $dark-border;
    }

    .header-search .select2-container--default .select2-selection--single {
      background-color: $dark-theme;
      border: 1px solid $dark-border;
      border-radius: 3px 0 0 3px;
      border-right: 0;
    }
  }
}

@media (max-width: 599px) {
  .dark-theme .mobile-main-header .nav-link.icon {
    color: #2f395f;
  }
}

@media (max-width: 991px) {
  .dark-theme {
    .main-navbar {
      &.hor-menu {
        .nav-item {
          .nav-sub li:not(.nav-sub-item) {
            color: #525277;
          }
        }

        .nav-sub-link {
          color: $dark-white;
        }
      }

      .nav-sub {
        border-top: 0;
      }
    }

    .main-header {
      box-shadow: none;
      border-bottom: 1px solid $dark-border;
      border-top: 0;
    }
  }
}

@media (max-width: 991px) {
  .dark-theme {
    .main-navbar {
      &.hor-menu .nav-sub-item.active a {
        color: $white;
      }

      .nav-sub-item.active > .nav-sub-link {
        color: $white;

        &:before {
          color: $white;
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .dark-theme {
    .main-navbar.hor-menu .nav-sub-item.active .nav-sub-link.active {
      color: $white;
    }
    .main-navbar .nav-sub-item.active > .nav-sub-link:before {
      color: $white;
    }
  }
}

@media (max-width: 991px) {
  .dark-theme {
    .main-content-body-contacts {
      border-top: 1px solid $dark-border;
    }

    .vtimeline .timeline-wrapper .timeline-panel:after {
      border-right: 14px solid $dark-body !important;
      border-left: 0 solid $dark-body !important;
    }
  }
}

@media (min-width: 992px) {
  .dark-theme .main-navbar.sticky.sticky-pin {
    border-bottom: 1px solid $dark-border;
  }
}

@media (max-width: 991px) {
  .dark-theme {
    .main-mail-compose-compress,
    .main-mail-compose-minimize {
      background-color: transparent;
    }
  }
}

.dark-theme {
  .main-sidebar-sticky {
    background: $dark-theme;
    border-right: 1px solid $dark-border;
  }
  .sidemenu-logo {
    border-bottom: 1px solid $dark-border !important;
    border-right: 1px solid $dark-border;
    background: $dark-theme !important;
  }
  .main-sidebar-body .nav-link {
    color: $dark-white;
  }
  .main-sidebar-body .nav-link .sidemenu-icon {
    fill: $dark-white;
  }
  .main-sidebar-body li.active .sidemenu-icon {
    fill: $white;
  }
  .main-sidebar-body .nav-sub .nav-sub-link:before {
    color: $dark-white;
  }
  .main-sidebar-body .nav-sub-link {
    color: $dark-white;
  }
  .side-menu .nav-link:hover {
    color: $dark-white;
  }
  .main-sidebar-body .nav-label {
    color: $white-4 !important;
  }
  .side-header {
    border-bottom: 1px solid $border;
    box-shadow: none !important;
  }
  .owl-theme::before {
    background: linear-gradient(to left, transparent 0%, $dark-body 100%);
  }
  .owl-theme::after {
    background: linear-gradient(to right, transparent 0%, $dark-body 100%);
  }
  .table-striped > tbody > tr:nth-of-type(odd) {
    color: $dark-white;
  }
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $dark-body !important;
    color: $primary;
  }
  .breadcrumb {
    background-color: transparent;
  }
  .bootstrap-tagsinput {
    background-color: $dark-theme;
    .badge {
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }
  .notifit_confirm,
  .notifit_prompt {
    background-color: $dark-theme;
    box-shadow: 0 8px 16px 0 $black-5;
  }
  .tree li.branch {
    background-color: $dark-theme;
    color: $dark-white;
    border: 1px solid $dark-border;
  }
  .tree li a {
    color: $dark-white;
  }
  .tree li.branch li {
    background: $dark-theme;
    color: $dark-white;
    border: 1px solid $dark-border;
  }
  .tree ul {
    &:before {
      border-left: 2px solid $dark-border;
    }

    li:before {
      border-top: 2px solid $dark-border;
    }
  }
  .sweet-alert {
    background-color: $dark-theme;
    box-shadow: 0 0.5rem 1rem rgba(16, 19, 41, 0.15);
    border: 1px solid $dark-border;

    h2 {
      color: $dark-white;
    }
  }
  .modal-content .btn-close {
    color: $dark-white;
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
    border: 0;
    border-radius: 0.25rem;
  }
  table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before,
  table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
    border: 2px solid $dark-border;
  }
  .svg-icon {
    fill: $dark-white;
  }
  .datepicker-days tbody {
    background-color: $dark-theme;
  }
  .datepicker td,
  .datepicker th {
    color: $dark-white;
  }
  .datepicker-dropdown.datepicker-orient-top:before {
    border-top: 7px solid $dark-border;
    border-bottom: 0;
  }
  .datepicker-dropdown.datepicker-orient-top:after {
    border-top: 6px solid $dark-theme;
    border-bottom: 0;
  }
  .datepicker table tr td span {
    background-color: $dark-theme;
  }
  .datepicker table tr td span:hover,
  .datepicker table tr td span.focused {
    background-color: $dark-body;
  }
  .datepicker-dropdown:after {
    border-bottom: 6px solid $dark-theme;
  }
  .datepicker-dropdown:before {
    border-bottom: 7px solid $dark-border;
  }
  .intl-tel-input input {
    border: 1px solid $dark-border;
    background: $dark-theme;
    color: $white;
  }
  .intl-tel-input .flag-dropdown .selected-flag {
    background-color: $dark-theme !important;
    border-right: 1px solid $dark-border;
  }
  .intl-tel-input .flag-dropdown .selected-flag .down-arrow {
    border-top: 4px solid $white;
  }
  .intl-tel-input .flag-dropdown .selected-flag:hover {
    background-color: $dark-body;
  }
  .intl-tel-input .flag-dropdown .country-list {
    background-color: $dark-theme;
    box-shadow: 0 8px 16px 0 $black-1;
  }
  .intl-tel-input .flag-dropdown .country-list .country.highlight {
    background-color: $dark-theme !important;
    border: 0 !important;
  }
  .intl-tel-input .flag-dropdown .country-list .divider {
    border-bottom: 1px solid $dark-border;
  }
  .daterangepicker .calendar-table {
    border: 1px solid $dark-border;
    background-color: $dark-theme;

    td {
      color: $dark-white !important;
    }
  }
  .daterangepicker td.active,
  .daterangepicker td.active:hover {
    background-color: $dark-body !important;
  }
  .daterangepicker td.off,
  .daterangepicker td.off.in-range,
  .daterangepicker td.off.start-date,
  .daterangepicker td.off.end-date {
    background-color: $dark-body;
  }

  .daterangepicker:after {
    border-bottom: 6px solid $dark-border !important;
  }
  .daterangepicker:before {
    border-bottom: 7px solid $dark-border !important;
  }
  .daterangepicker td.available:hover,
  .daterangepicker th.available:hover {
    background-color: $dark-body !important;
  }
  .daterangepicker td.in-range {
    background-color: $dark-body !important;
  }
  .daterangepicker.dropdown-menu {
    box-shadow: 0 8px 16px 0 $black-1;
  }
  .dropify-wrapper {
    background-color: $dark-theme;
    color: $dark-white;
    border: 1px solid $dark-border;
  }
  .dropify-wrapper:hover {
    background-image: linear-gradient(
      -45deg,
      $dark-body 25%,
      transparent 25%,
      transparent 50%,
      $dark-body 50%,
      $dark-body 75%,
      transparent 75%,
      transparent
    ) !important;
  }
  .dropify-wrapper .dropify-preview {
    background-color: $dark-theme;
  }
  .ff_fileupload_wrap .ff_fileupload_dropzone {
    border: 2px dashed $dark-border;
    background-color: $dark-body !important;
  }
  .ff_fileupload_wrap .ff_fileupload_dropzone:hover,
  .ff_fileupload_wrap .ff_fileupload_dropzone:focus,
  .ff_fileupload_wrap .ff_fileupload_dropzone:active {
    background-color: $dark-theme;
    border-color: $dark-border;
  }
  .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary .ff_fileupload_filename input {
    background-color: transparent;
    color: $dark-white;
  }
  .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button {
    background-color: $dark-body !important;
    border: 1px solid $dark-border !important;
  }
  .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary .ff_fileupload_progress_background {
    background-color: $dark-body !important;
  }
  .bg-gray-100 {
    background-color: #2d3458;
  }
  .gray-set .bg-gray-100 {
    background-color: #eaeafb !important;
  }
  .bg-gray-300 {
    background-color: #3f4771;
  }
  .gray-set .bg-gray-300 {
    background-color: #babade !important;
  }
  .bg-gray-400 {
    background-color: #2f3a75;
  }
  .gray-set .bg-gray-400 {
    background-color: #8f8fb7 !important;
  }
  .apexcharts-radialbar-hollow {
    fill: $dark-theme !important;
  }
  .apexcharts-text.apexcharts-datalabel-label,
  .apexcharts-text.apexcharts-datalabel-value {
    fill: $dark-white;
  }
  #revenuemorrischart text,
  #donutexample text,
  #morrisDonut2 text {
    fill: $dark-white;
  }
  .datepicker-container {
    background-color: $dark-theme;
    box-shadow: 0 8px 16px 0 $black-1;
  }
  .datepicker-panel > ul > li {
    background-color: $dark-theme;
    color: $dark-white;
  }
  .datepicker-panel > ul[data-view="week"] > li,
  .datepicker-panel > ul[data-view="week"] > li:hover {
    background-color: $dark-theme;
    color: $dark-white;
  }
  .datepicker-panel > ul > li[data-view="years current"],
  .datepicker-panel > ul > li[data-view="year current"],
  .datepicker-panel > ul > li[data-view="month current"] {
    color: $dark-white;
  }
  .datepicker-top-left::after,
  .datepicker-top-right::after {
    border-bottom-color: $dark-body !important;
  }
  #revenuemorrischart,
  #donutexample,
  #morrisDonut2 {
    svg path {
      stroke: $dark-theme !important;
    }
  }
  div.dt-button-info {
    background-color: $dark-theme;
    border: 2px solid $dark-border;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
    color: white;
  }
  div.dt-button-info h2 {
    background-color: $dark-theme;
    border: 2px solid $dark-border;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
    color: white;
  }
}

.dark-theme {
  .panel-group .panel {
    box-shadow: none;
    border-color: $dark-border;
  }
  .panel-group .panel,
  .panel-group1 .panel {
    border-radius: 6px;
    box-shadow: none;
    border: 1px solid $dark-border;
  }
  #accordion .panel-default > .panel-heading {
    border: 0px solid $dark-border;
  }
  .panel-default > .panel-heading {
    color: $dark-white;
  }
  .panel-default > .panel-heading {
    background-color: $dark-body;
    border-color: $dark-border;
  }
  .panel-heading {
    background: $dark-theme;
  }
  .accordion-item {
    border: 1px solid $dark-border;
    background: $dark-theme;
  }
  .accordion-button.collapsed {
    background: $dark-theme;
    box-shadow: none;
    color: $dark-white;
  }
  .accordion-button:not(.collapsed) {
    color: $primary;
    box-shadow: none;
    background: $dark-body;
  }
  .panel-title1 a {
    background: $primary !important;
  }
  .panel-title1 a {
    color: $dark-white !important;
  }
  .collapse:not(.show) {
    background: $dark-white;
  }
}

.dark-theme {
  @media (min-width: 992px) {
    .main-navbar .nav-sub-link:hover,
    .main-navbar .nav-sub-link:focus {
      color: $white;
    }
  }
}

.dark-theme {
  @media (max-width: 992px) {
    .responsive-navbar.navbar {
      .navbar-collapse {
        border-bottom: 1px solid $dark-border;
        border-top: 1px solid $dark-border;
        box-shadow: 0 8px 16px $black-1;
      }
    }
  }
}

.dark-theme {
  .light-layout {
    display: none;
  }
  .dark-layout {
    display: block;
  }
  .main-sidebar-header .sidemenu-logo {
    .desktop-logo {
      display: none;
    }
    .desktop-logo-dark {
      display: block;
    }
  }
  &.main-sidebar-hide {
    .main-sidebar-header .sidemenu-logo {
      .desktop-logo-dark,
      .icon-logo {
        display: none;
      }
      .icon-logo-dark {
        display: block;
      }
    }
    &.main-sidebar-open {
      .main-sidebar-header .sidemenu-logo {
        .icon-logo-dark,
        .desktop-logo {
          display: none;
        }
        .desktop-logo-dark {
          display: block;
        }
      }
    }
  }
}

.dark-theme {
  &.horizontalmenu {
    .main-header .main-header-left .main-logo .desktop-logo-dark {
      display: block;
    }
    .main-header .main-header-left .main-logo .desktop-logo {
      display: none;
    }
  }
}

.dark-theme {
  @media (max-width: 991px) {
    .main-header-center .responsive-logo .mobile-logo {
      display: none;
    }

    &.horizontalmenu {
      .main-header .main-header-left .main-logo .desktop-logo-dark {
        display: none;
      }
    }

    &.horizontalmenu .mobile-logo-dark {
      display: block;
    }
  }
}

.dark-theme {
  @media (max-width: 991px) {
    &.horizontalmenu .mobile-logo-dark {
      display: block;
      margin: auto;
    }
  }
}

body.dark-theme *::-webkit-scrollbar-thumb,
body.dark-theme *:hover::-webkit-scrollbar-thumb {
  background: $dark-body;
}

.dark-theme {
  .header-setting-icon:hover {
    background: $white-05 !important;
  }
  .header-setting-icon .settings-icon {
    fill: $dark-white !important;
  }
}
.dark-theme {
  .datepicker table tr td span {
    background: transparent !important;
  }
  .datepicker table tr td span:hover,
  .datepicker table tr td span.focused {
    background: $dark-body !important;
  }
  .daterangepicker .input-mini.active,
  .daterangepicker .input-mini {
    border: 1px solid $dark-border !important;
  }
  #basic-addon2.input-group-text {
    border-right: 1px solid $dark-border !important;
    border-left: none;
  }
  .contact-icon {
    background-color: $dark-body;
    color: $dark-white !important;
  }
}

.dark-theme {
  .ps__thumb-x {
    background-color: $white-3;
  }

  .ps__thumb-y {
    background-color: $white-3;
  }

  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x {
    background-color: $white-3;
  }

  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: $white-3;
  }
}

.dark-theme {
  .fc .fc-non-business {
    background: $dark-theme;
  }
  
  .fc-theme-standard .fc-scrollgrid {
    border: 1px solid $dark-border;
    border-top: 0;
  }
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 1px solid $dark-border;
    border-bottom: 0;
  }
  .fc .fc-daygrid-day.fc-day-today {
    background-color: $dark-body !important;
  }

  .fc-daygrid-day-top a {
    color: $primary !important;
  }
}

.dark-theme {
  .transaction-blog:before{
    border-left-color: $white-3
  }
  .signin-or-title {
    position: relative;

    &::after {
      background-color: $dark-border;
    }
  }
  .signin-or-title .title {
    background-color: $dark-theme;
  }
}
.dark-theme {
  .listorder1 {
    background-color: $dark-theme;
    border: 1px solid $dark-border;
  }
  .listunorder1 {
    background-color: $dark-theme;
    border: 1px solid $dark-border;
  }
  .listunorder {
    background-color: $dark-theme;
    border: 1px solid $dark-border;
  }
}
.dark-theme {
  .form-check-input {
    background-color: $dark-theme;
    border: 1px solid $dark-border;
  }
  .form-check-input:checked {
    background-color: $primary;
    border-color: none;
  }
  .form-check-input:focus {
    box-shadow: none;
    border-color: $dark-border;
  }
}
.dark-theme .user-list-avatar {
  box-shadow: 0 2px 4px 0 #14142b;
  border: 2px solid $dark-border;
}

.dark-theme {
  .ui-timepicker-wrapper {
    background: $dark-theme;
    border: 1px solid $dark-border;
  }
  .ui-timepicker-list li {
    color: $dark-white;
  }
}

.dark-theme {
  .richText {
    border: 1px solid $dark-border;
    background-color: $dark-theme;
  }
  .richText .richText-toolbar {
    border-bottom: 1px solid $dark-border;
    border-top: 1px solid $dark-border;
  }
  .richText .richText-toolbar ul li a {
    border-right: 1px solid $dark-border;
  }
  .richText .richText-editor {
    background-color: $dark-theme;
    border-left: $dark-theme solid 2px;
  }
  .richText .richText-editor:focus {
    border-left: $primary solid 2px;
  }
  .richText .richText-toolbar ul li a:hover {
    background-color: $dark-body;
  }
  .richText .richText-undo,
  .richText .richText-redo {
    border-right: 1px solid $dark-border;
  }
}
.dark-theme {
  .note-btn.btn-default:not(:disabled):not(.disabled).active {
    color: $white-7;
    background-color: $dark-body;
    border-color: $dark-border;
  }
}
.dark-theme {
  .profile-share {
    border: 1px solid $dark-border;
    background: $dark-theme;
  }
}
.dark-theme {
  .pricing-tabs .pri-tabs-heading {
    border: 1px solid $dark-border;
  }
}
.dark-theme {
  .list-group-transparent .list-group-item {
    background: none;
    border: 0 !important;
  }
}

.dark-theme {
  .desc-tabs .nav.panel-tabs li a {
    color: $dark-white;
  }
  .desc-tabs .nav.panel-tabs li a.active {
    color: $primary;
    border-bottom: 2px solid $primary;
  }
}
.dark-theme {
  hr.message-inner-separator {
    background-image: linear-gradient(to right, $dark-theme, $white-4, $dark-theme);
    border: 0;
  }
}
.dark-theme {
  .tag-outline {
    border: 1px solid $dark-border;
    color: $dark-white;
    background-color: transparent;
  }
  .file-attach .icons li a {
    border: 1px solid $dark-theme;
  }
  .file-attach .icons li a {
    background: $dark-theme;
  }
}
.dark-theme {
  .theme-container .active {
    background: $dark-theme;
    color: $primary;
  }
  .theme-container1 .active {
    background: $dark-theme;
    color: $secondary;
  }
  .theme-container2 .active {
    background: $dark-theme;
    color: $info;
  }
  .pcr-app {
    background: $dark-body;
  }
  .pickr input:focus,
  .pickr input.pcr-active,
  .pickr button:focus,
  .pickr button.pcr-active,
  .pcr-app input:focus,
  .pcr-app input.pcr-active,
  .pcr-app button:focus,
  .pcr-app button.pcr-active {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.85), 0 0 0 3px var(--pcr-color);
  }
}

.dark-theme {
  .main-msg-emoji,
  .main-msg-attach {
    color: $dark-white;
  }
}

.dark-theme {
  .tree li i {
    color: $primary !important;
  }
}

.dark-theme {
  .notification .notification-body {
    background: $dark-theme;
  }
  .notification .notification-body:before {
    border-right-color: $dark-theme;
  }
  .notification .notification-icon a {
    background: $dark-body;
  }
  .notification .notification-time .time {
    color: #a7a9bb;
  }
  .notification .notification-time .date {
    color: #a7a9bb;
  }
  .notification-time-date {
    color: #a7a9bb;
  }
}

.dark-theme {
  &.main-sidebar-hide.main-sidebar-open.hover-submenu {
    .main-sidebar-header {
      .sidemenu-logo {
        .desktop-logo-dark,
        .icon-logo {
          display: none;
        }
        .icon-logo-dark {
          display: block;
        }
      }
    }
    .nav-sub {
      background: $dark-theme;
    }
  }
  &.main-sidebar-hide.main-sidebar-open.hover-submenu1 {
    .main-sidebar-header {
      .sidemenu-logo {
        .desktop-logo-dark,
        .icon-logo {
          display: none;
        }
        .icon-logo-dark {
          display: block;
        }
      }
    }
    .nav-sub {
      background: $dark-theme;
    }
    .side-menu-label1 {
      border-bottom: 1px solid $dark-border !important;
    }
  }
}
.dark-theme {
  .btn-country {
    border: 1px solid $dark-border;
    color: $white;
    &:hover {
      border: 1px solid $primary !important;
    }
  }

  .btn-check:checked + .btn-country,
  .btn-country.active,
  .btn-country:active {
    border: 1px solid $primary !important;
    color: $white;
    background-color: $primary;
    box-shadow: none;
  }
}

.dark-theme {
  .note-editor.note-frame.panel.panel-default .panel-heading {
    background-color: $dark-theme;
    border-bottom: 1px solid $dark-border;
  }
  .note-editor.note-airframe,
  .note-editor.note-frame {
    border: 1px solid $dark-border;
  }
  .note-btn.btn-default {
    background-color: $dark-theme;
    border-color: $dark-border;
  }
  .note-editor .btn-default:not(:disabled):not(.disabled):active {
    background-color: $dark-theme;
  }
}

.dark-theme {
  .btn-close-white {
    filter: invert(0) grayscale(100%) brightness(200%);
  }

  .tree li.branch {
    background: $dark-theme !important;
  }
  .main-signin-wrapper .header-setting-icon {
    background: $darkprimary-transparent;
  }
}

.dark-theme {
  .switcher-2 .onoffswitch2-label {
    background-color: $dark-body;
    border: 1px solid $dark-border;

    &:before {
      background-color: $dark-theme;
      border: 1px solid $dark-border;
    }
  }
}

.dark-theme {
  @media (min-width: 992px) {
    &.horizontalmenu .menu-nav .nav-item ul.nav-sub::before {
      border: 1px solid transparent;
      border-top-color: $dark-border;
      border-left-color: $dark-border;
      background-color: $dark-theme;
    }
    .main-navbar .sub-nav-sub {
      background-color: $dark-theme;
      border: 1px solid $dark-border;
    }
    .main-navbar.hor-menu .nav-sub-item.active .nav-sub-link.active {
      .sidemenu-label, &::after {
        color: $primary !important;
      }
    }
  }
}

.dark-theme {
  @media (max-width: 991px) {
    &.horizontalmenu.main-navbar-show .main-navbar {
      border-right: 1px solid $dark-border;
    }
  }
}

.dark-theme {
  .main-sidebar-body li.active .nav-sub-link.sub-with-sub .sidemenu-label,
  .main-sidebar-body li.active .nav-sub-link i {
    color: $dark-white !important;
  }

  .main-sidebar-body .nav-sub-item.active .nav-sub-item .nav-sub-link.active {
    color: $primary !important;
  }
  .main-sidebar-body .nav-sub-item.active .nav-sub-item .nav-sub-link {
    color: $dark-white !important;
  }
  .main-sidebar-body .nav-sub-item.active .nav-sub-link.active {
    color: var(--primary-bg-color) !important;
  }
}

.dark-theme {
  .main-navbar.main-menu li.active .nav-sub-link.sub-with-sub .sidemenu-label {
    color: $dark-white !important;
  }

  .main-navbar.main-menu .nav-sub-item.active .nav-sub-item .nav-sub-link.active {
    color: $primary !important;
  }
  .main-navbar.main-menu .nav-sub-item.active .nav-sub-item .nav-sub-link {
    color: $dark-white !important;
  }
}

.dark-theme {
  .file-image-sm,
  .file-image-md,
  .file-image-lg {
    border: 1px solid $dark-border;
  }
  .file-image-sm .file-name-sm {
    color: #a7a9bb;
  }
}

.dark-theme {
  .signpages .login_form {
    .mobilelogo .light-logo {
      display: block;
    }
    .mobilelogo .dark-logo {
      display: none;
    }
  }
}

.dark-theme {
  .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown {
    background-color: $dark-theme;
    border: 1px solid $dark-border;
  }
  .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown .richText-dropdown-close {
    color: $dark-white;
  }
  .richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li a {
    border-bottom: 1px solid $dark-border;
  }
  .richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li a:hover {
    background-color: $dark-body;
  }
  .apexcharts-series.Total {
    path {
      stroke: $white-2;
    }
  }
}

.dark-theme {
  .demo_changer .form_holder {
    background: $dark-theme;
    border-left: 1px solid $dark-border;
    box-shadow: 0 8px 16px 0 $black-2;
  }
  .demo_changer h4 {
    background: $dark-body;
    border-bottom: 1px solid $dark-border;
    border-top: 1px solid $dark-border;
  }
  .demo_changer,
  .demo_changer p {
    color: $dark-white !important;
  }
  .onoffswitch2-checkbox:checked + .onoffswitch2-label {
    background-color: $primary;
  }
  .onoffswitch2-label {
    border: 1px solid $dark-border;
  }
  .onoffswitch2-label {
    background-color: $dark-body;
  }
  .onoffswitch2-checkbox:checked + .onoffswitch-label2,
  .onoffswitch2-checkbox:checked + .onoffswitch2-label:before {
    border-color: $primary;
  }
}
.dark-theme {
  @media (min-width: 992px) {
    .hor-menu .slide-left,
    .hor-menu .slide-right {
      border: 1px solid $dark-border;
      background-color: $dark-body;
    }
  }
}

.dark-theme {
  .datetimepicker table th.today:hover,
  .datetimepicker-days td:hover,
  .datetimepicker-hours span.hour:hover,
  .datetimepicker-hours span.minute:hover,
  .datetimepicker-hours span.month:hover,
  .datetimepicker-hours span.year:hover,
  .datetimepicker-minutes span.hour:hover,
  .datetimepicker-minutes span.minute:hover,
  .datetimepicker-minutes span.month:hover,
  .datetimepicker-minutes span.year:hover,
  .datetimepicker-months span.hour:hover,
  .datetimepicker-months span.minute:hover,
  .datetimepicker-months span.month:hover,
  .datetimepicker-months span.year:hover,
  .datetimepicker-years span.hour:hover,
  .datetimepicker-years span.minute:hover,
  .datetimepicker-years span.month:hover,
  .datetimepicker-years span.year:hover {
    background: $dark-body;
  }
  &.rtl {
    table.table-bordered.dataTable th:first-child,
    table.table-bordered.dataTable th:first-child,
    table.table-bordered.dataTable td:first-child,
    table.table-bordered.dataTable td:first-child {
      border-right-width: 0;
    }
    table.table-bordered.dataTable th:last-child,
    table.table-bordered.dataTable th:last-child,
    table.table-bordered.dataTable td:last-child,
    table.table-bordered.dataTable td:last-child {
      border-right-width: 1px !important;
    }
    .transaction-blog:before{
      border-right-color: $white-3
    }
  }
}

.dark-theme {
  .apexcharts-tooltip.light {
    border: 1px solid $dark-border;
    background: $dark-body
  }
  .apexcharts-tooltip.light .apexcharts-tooltip-title {
    background: $dark-body;
    border-bottom: 1px solid $dark-border;
  }
  .apexcharts-tooltip {
    box-shadow: 2px 2px 6px -4px $dark;
  }
  .form-control::placeholder{
    color: $white-3;
  }
  .main-contact-item.selected{
    border-left-color:$white-2;
    border-bottom-color:$white-2;
  }
  .main-contact-item:hover, .main-contact-item:focus{
    border-bottom-color:$white-2;
  }
  .richText .richText-form input[type=text], .richText .richText-form input[type=file], .richText .richText-form input[type=number],.richText .richText-form select{
    background-color: $white-2;
    border-color: $white-3;
  }
  &.header-light .main-header-center .form-control{
   color: $black-6 !important;
  }
}

.orders-activity .list-group-item {
  border-left: 0 !important;
  border-right: 0 !important;
}

.sales-analytics .list-group-item {
  border: 0 !important;
}
