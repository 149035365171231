/* ###### Profile  ###### */

.profile-cover__action {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  border-radius: 6px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  overflow: hidden;
  background: url(../img/media/bg-profileimage.jpg) no-repeat;
  background-size: cover;
  height: 255px;

  > .btn {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

.profile-cover__img {
	position: absolute;
	left: 50px;
	top: 160px;
	text-align: center;
	z-index: 1;

  > {
    img {
      max-width: 100px;
      border: 0px solid $white;
      border-radius: 50%;
    }

    .h3 {
      color: #393939;
      font-size: 20px;
      line-height: 30px;
    }

    img + .h3 {
      margin-top: 6px;
    }
  }
}

@media (min-width: 601px) {
  .profile-cover__info .nav {
    margin-right: 28px;
    padding: 15px 0 10px 170px;
    color: #999;
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
  }
}

.profile-cover__info .nav {
  li {
    margin-top: 13px;
    margin-bottom: 13px;
    font-size: 14px;
    color: #464461;
    font-weight: 500;

    &:not(:first-child) {
      margin-left: 30px;
      padding-left: 30px;
      border-left: 1px solid $border;
    }
  }

  strong {
    display: block;
    margin-bottom: 10px;
    font-size: 29px;
  }
}

.profile-tab .main-nav-line .nav-link {
  font-weight: 500;
}

@media (min-width: 481px) {
  .profile-cover__action > .btn {
    min-width: 125px;

    > span {
      display: inline-block;
    }
  }
}

@media (max-width: 600px) {
  .profile-cover__info .nav {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    margin-right: 28px;
    padding: 39px 0 10px 170px;
    margin-right: 0;
    padding: 0;
    display: flex;

    li {
      &:not(:first-child) {
        margin-top: 8px;
        margin-left: 0;
        padding-top: 0;
        padding-left: 0;
        border-top: 1px solid #eee;
        border-left-width: 0;
        margin-top: 8px;
        margin-left: 0;
        padding-top: 18px;
        padding-left: 0;
        border-top: 0;
        border-left-width: 0;
      }

      margin-top: 27px;
      margin-bottom: 0;
      margin-right: 1rem;
      font-size: 13px;
    }

    margin-right: 0;
    padding: 18px 0 10px 0;
    color: #999;
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-start;
  }

  .main-nav-line .nav-link + .nav-link {
    margin-top: 13px;
    margin-left: 0;
  }
}

.main-content-profile {
  flex: 1;
}

@media (max-width: 991.98px) {
  .main-content-profile {
    .container, .container-fluid {
      display: block;
    }
  }
}

.main-content-left-profile {
  padding-left: 0;
  padding-right: 0;
  display: block;
  border-bottom: 1px solid $border;
  padding-bottom: 25px;
  width: auto;
}

@media (min-width: 992px) {
  .main-content-left-profile {
    width: 270px;
    padding-right: 20px;
    padding-bottom: 0;
    border-right: 1px solid $border;
    border-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .main-content-left-profile {
    padding-right: 25px;
  }
}

.main-profile-overview {
  .main-img-user {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    margin: 0 auto;

    &::after {
      display: none;
    }
  }

  .btn-icon-list {
    .btn, .sp-container button {
      border-radius: 100%;
    }
  }
}

.sp-container .main-profile-overview .btn-icon-list button {
  border-radius: 100%;
}

.main-profile-name {
  color: #8f9cc0;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 2px;
}

.main-profile-name-text {
  color: #a8afc7;
  font-size: 13px;
  margin-bottom: 0;
}

.main-profile-bio {
  font-size: 13px;
  margin-bottom: 20px;
}

.main-profile-social-list {
  .media {
    align-items: center;

    + .media {
      margin-top: 20px;
    }
  }

  .media-body {
    margin-left: 20px;

    span {
      display: block;
      font-size: 12px;
    }

    a {
      font-size: 13px;
    }
  }
}

.main-content-body-profile {
  .nav {
    flex-direction: column;
    padding: 20px 20px 20px 20px;
    border-bottom: 1px solid $border;
  }

  .main-nav-line .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    color: #616366;

    &.active {
      font-weight: 600;
    }
  }
}

@media (min-width: 576px) {
  .main-content-body-profile .nav {
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .main-content-body-profile .nav {
    padding-left: 20px;
  }
}

@media (min-width: 1200px) {
  .main-content-body-profile .nav {
    padding-left: 25px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .main-content-body-profile .main-nav-line .nav-link.active::before {
    width: auto;
    top: auto;
    left: 0;
    right: 0;
    height: 2px;
  }
}

@media (min-width: 576px) {
  .main-content-body-profile .main-nav-line .nav-link.active::before {
    bottom: -11px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .main-content-body-profile .main-nav-line .nav-link + .nav-link {
    margin-top: 0;
  }
}

.main-profile-body {
  padding: 15px 0 0;
}

@media (min-width: 576px) {
  .main-profile-body {
    padding-top: 20px;
  }
}

@media (min-width: 992px) {
  .main-profile-body {
    padding: 25px 0 0 20px;
  }
}

@media (min-width: 1200px) {
  .main-profile-body {
    padding-left: 25px;
  }
}

.main-profile-view-chart {
  position: relative;
  width: calc(100% - 10px);
  height: 200px;
}

@media (min-width: 375px) {
  .main-profile-view-chart {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .main-profile-view-chart {
    height: 250px;
  }
}

.main-profile-view-info {
  position: absolute;
  top: 0;
  left: 0;

  h6 {
    font-size: 32px;
    font-weight: 500;
    color: #8f9cc0;
    margin-bottom: 0;
  }

  span {
    font-size: 12px;
    color: #f10075;
    margin-left: 5px;
  }

  p {
    font-size: 13px;
    margin-bottom: 0;
  }
}

.main-traffic-detail-item {
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 7px;

    > span {
      &:first-child {
        color: #51586f;
        font-size: 14px;
      }

      &:last-child {
        font-size: 11px;
        font-weight: 700;
        color: #5e5e77;

        span {
          color: #a8afc7;
          font-weight: 400;
        }
      }
    }
  }

  + .main-traffic-detail-item {
    margin-top: 18px;
  }
}

.main-profile-work-list .media + .media {
  margin-top: 25px;
}

.main-profile-work-list .media-body {
  margin-left: 20px;

  h6 {
    color: #8f9cc0;
    font-weight: 500;
    margin-bottom: 2px;
  }

  span {
    display: block;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 0;
    font-size: 12px;
    color: #a8afc7;
  }
}

.main-profile-contact-list {
  .media {
    align-items: center;

    + .media {
      margin-top: 25px;
    }
  }

  .media-body {
    margin-left: 25px;

    span {
      font-size: 12px;
      color: #a8afc7;
      display: block;
      line-height: 1.3;
    }

    div {
      font-weight: 500;
      color: #8f9cc0;
    }
  }
}
.btn-profile {
  position: absolute;
  right: 50px;
  top: 220px;
}
@media (max-width:767px) {
  .profile-cover__info .nav {
    display: flex;
    margin-right: 0;
    padding: 114px 0 10px;
    color: #999;
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;

    li {
      margin-top: 27px;
      margin-bottom: 0;
      margin-right: 1rem;
      font-size: 13px;

      &:not(:first-child) {
        margin-top: 8px;
        margin-left: 0;
        padding-top: 18px;
        padding-left: 0;
        border-top: 0;
        border-left-width: 0;
      }
    }
  }

  .btn-profile {
    right: 0;
    top: 47px;
    left: 0;
    margin: 0 auto;
    text-align: center;
  }

  .main-nav-line .nav-link + .nav-link {
    margin-top: 13px;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
     .profile-footer {
     	display:block !important;
     }
}
.profile-share {
  width: 100%;
  padding: 10px 22px 10px 22px;
  border-radius: 0 0 11px 11px;
  border: 1px solid $border;
  display: flex;
  background: $white;
}
.profile-edit {
  position: relative;
}
.profile-edit {
  .form-control {
    border-radius: 11px 11px 0 0
  }
}
.profile-info {
  display: block;
  top: 185px;
  position: absolute;
  color: $background;
  left: 170px;
}
 @media (min-width: 320px) and (max-width: 568px) {
  .profile-cover__img{
    top: 190px;
    left: 45px;

    img {
      max-width: 70px;
    }
  }
  .profile-info {
    top: 205px;
    left: 130px;
    h3 {
      font-size: 16px;
    }
    h6 {
      font-size: 12px;
    }
  }
 }

 .profile-cover__img a {
    position: absolute;
    bottom: 1px;
    right: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background-color: #f9f9ff;
    color: #454567;
    font-size: 15px;
    line-height: .9;
    border-radius: 50%;
 }



/* ###### Profile  ###### */