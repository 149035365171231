/* ###### Tags  ###### */

.tag {
  font-size: 11px;
  color: $white;
  background-color: #e9edfb;
  border-radius: 3px;
  padding: 0px 8px;
  line-height: 2;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: default;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tag-pill {
  padding-right: 0.9em;
  padding-left: 0.9em;
  border-radius: 50px;
}
a.tag {
  text-decoration: none;
  cursor: pointer;
  transition: .3s color, .3s background;

  &:hover {
    background-color: rgba(110, 118, 135, 0.2);
    color: inherit;
  }
}

.tag-addon {
  display: inline-block;
  padding: 0 .5rem;
  color: inherit;
  text-decoration: none;
  margin: 0 -.5rem 0 .5rem;
  text-align: center;
  min-width: 1.5rem;
  background: #e7ebf5;

  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  i {
    vertical-align: middle;
    margin: 0 -.25rem;
  }
}

a.tag-addon {
  text-decoration: none;
  cursor: pointer;
  transition: .3s color, .3s background;

  &:hover {
    background: #dce1ef;
    color: inherit;
  }
}

.tag-avatar {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 3px 0 0 3px;
  margin: 0 .5rem 0 -.5rem;
}

.tag-blue {
  background-color: #467fcf;
  color: $white;
}

.tag-indigo {
  background-color: $primary;
  color: $white;
}

.tag-purple {
  background-color: #8500ff;
  color: $white;
}

.tag-pink {
  background-color: #ec82ef;
  color: $white;
}

.tag-red {
  background-color: #ec2d38;
  color: $white;
}

.tag-yellow {
  background-color: #fdb901;
  color: $white;
}

.tag-green {
  background-color: #0fa751;
  color: $white;
}

.tag-cyan {
  background-color: #00b9ff;
  color: $white;
}

.tag-orange {
  background-color: #fc7303;
  color: $white;
}

.tag-teal {
  background-color: #2bcbba;
  color: $white;
}

.tag-white {
  background-color: $white;
  color: $white;
}

.tag-gray {
  background-color: #868e96;
  color: $white;
}

.tag-gray-dark {
  background-color: #343a40;
  color: $white;
}

.tag-azure {
  background-color: #17c1f4;
  color: $white;
}

.tag-lime {
  background-color: #7bd235;
  color: $white;
}

.tag-primary {
  background-color: #467fcf;
  color: $white;
}

.tag-secondary {
  background-color: $secondary;
  color: $white;
}

.tag-success {
  background-color: $success;
  color: $white;
}

.tag-info {
   background-color: $info;
  color: $white;
}

.tag-default {
  background-color: $primary-transparent;
  color: #828db1;
}

.tag-warning {
  background-color:$warning;
  color: $white;
}

.tag-danger {
  background-color: $danger;
  color: $white;
}

.tag-light {
  background-color: #f8f9fa;
  color: $white;
}

.tag-dark {
  background-color: $dark;
  color: $white;
}

.tag-rounded {
  border-radius: 50px;

  .tag-avatar {
    border-radius: 50px;
  }
}

.tags {
  margin-bottom: -.5rem;
  font-size: 0;

  > .tag {
    margin-bottom: .5rem;

    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
}
.tag-primary-transparent {
  background-color: $primary-02 !important;
  color: $primary;
}

.tag-secondary-transparent {
  background-color: rgba(241, 56, 139, 0.2) !important;
  color: $secondary;
}

.tag-success-transparent {
  background-color: rgba(25, 177, 89, 0.2) !important;
  color: $success;
}
.tag-danger-transparent {
  background-color: rgba(253, 96, 116, 0.2) !important;
  color: $danger;
}
.tag-info-transparent {
  background-color: rgba(1, 184, 255, 0.2) !important;
  color: $info;
}
.tag-warning-transparent {
  background-color: rgba(255, 155, 33, 0.2) !important;
  color: $warning;
}
.tag-dark-transparent {
  background-color: rgba(59, 72, 99, 0.2) !important;
  color: $dark;
}

.tag-outline {
  border: 1px solid $border;
  color: #53545e;
  background-color: transparent;
}

/* ###### Tags  ###### */