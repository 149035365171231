/* ###### Custom-control ###### */

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:checked ~ .custom-control-label::before {
    color: $white;
  }

  &:focus {
    ~ .custom-control-label::before {
      box-shadow: none;
    }
  }

  &:not(:disabled):active ~ .custom-control-label::before {
    color: $white;
    background-color: #e6ecff;
    border-color: #e6ecff;
  }

  &:disabled ~ .custom-control-label {
    color: #a8afc7;

    &::before {
      background-color: $primary-transparent;
    }
  }
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;

  &::before {
    position: absolute;
    top: 0.00625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: $white;
    border: #dedef5 solid 1px;
    border-radius: 4px;
  }

  &::after {
    position: absolute;
    top: 0.00625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%;
  }
}
.custom-control-input.is-valid ~ .valid-feedback, input.custom-control-input.parsley-success ~ .valid-feedback, textarea.custom-control-input.parsley-success ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip, input.custom-control-input.parsley-success ~ .valid-tooltip, textarea.custom-control-input.parsley-success ~ .valid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback, input.custom-control-input.parsley-error ~ .invalid-feedback, textarea.custom-control-input.parsley-error ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip, input.custom-control-input.parsley-error ~ .invalid-tooltip, textarea.custom-control-input.parsley-error ~ .invalid-tooltip {
  display: block;
}
.custom-control-label::before, .custom-file-label, .form-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before, .custom-file-label, .form-select {
    transition: none;
  }
}
/* ###### Custom-control ###### */