/* ###### Quill ###### */

.ql-wrapper-demo {
  background-color: $white;

  .ql-container {
    height: 250px;
  }

  .ql-editor {
    height: 100%;
    overflow-y: auto;
  }
}

@media (min-width: 1200px) {
  .ql-wrapper-demo {
    width: 100%;
  }
}

.ql-scrolling-demo {
  background-color: $white;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  border: 1px solid $border;
  position: relative;

  .ql-container {
    border-width: 0;
    height: auto;
    min-height: 100%;
    padding: 30px;

    .ql-editor {
      font-size: 16px;
      overflow-y: visible;
      color: $color;
    }
  }
}

@media (min-width: 768px) {
  .ql-scrolling-demo {
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .ql-scrolling-demo .ql-container {
    padding: 18px;
    padding-left: 0;
  }
}

.ql-wrapper {
  display: flex;
  flex-direction: column;
}

.ql-wrapper-modal .ql-snow {
  &.ql-toolbar {
    border-width: 0;
    border-bottom-width: 1px;
  }

  &.ql-container {
    border-width: 0;
  }

  .ql-editor {
    padding: 20px;
  }
}

@media (min-width: 576px) {
  .ql-wrapper-modal .ql-snow.ql-toolbar {
    padding: 15px 20px !important;
  }
}

.ql-bubble {
  &.ql-container {
    &:not(.ql-disabled) a::before {
      background-color: #8f9cc0;
      border-radius: 2px;
    }
  }

  .ql-tooltip {
    background-color: #444;
    border-radius: 2px;

    &.ql-flip .ql-tooltip-arrow {
      border-top-color: $black;
    }

    &:not(.ql-flip) .ql-tooltip-arrow {
      border-bottom-color: $black !important;
    }
  }

  .ql-toolbar {
    padding: 0 8px 8px;
    background: $black;

    button {
      width: 32px !important;
      height: 32px !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      color: #8896af;
      outline: none;

      i {
        font-size: 24px;
        line-height: 0;
        display: inline-block;

        &.la-bold, &.la-underline {
          width: 21px;
        }
      }

      .ql-fill {
        fill: #8896af;
      }

      .ql-stroke {
        stroke: #8896af;
      }

      &.ql-active {
        color: $white;
        background-color: rgba(255, 255, 255, 0.08);
      }
    }

    .ql-formats {
      margin: 8px 8px 0 0;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

@media (min-width: 576px) {
  .ql-bubble .ql-toolbar .ql-formats {
    margin: 8px 0 0 !important;

    &:first-child {
      margin-left: 0 !important;
    }
  }
}

.ql-snow {
  &.ql-container {
    border-color: $border;
    border-width: 1px;
    border-radius: 0 0 6px 6px;
  }

  &.ql-toolbar {
    border-width: 1px;
    border-color: $border;
    border-bottom-width: 1px;
    padding: 5px 10px 10px !important;
    border-radius: 6px 6px 0 0;

    .ql-picker-label {
      border-color: $border !important;
      border-radius: 3px;

      &.ql-active {
        background-color: $background;
        border-color: transparent;
        color: #8f9cc0;
        font-weight: 500;

        .ql-stroke {
          stroke: $black;
        }
      }
    }

    button {
      border: 1px solid transparent !important;
      width: 32px !important;
      height: 32px !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      color: rgba(75, 75, 82, 0.94);
      outline: none;

      svg {
        width: 20px;
        float: none;
      }

      i {
        font-size: 24px;
        line-height: 0;
        display: inline-block;

        &.la-bold, &.la-underline {
          width: 21px;
        }
      }

      .ql-stroke {
        stroke-width: 2px;
      }

      &:hover, &:focus {
        border-color: $background;
      }

      &:hover .ql-stroke, &:focus .ql-stroke {
        stroke: #a8afc7;
      }

      &.ql-active {
        background-color: $background;
        color: $dark !important;
        border-color: transparent;

        .ql-fill {
          fill: $dark;
        }

        .ql-stroke {
          stroke: $dark;
        }
      }
    }

    .ql-formats {
      margin-top: 5px;
      margin-right: 5px !important;
    }
  }

  .ql-formats button + button {
    margin: 0 0 0 2px;
  }

  .ql-picker {
    display: block !important;
    border-width: 0;
    height: 32px !important;
    color: #3c4858 !important;

    &:not(.ql-color-picker):not(.ql-icon-picker) svg {
      right: 3px !important;
    }

    &:hover .ql-picker-label, &:focus .ql-picker-label {
      color: $primary;
    }

    &:hover .ql-picker-label .ql-stroke, &:focus .ql-picker-label .ql-stroke {
      stroke: $black;
    }
  }

  .ql-picker-label {
    display: block !important;
    padding-left: 10px !important;
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    border: 1px solid $border !important;
    background-color: $white;
    outline: none;

    &::before {
      line-height: 2.25 !important;
      font-weight: 400;
    }
  }

  .ql-stroke {
    stroke: $black !important;
    stroke-linecap: square !important;
    stroke-linejoin: miter !important;
    stroke-width: 1 !important;
  }

  .ql-editor {
    padding: 20px;
    color: $dark;
  }
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: $primary !important;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: $primary;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-radius: 6px;
  margin-top: 2px;
}

/* ###### Quill ###### */