/* jQuery Countdown styles 2.0.0. */

#launch_date {
    margin: 0;
  }
  
  .countdown-rtl {
    direction: rtl;
  }
  
  .countdown-holding span {
    color: #888;
  }
  
  .countdown-row {
    clear: both;
    width: 100%;
    padding: 0px 2px;
    text-align: center;
  }
  
  .countdown {
    li {
      display: inline-block;
      font-size: 1.83rem;
      font-weight: 500;
      width: 155px;
      height: 90px;
      border-radius: 11px;
      line-height: 23px;
      padding: 23px;
      text-align: center;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.12);
      z-index: 1;
      box-shadow: 0 8px 16px 0 rgba(29, 29, 31, 0.08);
      color: #fff;
    }
  
    .time {
      font-size: 13px;
      padding-top: 2px;
      font-weight: 300;
    }
  }
  
  @media (max-width: 991px) {
    .countdown li {
      margin: 20px 10px 10px 10px;
    }
  
    .is-countdown {
      margin-top: 20px;
    }
  }
  
  @media (min-width: 992px) {
    .countdown li {
      margin: 30px 10px 5px 0;
    }
  }