@import "custom/fonts/fonts";
@import "variables";




/* ############### Plugin STYLES ############### */
@import "plugins/select2";
@import "plugins/datatables";
@import "plugins/morris";
@import "plugins/owlcarousel";
@import "plugins/quill.bubble";
@import "plugins/quill.snow";
@import "plugins/fancyuploader";
@import "plugins/modal.datepicker";
@import "plugins/inputtags";
@import "plugins/fullcalendar";
@import "plugins/notifit";
@import "plugins/treeview";
@import "plugins/timeline";
@import "plugins/draggablecards";
@import "plugins/sweetalert";
@import "plugins/star-rating-svg";
@import "plugins/gallery";
@import "plugins/jqvmap";
@import "plugins/classic";
@import "plugins/monolith";
@import "plugins/nano";
@import "plugins/spectrum";
@import "plugins/datetime-picker";
@import "plugins/bootstrap-datepicker";
@import "plugins/ion.rangeslider";
@import "plugins/ion.rangeslider.skinFlat";
@import "plugins/fileupload";
@import "plugins/telephoneinput";
@import "plugins/timepicker";
@import "plugins/daterangepicker";
@import "plugins/summernote";
@import "plugins/richtext";
@import "plugins/smartphoto";
@import "plugins/jquery.countdown";

// @import "plugins/jquery.steps";
@import "plugins/multiple-select";
@import "plugins/sumoselect";
@import "plugins/tabs";
