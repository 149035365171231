/* ###### Checkbox ###### */

.ckbox {
  font-weight: normal;
  position: relative;
  display: block;
  line-height: 1;
  margin-bottom: 0;

  span {
    padding-left: 16px;

    &:empty {
      float: left;
      padding-left: 0;
      width: 3px;
    }

    &:before, &:after {
      line-height: 18px;
      position: absolute;
    }

    &:before {
      content: '';
      width: 16px;
      height: 16px;
      background-color: $white;
      border: 1px solid #dedef5 !important;
      top: 1px;
      left: 0;
      border-radius: 3px;
    }

    &:after {
      top: 1px;
      left: 0;
      width: 16px;
      height: 16px;
      content: '';
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
      background-size: 66%;
      background-repeat: no-repeat;
      background-position: 55% 45%;
      line-height: 17px;
      display: none;
      border-radius: 3px;
    }
  }

  input[type='checkbox'] {
    opacity: 0;
    margin: 0;

    &:checked + span:after {
      display: block;
    }

    &[disabled] + span {
      opacity: .75;

      &:before, &:after {
        opacity: .75;
      }
    }
  }
}

.ckbox-inline {
  display: inline-block;
}

.custom-checkbox .custom-control-input {
  &:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }

  &:indeterminate ~ .custom-control-label {
    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
    }
  }

  &:disabled {
    &:checked ~ .custom-control-label::before, &:indeterminate ~ .custom-control-label::before {
      background-color: $primary;
      opacity: 0.5;
    }
  }
}
.custom-control-label-md::before, .custom-control-label-md::after {
  top: 0.25rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.custom-control-label-md {
  padding-left: 15px;
  padding-top: 6px;
}
.custom-controls-stacked .custom-control-md {
  margin-bottom: .25rem;
}
.custom-control-md {
  min-height: 2rem !important;
}

.custom-control-label-lg::before, .custom-control-label-lg::after {
  top: 0.25rem !important;
  width: 2rem !important;
  height: 2rem !important;
}
.custom-control-label-lg {
  padding-left: 15px;
  padding-top: 10px;
}
.custom-controls-stacked .custom-control-lg {
  margin-bottom: 1.25rem;
}
.custom-control-lg {
  min-height: 2.5rem;
}

.custom-switch-indicator-lg {
  height: 1.5rem;
  width: 3rem;
}
.custom-switch-input:checked~.custom-switch-indicator-lg:before {
  left: calc(1.46rem + 1px);
}
.custom-switch-indicator-lg:before {
    content: '';
    position: absolute;
    height: calc(1.5rem - 4px);
    width: calc(1.5rem - 4px);
    top: 1px;
    left: 1px;
    background: $white;
    border-radius: 50%;
    transition: .3s left;
    box-shadow: none;
}

.custom-switch-indicator-xl{
  height: 2rem;
  width: 4rem;
}
.custom-switch-indicator-xl:before {
    content: '';
    position: absolute;
    height: calc(2rem - 4px);
    width: calc(2rem - 4px);
    top: 1px;
    left: 1px;
    background: $white;
    border-radius: 50%;
    transition: .3s left;
    box-shadow: none;
}
.custom-switch-input:checked~.custom-switch-indicator-xl:before {
  left: calc(1.96rem + 1px) !important;
}

.custom-square {
  border-radius: 0px !important;
}
.custom-square:before {
  border-radius: 0px !important;
}

.custom-radius {
  border-radius: 4px !important;
}
.custom-radius:before {
  border-radius: 4px !important;
}

/* ###### Checkbox ###### */