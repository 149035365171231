.tree {
    margin: 0;
    padding: 0;
    list-style: none;
  
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      margin-left: 1em;
      position: relative;
  
      ul {
        margin-left: 0.5em;
      }
  
      &:before {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -10px;
        border-left: 2px solid #d8e0f7;
      }
    }
  
    li {
      margin: 0;
      padding: 0.5em 1em 0.2em 1em;
      line-height: 2em;
      color: #17171b;
      position: relative;
      border: 1px solid #e6eaf7;
      margin: 10px 0;
      border-radius: 6px;
      line-height: 30px;
    }
  
    ul li {
      &:before {
        content: "";
        display: block;
        width: 10px;
        height: 0;
        border-top: 2px solid #d3dfea;
        margin-top: -1px;
        position: absolute;
        top: 22px;
        left: -10px;
      }
  
      &:last-child:before {
        background: transparent;
        height: auto;
        top: 22px;
        bottom: 0;
      }
    }
  }
  
  .indicator {
    margin-right: 5px;
    color: #4b10a4;
  }
  
  .tree li {
    a {
      text-decoration: none;
      color: #424e79;
      font-size: 15px;
    }
  
    button {
      text-decoration: none;
      color: #29273c;
      border: none;
      background: transparent;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      outline: 0;
  
      &:active, &:focus {
        text-decoration: none;
        color: #29273c;
        border: none;
        background: transparent;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        outline: 0;
      }
    }
  
    &.branch {
      background: #f7f6f9;
  
      li {
        background: #fff;
      }
    }
  }
  
  .irs-min, .irs-max {
    color: #4d65d9;
  }
  
  .tree li i {
    color: #4d65d9;
    margin-right: 10px;
    font-weight: bold;
    color: #4d65d9;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
  }